import React,{memo,Fragment} from 'react'
import { Row, Col,  FormCheck, Form, Button,Alert } from 'react-bootstrap'

//Router
import {Link} from 'react-router-dom'

//React-bootstrap
import {Navbar,Container,Nav,NavDropdown} from 'react-bootstrap'

// Logo
import Logo from '../../components/logo'
import './header-style.css';

const HeaderStyle4 = memo(() => {
    return (
        <Fragment>
            <Navbar bg='dark' expand="lg" variant="dark" className="fixed-top iq-navbar" aria-label="Main navigation">
                <Container fluid>
                    {/* <Link to="/" className="navbar-brand d-flex align-items-center"> */}
                    {/* <Col lg="1" md="1"></Col> */}

                        <Col lg="2" md="2" style={{marginLeft: '3.5rem'}}><Logo color={true} /></Col>
                        
                        <Col lg="6" md="6" style={{marginLeft:"0rem"}}>
                            <Row className="justify-content-center text-center">
                            <h4 style={{fontSize: '1.855rem'}} className="logo-title text-light ms-3 mb-0 "><b>Compliance Information Portal (CIP - Signal)</b></h4>
                            </Row >
                            <Row className="justify-content-center text-center">
                            <h5 className="text-light"><b><i>If compliance is expensive, then recalculate the budget for Non-Compliance</i></b></h5>
                            </Row>
                            <br />
                            <Row className="justify-content-center text-center">
                            <h5  className="text-light"><b>SET UP A WORLD CLASS COMPLIANCE FRAMEWORK WITH CIP</b></h5>

                            </Row>
                        </Col>
                        <Col lg="2" md="2"></Col>
                    {/* </Link> */}
                   
                </Container>
            </Navbar>
            <div className=" bg-body shadow-sm" style={{zIndex:'2',marginTop:'5rem', position:'fixed',width:'100%'}}>
                <nav style={{ display: "flex", justifyContent: "center", width: "100%" }} className="nav nav-underline nav-links d-flex" aria-label="Secondary navigation">
                    <Link className="nav-link active" aria-current="page" to="/frontpage-details/CIPOverview">Why CIP?</Link>
                    <Link className="nav-link" to="/frontpage-details/KeyFeatures">Key Features</Link>
                    <Link className="nav-link" to="/frontpage-details/OurClients">Our Clients</Link>
                    <Link className="nav-link" to="/frontpage-details/OtherServices">Other Services</Link>
                    <Link style={{fontSize: '2rem', fontWeight: 700, textDecoration: 'underline'}} className="nav-link contact-us-link" to="/frontpage-details/ContactUs">Contact Us</Link>
                </nav>
            </div>   
        </Fragment>
    )
})

HeaderStyle4.displayName="HeaderStyle4"
export default HeaderStyle4
