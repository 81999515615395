import  React,{memo,Fragment, useState} from 'react'

//react-bootstrap
import { Row, Col, Form, Image, Button, Modal } from 'react-bootstrap'

//router
import { Link } from 'react-router-dom'
import Card from '../../../components/bootstrap/card'


const AdminEdit = memo( (props) => {
    const [userData, setUserData] = useState(props.userData);
    const [userDetails, setUserDetails] = useState({
        role: localStorage.getItem('role'),
        iec: localStorage.getItem('iec_no'),
        name: localStorage.getItem('name')
    })

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        
        setUserData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
      };
    
    const handleUpdateUser = async () => {
        try {
            props.handleUpdate(userData)
 
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    return (
        <Fragment>
            <Modal show={props.show} onHide={props.handleClose} backdrop="static">
                <Modal.Body>
                <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">Edit User Information</h4>
                        </div>
                     </Card.Header>
                     <Card.Body>
                        <div className="new-user-info">
                           <Form>
                              <div className="row">
                                    <Form.Group className="col-md-6 form-group">
                                        <Form.Label htmlFor="fullname">Full Name:</Form.Label>
                                        <Form.Control type="text" value={userData.fullname} id="fullname" placeholder="Full Name"  onChange={handleInputChange}/>
                                    </Form.Group>
                                    <Form.Group className="col-md-6  form-group">
                                        <Form.Label htmlFor="mobile">Mobile Number:</Form.Label>
                                        <Form.Control type="text" value={userData.mobile} id="mobile" placeholder="Mobile Number" onChange={handleInputChange}/>
                                    </Form.Group>
                                 <hr/>
                                
                                {userDetails.role == 'superadmin'?  
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="pno">IEC:</Form.Label>
                                    <Form.Control type="text" value={userData.iec_no}  id="iec_no" placeholder="IEC" onChange={handleInputChange}/>
                                 </Form.Group> 
                                 :
                                 <Form.Group className="col-md-12 form-group">
                                 <Form.Label htmlFor="pno">IEC:</Form.Label>
                                 <Form.Control type="text"  id="iec_no" value={userData.iec_no} placeholder="IEC" disabled/>
                                 </Form.Group> 

                                 }
                                 
                                 <hr />
                                 <Form.Group className="col-md-12  form-group">
                                    <Form.Label htmlFor="email">Email:</Form.Label>
                                    <Form.Control type="email" value={userData.email} id="email" placeholder="Email" onChange={handleInputChange}/>
                                 </Form.Group>
                              </div>
                              <hr/>
                              {/* <h5 className="mb-3">Security</h5>
                              <div className="row">
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="pass">Password:</Form.Label>
                                    <Form.Control type="password"  id="password" placeholder="Password" onChange={handleInputChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="repeatpassword">Repeat Password:</Form.Label>
                                    <Form.Control type="password"  id="repeatpassword" placeholder="Repeat Password " onChange={handleInputChange}/>
                                 </Form.Group>
                              </div> */}
                              <Button type="button" variant="btn btn-secondary" onClick={props.handleClose}>Cancel</Button>
                              <Button style={{float: "right"}} type="button" variant="btn btn-primary" onClick={handleUpdateUser}>{userDetails.role == 'superadmin' ? 'Update Admin' : 'Update User'}</Button>
                           </Form>
                        </div>
                     </Card.Body>
                  </Card>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
})

AdminEdit.displayName="AdminEdit"
export default AdminEdit