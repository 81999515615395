import React from 'react';
import { Container } from 'react-bootstrap';
import './frontpage.scss';

const CIPOverview = () => {
  return (
    <Container className="my-5 cip-overview">
      <h1 className="mb-4 title">Why CIP is Needed</h1>
      <p className="intro-text">
        CERTAINLY! COMPLIANCE IS THE ADHERENCE TO RULES, REGULATIONS, AND STANDARDS. IT ENSURES THAT INDIVIDUALS, ORGANIZATIONS, OR ENTITIES OPERATE WITHIN LEGAL AND ETHICAL BOUNDARIES.
      </p>
      <section>
        <h2 className="section-title">Customs Compliance:</h2>
        <ul className="custom-list">
          <li>Customs regulations ensure smooth import and export of goods across international borders.</li>
          <li>Compliance with customs rules is essential to avoid delays, penalties, or confiscation of goods.</li>
          <li>Customs compliance includes accurate declaration of goods, proper valuation, and adherence to import/export restrictions.</li>
          <li>It helps maintain national security, revenue collection, and fair trade practices.</li>
        </ul>
      </section>
      <section>
        <h2 className="section-title">DGFT Compliance:</h2>
        <ul className="custom-list">
          <li>The Directorate General of Foreign Trade (DGFT) regulates India’s foreign trade policies.</li>
          <li>DGFT compliance ensures adherence to export-import policies, licensing, and incentives.</li>
          <li>Exporters and importers must obtain IEC (Import Export Code) from DGFT.</li>
          <li>DGFT also amends the Foreign Trade Policy (FTP) periodically to align with global trade dynamics.</li>
        </ul>
      </section>
      <section>
        <h2 className="section-title">FEMA Compliance:</h2>
        <ul className="custom-list">
          <li>
            Integration of Returns:
            <ul>
              <li>EDPMS simplifies filing returns on a single platform.</li>
              <li>It integrates returns related to handling shipping bills for caution-listed exporters, delayed utilization of advances received for exports, and outstanding exports.</li>
            </ul>
          </li>
          <li>
            Caution Listing of Exporters:
            <ul>
              <li>EDPMS automates cautioning/de-cautioning of exporters.</li>
              <li>Criteria for caution listing include shipping bills remaining open for overdue without extension, adverse notice by law enforcement agencies, non-traceable exporters, or lack of efforts for export proceeds realization.</li>
              <li>AD Category-I banks handle shipping documents based on caution status.</li>
            </ul>
          </li>
          <li>
            Transparency and Monitoring:
            <ul>
              <li>EDPMS enhances transparency by tracking inward remittances.</li>
              <li>Strict enforcement by RBI improves India’s business environment.</li>
            </ul>
          </li>
        </ul>
      </section>
    </Container>
  );
};

export default CIPOverview;
