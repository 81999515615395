import axios from "axios";
import {SERVER_URL} from "../const";


export const StdCertificateDataService = async (billType, token) => {
    try {

      const response = await axios.get(SERVER_URL + "/std-cert-data?bill_type=" + billType, {
        headers: {
          'Authorization': 'Bearer ' + token,
        },
      });
  
      if (response.status === 200) {
        const data = response.data;

        return data;
      } else {
        console.error('API Error:', response.statusText);
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.error('Error:', error.message);
      throw error;
    }
  };

  export const GetLatestStdCertificateDataService = async (billType, limit, token) => {
    try {

      const response = await axios.get(SERVER_URL + "/std-cert-data/latest?bill_type=" + billType +"&limit=" + limit, {
        headers: {
          'Authorization': 'Bearer ' + token,
        },
      });
  
      if (response.status === 200) {
        const data = response.data;

        return data;
      } else {
        console.error('API Error:', response.statusText);
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.error('Error:', error.message);
      throw error;
    }
  };

  export const fileUploadStatusDataService = async (billType, token) => {
    try {
      console.log('tolen',token)
      const response = await axios.get(SERVER_URL + "/file-upload-status?bill_type=" + billType, {
        headers: {
          'Authorization': 'Bearer ' + token,
        },
      });
  
      if (response.status === 200) {
        const data = response.data;
        console.log('API Response:', data);
        return data;
      } else {
        console.error('API Error:', response.statusText);
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.error('Error:', error.message);
      throw error;
    }
  };

  export const getAllAdminIECsService = async (token) => {
    try {
      console.log('tolen',token)
      const response = await axios.get(SERVER_URL + "/users/admin", {
        headers: {
          'Authorization': 'Bearer ' + token,
        },
      });
  
      if (response.status === 200) {
        const data = response.data;
        // console.log('API Response:', data);
        return data;
      } else {
        console.error('API Error:', response.statusText);
        // throw new Error(response.statusText);
      }
    } catch (error) {
      console.error('Error:', error.message);
      // throw error;
    }
  };

  export const deleteFileDataService = async (token, file_status_id) => {
    try {
      console.log('tolen',token)
      const response = await axios.delete(SERVER_URL + "/file-upload-status/" + file_status_id, {
        headers: {
          'Authorization': 'Bearer ' + token,
        },
      });
  
      if (response.status === 200) {
        const data = response.data;
        // console.log('API Response:', data);
        return data;
      } else {
        console.error('API Error:', response.statusText);
        // throw new Error(response.statusText);
      }
    } catch (error) {
      console.error('Error:', error.message);
      // throw error;
    }
  };

  export const getDashboardReportSummaryData = async (reportTypeList) => {
    try {
      const token = localStorage.getItem('token');
      console.log('token',token)
      const response = await axios.post(SERVER_URL + "/get-dashboard-report-summary", reportTypeList, {
        headers: {
          'Authorization': 'Bearer ' + token,
        },
      });
  
      if (response.status == 200) {
        return response;
      } else {
        console.error('API Error:', response.statusText);
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.error('Error:', error.message);
      throw error;
    }
  };

  export const getReportSummaryData = async (reportType) => {
    try {
      const token = localStorage.getItem('token');
      console.log('token',token)
      const response = await axios.get(SERVER_URL + "/get-report-summary?report_type=" + reportType, {
        headers: {
          'Authorization': 'Bearer ' + token,
        },
      });
  
      if (response.status == 200) {
        return response;
      } else {
        console.error('API Error:', response.statusText);
        // throw new Error(response.statusText);
        return []
      }
    } catch (error) {
      console.error('Error:', error.message);
      // throw error;
    }
  };