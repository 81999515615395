import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Table, Spin, Alert } from 'antd';
import Card from '../../../components/bootstrap/card';
import { Col, Row } from 'react-bootstrap';
import { Excel } from 'antd-table-saveas-excel';
import { getAdvLicImportExportReportService } from '../../../services/AdvLicImportExportReportService';
import { getReportSummaryData } from '../../../services/FileDataService';
import { getTodaysDate } from '../../../utilities/utils';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const AdvLicImportExportReport = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLoading2, setIsLoading2] = useState(false);
  const [summaryDashboardData, setSummaryDashboardData] = useState([]);
  const iec_no = localStorage.getItem('iec_no');
  const tableRef = useRef(null);

  useEffect(() => {
    getSummaryDashboard();
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getAdvLicImportExportReportService(iec_no);
      setData(response);
    } catch (error) {
      console.error('Error fetching clearance time import report data:', error);
      setError('Error fetching data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const formatDateToDDMMYY = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return '';
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  };

  const calculateDaysDifference = (date1, date2) => {
    const diffTime = Math.abs(new Date(date2) - new Date(date1));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const columns = [
    { title: 'ADV. LIC. NO.', dataIndex: 'auth_number', key: 'auth_number', header: 'ADV. LIC. NO.' },
    { 
      title: 'DATE', 
      dataIndex: 'import_date', 
      key: 'import_date', 
      header: 'DATE',
      render: (text, record) => {
        if (record.import_date) {
          return formatDateToDDMMYY(record.import_date);
        }
      } 
    },
    { title: 'EXPORT ITEM SR. NO. AS PER LIC.', dataIndex: 'export_sr_no', key: 'export_sr_no', header: 'EXPORT ITEM SR. NO. AS PER LIC.' },
    { 
      title: 'AUTH. DESCRIPTION', 
      dataIndex: 'item_name', 
      key: 'item_name', 
      header: 'AUTH. DESCRIPTION',
      render: (text, record) => `${record.item_name} ${record.item_description}` 
    },
    { title: 'ATHO. QTY.', dataIndex: 'quantity', key: 'quantity', header: 'ATHO. QTY.' },
    { title: 'ATHO. UOM', dataIndex: 'uom', key: 'uom', header: 'ATHO. UOM' },
    { title: 'VALUE IN INR', dataIndex: 'fob_for_value', key: 'fob_for_value', header: 'VALUE IN INR' },
    { title: 'IEC/BR CODE', dataIndex: 'iec_br', key: 'iec_br', header: 'IEC/BR CODE' },
    { title: 'REF. CODE', dataIndex: 'reference_code', key: 'reference_code', header: 'REF. CODE' },
    { title: 'SHB NO.', dataIndex: 'sb_no', key: 'sb_no', header: 'SHB NO.' },
    { title: 'SHB DT.', dataIndex: 'sb_date', key: 'sb_date', header: 'SHB DT.' },
    { title: 'HSN CODE', dataIndex: 'hs_cd', key: 'hs_cd', header: 'HSN CODE' },
    { title: 'DECRIPTION', dataIndex: 'descn_of_export_item', key: 'descn_of_export_item', header: 'DECRIPTION' },
    { title: 'EXPORT ITEM SR. NO.', dataIndex: 'exp_s_no', key: 'exp_s_no', header: 'EXPORT ITEM SR. NO.' },
    { title: 'QTY.', dataIndex: 'expqty', key: 'expqty', header: 'QTY.' },
    { title: 'IMPORT ITEM SR. NO.', dataIndex: 'imp_s_no', key: 'imp_s_no', header: 'IMPORT ITEM SR. NO.' },
    { title: 'QTY. USED', dataIndex: 'impqt', key: 'impqt', header: 'QTY. USED' },
    { title: 'UOM', dataIndex: 'uqc_aa', key: 'uqc_aa', header: 'UOM' },
  ];

  const dashboardReportSummaryColumns = [
    {
      title: 'ADV. LIC. NO.',
      dataIndex: 'auth_number',
      key: 'auth_number',
      header: 'ADV. LIC. NO.',  // Same as title
    },
    {
      title: 'DATE',
      dataIndex: 'import_date',
      key: 'import_date',
      header: 'DATE',  // Same as title
    },
    {
      title: 'IMPORT ITEM SR. NO.',
      dataIndex: 'import_sr_no',
      key: 'import_sr_no',
      header: 'IMPORT ITEM SR. NO.',  // Same as title
      align: 'right'
    },
    {
      title: 'IMPORT QTY. USED TOTAL',
      dataIndex: 'imp_qty_total',
      key: 'imp_qty_total',
      header: 'IMPORT QTY. USED TOTAL',  // Same as title
      align: 'right'
    },
    {
      title: 'IMP QTY. UOM',
      dataIndex: 'imp_qty_uom',
      key: 'imp_qty_uom',
      header: 'IMP QTY. UOM',  // Same as title
    },
    {
      title: 'ACTUAL QTY. IMPORTED TOTAL',
      dataIndex: 'actual_imported_qty',
      key: 'actual_imported_qty',
      header: 'ACTUAL QTY. IMPORTED TOTAL',  // Same as title
      align: 'right'
    },
    {
      title: 'UOM (AS PER AUTH.)',
      dataIndex: 'uom_as_per_auth',
      key: 'uom_as_per_auth',
      header: 'UOM (AS PER AUTH.)',  // Same as title
    },
    {
      title: 'DIFFERENCE',
      dataIndex: 'difference',
      key: 'difference',
      header: 'DIFFERENCE',  // Same as title
      align: 'right'
    },
  ];

  const getSummaryDashboard = async () => {
    try{
        setIsLoading2(true)
        let reportTypes = 'AdvLic-ExcessImp';
        const response = await getReportSummaryData(reportTypes);

        if(response){
            console.log("response: ", response.data);
            if(response.data){
                setSummaryDashboardData(response.data);
            }
        }
        else{
            console.log("getSummaryDashboard :: no reponse")
        }
        setIsLoading2(false)
    }
    catch(err){
        setIsLoading2(false)
        console.log(err);
    }  
  }


  const getDateOnly = (dateString) => {
    if(dateString && dateString.length !== 0){
      const date = new Date(dateString);
      return date;
    }
    else{
      return '';
    }
  }

  const exportToExcel = async () => {
    let workbook = new ExcelJS.Workbook();
    let summaryWorksheet = workbook.addWorksheet('Summary');
    let worksheet = workbook.addWorksheet('Data');

    // Define columns for Excel export
    summaryWorksheet.columns = dashboardReportSummaryColumns;
    // Apply styles to the header row (Row 1)
    summaryWorksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true }; // Bold text
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Yellow background
      };
      cell.alignment = { vertical: 'middle'}; // Center align
    });

    summaryDashboardData.forEach((obj) => {
      summaryWorksheet.addRow({
        ...obj,
        import_date: getDateOnly(obj.import_date),
        actual_imp_qty: obj.actual_imp_qty ? parseFloat(obj.actual_imp_qty) : '',
        used_for_exp_qty: obj.used_for_exp_qty ? parseFloat(obj.used_for_exp_qty) : '',
      })
    })

    worksheet.columns = columns;
    // Apply styles to the header row (Row 1)
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true }; // Bold text
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Yellow background
      };
      cell.alignment = { vertical: 'middle' }; // Center align
    });

    data.forEach((obj) => {
      worksheet.addRow({
        ...obj,
        import_date: getDateOnly(obj.import_date),
        sb_date: getDateOnly(obj.sb_date),
        quantity: obj.quantity ? parseFloat(obj.quantity) : '',
        fob_for_value: obj.fob_for_value ? parseFloat(obj.fob_for_value) : '',
        expqty: obj.expqty ? parseFloat(obj.expqty) : '',
        impqt: obj.impqt ? parseFloat(obj.impqt) : '',
      })
      
    });

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    // Use FileSaver to save the file
    saveAs(blob, `Lucrative's CIP DSS - Adv. Lic. Accountability with Excess Import Statement as on ${getTodaysDate()} of ${iec_no}.xlsx`);
  };
  
  //  const exportToExcel = () => {
  //   const tableData = getDataToRender();
  //   const ws = XLSX.utils.json_to_sheet(tableData);
  //   const ws1 = XLSX.utils.json_to_sheet(summaryDashboardData);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws1, 'Sheet1');
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet2');
  //   XLSX.writeFile(wb, 'AdvLicAccExcessImportStmt.xlsx');
  // };

  // const getDataToRender = () => {
  //   if (tableRef.current) {
  //     return data;
  //   }
  //   return [];
  // };

  return (
    <Fragment>
    <Row>
      <Col sm="12">
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">Adv. Lic. Accountability with Excess Import Statement -  co related with validity</h4>
            </div>
            <div>
              <button className="btn btn-primary"onClick={exportToExcel}>Export to Excel</button>
            </div>
          </Card.Header>
          <Card.Body className="px-0">
            <Table
                className="report-summary-table"
                width={100}
                columns={dashboardReportSummaryColumns} 
                dataSource={summaryDashboardData}
                pagination={false}
                loading={isLoading2}
            />
            <hr />
            <div className="table-responsive">
     <Table dataSource={data} columns={columns} ref={tableRef}/>
     </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AdvLicImportExportReport;
