import React, { memo, Fragment } from "react";
import logo from '../../../assets/images/Lucrative_Exim_New-CIP_LOGO_2024.png';
const Logo = memo((props) => {
  return (
    <Fragment>
      <div className="logo-main">
        <div className="logo-normal">
        <img src={logo} style={{width:'110px',height:'110px',marginLeft:'1rem'}}></img>
        </div>
        {/* <div className="logo-mini">
        <img src={logo} style={{width:'35px',height:'35px'}}></img>
        </div> */}
      </div>
    </Fragment>
  );
});

Logo.displayName = "Logo";
export default Logo;
