import axios from "axios";
import { SERVER_URL } from "../const";

const token = localStorage.getItem('token');

export const getAdvLicImportExportReportService = async (iec_no) => {
    try {
      // Make a GET request to the API endpoint using Axios
      const response = await axios.get(`${SERVER_URL}/get-adv-lic-excess-import-details?iec_no=${iec_no}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
  
      // Handle the response as needed
     console.log('Response:', response.data);
  
      return response.data; // You can modify this based on your response structure
    } catch (error) {
      // Handle errors
      console.error('Error:', error);
      throw error; // Rethrow the error if needed
    }
  };
  
  export const getClearanceTimeImportReportService = async (iec_no) => {
    try {
      // Make a GET request to the API endpoint using Axios
      const response = await axios.get(`${SERVER_URL}/get-clearance-import-details?iec_no=${iec_no}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });  
      return response.data; // You can modify this based on your response structure
    } catch (error) {
      // Handle errors
      console.error('Error:', error);
      throw error; // Rethrow the error if needed
    }
  };
  