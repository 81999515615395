import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './frontpage.scss';

const Clients = () => {
  const clients = [
    'CUMMINS INDIA LTD.',
    'TATA CUMMINS PRIVATE LIMITED',
    'CUMMINS TECHNOLOGIES INDIA PRIVATE LIMITED',
    'JINDAL STAINLESS LIMITED',
    'CUMMINS GENERATOR TECHNOLOGIES LTD.',
    'EMERSON PROCESS MANAGEMENT (INDIA) PVT. LTD.',
    'ALFA LAVAL (I) PRIVATE LIMITED',
    'ATLAS COPCO (INDIA) PRIVATE LIMITED',
    'PRAJ INDUSTRIES LIMITED',
    'DHARIWAL INDUSTRIES PVT LTD',
    'ENPRO INDUSTRIES PVT. LTD.',
    'BEKAERT INDUSTRIES PVT LTD',
    'BEKAERT MUKAND WIRE INDUSTRIES PVT LTD',
    'AUTOCOMP CORPORATION PANSE PRIVATE LIMITED',
    'HUSCO HYDRAULICS PRIVATE LIMITED',
    'OCTILLION POWER SYSTEMS INDIA PRIVATE LIMITED'
  ];

  return (
    <Container className="my-5 clients-list">
    <h2 className="mb-4 title">Our Clients for D2D/CIP</h2>
    <Row>
      {clients.map((client, index) => (
        <Col md={6} key={index} className="client-item">
          <div className="client-card">
            {client}
          </div>
        </Col>
      ))}
    </Row>
  </Container>
  );
};

export default Clients;
