import React, { useState, useEffect } from "react";

//react-boostrap
import { Button, Nav, Collapse, Navbar, Container } from "react-bootstrap";


//img
import topImage from "../assets/images/dashboard/top-image.jpg";


// SliderTab
import SliderTab from "../directives/slider-tabs";

import SignIn from "./modules/auth/pages/simple/sign-in";


const Index = React.memo(() => {


  useEffect(() => {
    return () => {
      setTimeout(() => {
        Array.from(
          document.querySelectorAll('[data-toggle="slider-tab"]'),
          (elem) => {
            return new SliderTab(elem);
          }
        );
      }, 100);
    };
  });

  useEffect(() => {
    const backToTop = document.getElementById("back-to-top");

    if (backToTop) {
      backToTop.classList.add("animate__animated", "animate__fadeOut");

      const handleScroll = () => {
        if (document.documentElement.scrollTop > 250) {
          backToTop.classList.remove("animate__fadeOut");
          backToTop.classList.add("animate__fadeIn");
        } else {
          backToTop.classList.remove("animate__fadeIn");
          backToTop.classList.add("animate__fadeOut");
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <React.Fragment>
      <span className="uisheet screen-darken"></span>
      <div
        className="header"
        style={{
          background: `url(${topImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          position: "relative",
        }}
      >
        <div >
          <div className="container">
              <div>
                <SignIn />
              </div>  
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});

export default Index;
