import { remarks } from './remarks';

export const determineRemark = (record, reportType) => {
    const brcRemarksArray = [];
    var differenceInDaysFromSBDate = null;
    if(record.sb_date){
        const dateString = record.sb_date;
        const [day, month, year] = dateString.split('/');
        const dateObject = new Date(`${month}/${day}/${year}`);
        const timestamp = dateObject.getTime();
        const sbDate = timestamp;
        const today = new Date();
        differenceInDaysFromSBDate = Math.floor((today - sbDate) / (1000 * 60 * 60 * 24));
    }
    const drawbackLiability = [];
    const rodtepLiability = [];
    const drawbackRemarksArray = [];
    const drawbackScrollgenerated = record.scroll_number && record.scroll_date && record.scroll_amount
    const rodtepScrollGenerated = reportType == 'RODTEP' && record.rodtep_scroll_number
    const rodtepScrollGeneratedScripPending = reportType == 'RODTEP' && record.rodtep_scroll_number && !record.rodtep_scrip_number
    const rodtepScrollScripGenerated = reportType == 'RODTEP' && record.rodtep_scroll_number && record.rodtep_scrip_number
    const rodtepArray = [];
    if (record.brc_no) {
        if (record.port_code !== record.shb_port && record.invoice_value_currency !== record.currency) {
            brcRemarksArray.push(remarks.portAndCurrencyMismatch.remark);
            //DBK
            if (!drawbackScrollgenerated) {
                drawbackRemarksArray.push(remarks.portAndCurrencyMismatch.drawback_remark_scroll_pending);
            }
            else {
                drawbackRemarksArray.push(remarks.portAndCurrencyMismatch.drawback_remark_scroll_generated)
            }

            //RODTEP
            if(!rodtepScrollGenerated){
                rodtepArray.push(remarks.portAndCurrencyMismatch.rodtep_scroll_not_generated)
            }
            else if(rodtepScrollGeneratedScripPending){
                rodtepArray.push(remarks.portAndCurrencyMismatch.rodtep_scroll_generated_scrip_pending)
            }
            else if(rodtepScrollScripGenerated){
                rodtepArray.push(remarks.portAndCurrencyMismatch.rodtep_scroll_scrip_generated)
            }
        } else if (record.port_code !== record.shb_port) {
            brcRemarksArray.push(remarks.portMismatch.remark);
            if (!drawbackScrollgenerated) {
                drawbackRemarksArray.push(remarks.portMismatch.drawback_remark_scroll_pending);
            }
            else {
                drawbackRemarksArray.push(remarks.portMismatch.drawback_remark_scroll_generated)
            }

            //RODTEP
            if(!rodtepScrollGenerated){
                rodtepArray.push(remarks.portMismatch.rodtep_scroll_not_generated)
            }
            else if(rodtepScrollGeneratedScripPending){
                rodtepArray.push(remarks.portMismatch.rodtep_scroll_generated_scrip_pending)
            }
            else if(rodtepScrollScripGenerated){
                rodtepArray.push(remarks.portMismatch.rodtep_scroll_scrip_generated)
            }
        } else if (record.invoice_value_currency !== record.currency) {
            brcRemarksArray.push(remarks.currencyMismatch.remark);
            if (!drawbackScrollgenerated) {
                drawbackRemarksArray.push(remarks.currencyMismatch.drawback_remark_scroll_pending);
            }
            else {
                drawbackRemarksArray.push(remarks.currencyMismatch.drawback_remark_scroll_generated)
            }

            //RODTEP
            if(!rodtepScrollGenerated){
                rodtepArray.push(remarks.currencyMismatch.rodtep_scroll_not_generated)
            }
            else if(rodtepScrollGeneratedScripPending){
                rodtepArray.push(remarks.currencyMismatch.rodtep_scroll_generated_scrip_pending)
            }
            else if(rodtepScrollScripGenerated){
                rodtepArray.push(remarks.currencyMismatch.rodtep_scroll_scrip_generated)
            }
        }

        if (record.date_of_realization && record.sb_date) {
            const date_of_realization = formatDateToDDMMYY(record.date_of_realization)
            const [realizationDay, realizationMonth, realizationYear] = date_of_realization.split('/');
            const [sbDay, sbMonth, sbYear] = record.sb_date.split('/');
            const dateOfRealization = new Date(`20${realizationYear}`, realizationMonth - 1, realizationDay); // Year assumed to be in 20xx format
            const sbDate = new Date(`20${sbYear}`, sbMonth - 1, sbDay); // Year assumed to be in 20xx format
            const differenceInMs = Math.abs(dateOfRealization - sbDate);
            const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
            if (differenceInDays > 270) {
                brcRemarksArray.push(remarks.paymentReceivedAfter270Days.remark);
                if (!drawbackScrollgenerated) {
                    drawbackRemarksArray.push(remarks.paymentReceivedAfter270Days.drawback_remark_scroll_pending);
                    drawbackLiability.push(record.dbk_claim)
                }
                else {
                    drawbackRemarksArray.push(remarks.paymentReceivedAfter270Days.drawback_remark_scroll_generated)
                    drawbackLiability.push(record.dbk_claim)
                }

                //RODTEP
            if(!rodtepScrollGenerated){
                rodtepLiability.push(record.rodtep_amt)
                rodtepArray.push(remarks.paymentReceivedAfter270Days.rodtep_scroll_not_generated)
            }
            else if(rodtepScrollGeneratedScripPending){
                rodtepLiability.push(record.rodtep_amt)
                rodtepArray.push(remarks.paymentReceivedAfter270Days.rodtep_scroll_generated_scrip_pending)
            }
            else if(rodtepScrollScripGenerated){
                rodtepLiability.push(record.rodtep_amt)
                rodtepArray.push(remarks.paymentReceivedAfter270Days.rodtep_scroll_scrip_generated)
            }
            }
        }

        if (record.fcc_total < record.total_realised_value) {
            brcRemarksArray.push(remarks.excessPaymentReceived.remark);
            if (!drawbackScrollgenerated) {
                drawbackRemarksArray.push(remarks.excessPaymentReceived.drawback_remark_scroll_pending);
            }
            else {
                drawbackRemarksArray.push(remarks.excessPaymentReceived.drawback_remark_scroll_generated)
            }

            //RODTEP
            if(!rodtepScrollGenerated){
                rodtepArray.push(remarks.excessPaymentReceived.rodtep_scroll_not_generated)
            }
            else if(rodtepScrollGeneratedScripPending){
                rodtepArray.push(remarks.excessPaymentReceived.rodtep_scroll_generated_scrip_pending)
            }
            else if(rodtepScrollScripGenerated){
                rodtepArray.push(remarks.excessPaymentReceived.rodtep_scroll_scrip_generated)
            }
        } else if (record.fcc_total > record.total_realised_value) {
            brcRemarksArray.push(remarks.shortPaymentReceived.remark);
            if (!drawbackScrollgenerated) {
                drawbackRemarksArray.push(remarks.shortPaymentReceived.drawback_remark_scroll_pending);
                drawbackLiability.push((record.dbk_claim/record.total_realised_value*(record.fcc_total - record.total_realised_value)).toFixed(2))
            }
            else {
                drawbackRemarksArray.push(remarks.shortPaymentReceived.drawback_remark_scroll_generated)
                drawbackLiability.push((record.dbk_claim/record.total_realised_value*(record.fcc_total - record.total_realised_value)).toFixed(2))
            }

            //RODTEP
            if(!rodtepScrollGenerated){
                rodtepLiability.push((record.rodtep_amt/record.total_realised_value*(record.fcc_total - record.total_realised_value)).toFixed(2))
                rodtepArray.push(remarks.shortPaymentReceived.rodtep_scroll_not_generated)
            }
            else if(rodtepScrollGeneratedScripPending){
                rodtepLiability.push((record.rodtep_amt/record.total_realised_value*(record.fcc_total - record.total_realised_value)).toFixed(2))
                rodtepArray.push(remarks.shortPaymentReceived.rodtep_scroll_generated_scrip_pending)
            }
            else if(rodtepScrollScripGenerated){
                rodtepLiability.push((record.rodtep_amt/record.total_realised_value*(record.fcc_total - record.total_realised_value)).toFixed(2))
                rodtepArray.push(remarks.shortPaymentReceived.rodtep_scroll_scrip_generated)
            }
        }
    } else {
        if (differenceInDaysFromSBDate > 270 && !record.brc_no) {
            brcRemarksArray.push(remarks.obtainBRCOnPriority.remark);
            if (!drawbackScrollgenerated) {
                drawbackRemarksArray.push(remarks.obtainBRCOnPriority.drawback_remark_scroll_pending);
            }
            else {
                drawbackRemarksArray.push(remarks.obtainBRCOnPriority.drawback_remark_scroll_generated)
            }

            //RODTEP
            if(!rodtepScrollGenerated){
                rodtepArray.push(remarks.obtainBRCOnPriority.rodtep_scroll_not_generated)
            }
            else if(rodtepScrollGeneratedScripPending){
                rodtepArray.push(remarks.obtainBRCOnPriority.rodtep_scroll_generated_scrip_pending)
            }
            else if(rodtepScrollScripGenerated){
                rodtepArray.push(remarks.obtainBRCOnPriority.rodtep_scroll_scrip_generated)
            }
        } else if (differenceInDaysFromSBDate > 230 && differenceInDaysFromSBDate <= 270 && !record.brc_no) {
            brcRemarksArray.push(remarks.checkPaymentStatusAndObtainBRC.remark);
            if (!drawbackScrollgenerated) {
                drawbackRemarksArray.push(remarks.checkPaymentStatusAndObtainBRC.drawback_remark_scroll_pending);
            }
            else {
                drawbackRemarksArray.push(remarks.checkPaymentStatusAndObtainBRC.drawback_remark_scroll_generated)
            }

            //RODTEP
            if(!rodtepScrollGenerated){
                rodtepArray.push(remarks.checkPaymentStatusAndObtainBRC.rodtep_scroll_not_generated)
            }
            else if(rodtepScrollGeneratedScripPending){
                rodtepArray.push(remarks.checkPaymentStatusAndObtainBRC.rodtep_scroll_generated_scrip_pending)
            }
            else if(rodtepScrollScripGenerated){
                rodtepArray.push(remarks.checkPaymentStatusAndObtainBRC.rodtep_scroll_scrip_generated)
            }
        } else if (differenceInDaysFromSBDate === 230 && !record.brc_no) {
            brcRemarksArray.push(remarks.BRCPending.remark);
            if (!drawbackScrollgenerated) {
                drawbackRemarksArray.push(remarks.BRCPending.drawback_remark_scroll_pending);
            }
            else {
                drawbackRemarksArray.push(remarks.BRCPending.drawback_remark_scroll_generated)
            }

            //RODTEP
            if(!rodtepScrollGenerated){
                rodtepArray.push(remarks.BRCPending.rodtep_scroll_not_generated)
            }
            else if(rodtepScrollGeneratedScripPending){
                rodtepArray.push(remarks.BRCPending.rodtep_scroll_generated_scrip_pending)
            }
            else if(rodtepScrollScripGenerated){
                rodtepArray.push(remarks.BRCPending.rodtep_scroll_scrip_generated)
            }
        } else {
            brcRemarksArray.push(remarks.BRCPending.remark);
            if (!drawbackScrollgenerated) {
                drawbackRemarksArray.push(remarks.BRCPending.drawback_remark_scroll_pending);
            }
            else {
                drawbackRemarksArray.push(remarks.BRCPending.drawback_remark_scroll_generated)
            }

            //RODTEP
            if(!rodtepScrollGenerated){
                rodtepArray.push(remarks.BRCPending.rodtep_scroll_not_generated)
            }
            else if(rodtepScrollGeneratedScripPending){
                rodtepArray.push(remarks.BRCPending.rodtep_scroll_generated_scrip_pending)
            }
            else if(rodtepScrollScripGenerated){
                rodtepArray.push(remarks.BRCPending.rodtep_scroll_scrip_generated)
            }
        }
    }

    if (brcRemarksArray.length === 0) {
        brcRemarksArray.push(remarks.allClear.remark);
        if (!drawbackScrollgenerated) {
            drawbackRemarksArray.push(remarks.allClear.drawback_remark_scroll_pending);
        }
        else {
            drawbackRemarksArray.push(remarks.allClear.drawback_remark_scroll_generated)
        }

        //RODTEP
        if(!rodtepScrollGenerated){
            rodtepArray.push(remarks.allClear.rodtep_scroll_not_generated)
        }
        else if(rodtepScrollGeneratedScripPending){
            rodtepArray.push(remarks.allClear.rodtep_scroll_generated_scrip_pending)
        }
        else if(rodtepScrollScripGenerated){
            rodtepArray.push(remarks.allClear.rodtep_scroll_scrip_generated)
        }
    }

    if (brcRemarksArray.length > 0) {
        brcRemarksArray[brcRemarksArray.length - 1].replace(/,/g, '');
    }
    if (reportType == 'BRC') {
        return brcRemarksArray;
    }
    else if (reportType === 'DBK') {
        return [drawbackRemarksArray, drawbackLiability]; // Replace anotherArray with your additional array
    }
    else if (reportType === 'RODTEP') {
        return [rodtepArray, rodtepLiability];
    }
};

function formatDateToDDMMYY(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        return ''; // Return empty string if the dateString is not a valid date
    }
    else {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-2);
        return `${day}/${month}/${year}`;
    }

}
