import React, { useState, useEffect, useContext, memo, Fragment } from 'react'

//Router
import { Link, useLocation } from 'react-router-dom'

import { userRbac } from '../../../../services/UserService';

//React-bootstrap
import { Accordion, useAccordionButton, AccordionContext, Nav, Tooltip, OverlayTrigger } from 'react-bootstrap'

//Componets
import Modalpopup from '../../../../views/modules/auth/components/modal-popup'
import SidebarMenu from '../../components/sidebar/sidebar-menu'


function CustomToggle({ children, eventKey, onClick }) {

    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, (active) => onClick({ state: !active, eventKey: eventKey }));

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <Link to="#" aria-expanded={isCurrentEventKey ? 'true' : 'false'} className="nav-link" role="button" onClick={(e) => {
            decoratedOnClick(isCurrentEventKey)
        }}>
            {children}
        </Link>
    );
}

const VerticalNav = memo(() => {
    const [activeMenu, setActiveMenu] = useState(false)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const [active, setActive] = useState('')
    const [userDetails, setUserDetails] = useState({
        role: localStorage.getItem('role'),
        iec: localStorage.getItem('iec_no'),
        name: localStorage.getItem('name')
    })

    const [userReports, setUserReports] = useState([])
    const [isUploadVisible, setIsUploadVisible] = useState(false);
    const [isReportVisible, setIsReportVisible] = useState(false);

    const [activeSubMenu, setActiveSubMenu] = useState(null); // State to track active menu item
    const [selectedCardType,setSelectedCardType] = useState('')
    const handleMenuClick = (menuKey, cardType) => {
        setActiveSubMenu(menuKey);
        setSelectedCardType(cardType); // Set selected card type
    };
        let location = useLocation();


    const getUserRbacDetails = async () => {
        try{
            const token = localStorage.getItem('token');
            const response = await userRbac(token);
            console.log("response: ", response)
            const userRbacDetails = response.data;
            console.log('user rbac reponse: ', userRbacDetails)
            setUserReports(userRbacDetails.data)
            userRbacDetails.data.map((item, index) => {
                if (item.page_type == "UPLOAD"){
                    setIsUploadVisible(true);
                }

                if(item.page_type == "REPORT"){
                    setIsReportVisible(true);
                }
            })
        }
        catch(error){
            console.log(error)
        }
    }

    useEffect(() => {
        getUserRbacDetails();
    }, [userDetails.iec])
    return (
        <Fragment>
            {userDetails && userDetails.role && (
                <Accordion as="ul" className="navbar-nav iq-main-menu">
                    <SidebarMenu isTag="true" pathname='/dashboard' title='Dashboard'>
                        <i className="icon">
                            <svg className="icon-20" width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4" d="M16.0756 2H19.4616C20.8639 2 22.0001 3.14585 22.0001 4.55996V7.97452C22.0001 9.38864 20.8639 10.5345 19.4616 10.5345H16.0756C14.6734 10.5345 13.5371 9.38864 13.5371 7.97452V4.55996C13.5371 3.14585 14.6734 2 16.0756 2Z" fill="currentColor"></path>
                                <path fillRule="evenodd" clipRule="evenodd" d="M4.53852 2H7.92449C9.32676 2 10.463 3.14585 10.463 4.55996V7.97452C10.463 9.38864 9.32676 10.5345 7.92449 10.5345H4.53852C3.13626 10.5345 2 9.38864 2 7.97452V4.55996C2 3.14585 3.13626 2 4.53852 2ZM4.53852 13.4655H7.92449C9.32676 13.4655 10.463 14.6114 10.463 16.0255V19.44C10.463 20.8532 9.32676 22 7.92449 22H4.53852C3.13626 22 2 20.8532 2 19.44V16.0255C2 14.6114 3.13626 13.4655 4.53852 13.4655ZM19.4615 13.4655H16.0755C14.6732 13.4655 13.537 14.6114 13.537 16.0255V19.44C13.537 20.8532 14.6732 22 16.0755 22H19.4615C20.8637 22 22 20.8532 22 19.44V16.0255C22 14.6114 20.8637 13.4655 19.4615 13.4655Z" fill="currentColor"></path>
                            </svg>
                        </i>
                    </SidebarMenu>
                    <li><hr className="hr-horizontal" /></li>
                    <li className="nav-item static-item">
                        <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
                            <span className="default-icon">Pages</span>
                            <span className="mini-icon">-</span>
                        </Link>
                    </li>
                    <Modalpopup sign="in" show={show} handleclose={handleClose} />
                    <Modalpopup sign="up" show={show1} handleclose={handleClose1} />
                    {userDetails.role != 'user' &&
                    <Accordion.Item as="li" eventKey="sidebar-user" bsPrefix={`nav-item ${location.pathname === '/dashboard/app/user-profile' ? 'active' : '' || location.pathname === '/dashboard/app/user-add' ? 'active' : '' || location.pathname === '/dashboard/app/user-list' ? 'active' : ''} `} onClick={() => setActive('user')} >
                        <CustomToggle eventKey="sidebar-user" onClick={(activeKey) => setActiveMenu(activeKey)}>
                            <OverlayTrigger placement="right" overlay={<Tooltip>Users</Tooltip>}>
                                <i className="icon">
                                    <svg className="icon-20" width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.9488 14.54C8.49884 14.54 5.58789 15.1038 5.58789 17.2795C5.58789 19.4562 8.51765 20.0001 11.9488 20.0001C15.3988 20.0001 18.3098 19.4364 18.3098 17.2606C18.3098 15.084 15.38 14.54 11.9488 14.54Z" fill="currentColor"></path>
                                        <path opacity="0.4" d="M11.949 12.467C14.2851 12.467 16.1583 10.5831 16.1583 8.23351C16.1583 5.88306 14.2851 4 11.949 4C9.61293 4 7.73975 5.88306 7.73975 8.23351C7.73975 10.5831 9.61293 12.467 11.949 12.467Z" fill="currentColor"></path>
                                        <path opacity="0.4" d="M21.0881 9.21923C21.6925 6.84176 19.9205 4.70654 17.664 4.70654C17.4187 4.70654 17.1841 4.73356 16.9549 4.77949C16.9244 4.78669 16.8904 4.802 16.8725 4.82902C16.8519 4.86324 16.8671 4.90917 16.8895 4.93889C17.5673 5.89528 17.9568 7.0597 17.9568 8.30967C17.9568 9.50741 17.5996 10.6241 16.9728 11.5508C16.9083 11.6462 16.9656 11.775 17.0793 11.7948C17.2369 11.8227 17.3981 11.8371 17.5629 11.8416C19.2059 11.8849 20.6807 10.8213 21.0881 9.21923Z" fill="currentColor"></path>
                                        <path d="M22.8094 14.817C22.5086 14.1722 21.7824 13.73 20.6783 13.513C20.1572 13.3851 18.747 13.205 17.4352 13.2293C17.4155 13.232 17.4048 13.2455 17.403 13.2545C17.4003 13.2671 17.4057 13.2887 17.4316 13.3022C18.0378 13.6039 20.3811 14.916 20.0865 17.6834C20.074 17.8032 20.1698 17.9068 20.2888 17.8888C20.8655 17.8059 22.3492 17.4853 22.8094 16.4866C23.0637 15.9589 23.0637 15.3456 22.8094 14.817Z" fill="currentColor"></path>
                                        <path opacity="0.4" d="M7.04459 4.77973C6.81626 4.7329 6.58077 4.70679 6.33543 4.70679C4.07901 4.70679 2.30701 6.84201 2.9123 9.21947C3.31882 10.8216 4.79355 11.8851 6.43661 11.8419C6.60136 11.8374 6.76343 11.8221 6.92013 11.7951C7.03384 11.7753 7.09115 11.6465 7.02668 11.551C6.3999 10.6234 6.04263 9.50765 6.04263 8.30991C6.04263 7.05904 6.43303 5.89462 7.11085 4.93913C7.13234 4.90941 7.14845 4.86348 7.12696 4.82926C7.10906 4.80135 7.07593 4.78694 7.04459 4.77973Z" fill="currentColor"></path>
                                        <path d="M3.32156 13.5127C2.21752 13.7297 1.49225 14.1719 1.19139 14.8167C0.936203 15.3453 0.936203 15.9586 1.19139 16.4872C1.65163 17.4851 3.13531 17.8066 3.71195 17.8885C3.83104 17.9065 3.92595 17.8038 3.91342 17.6832C3.61883 14.9167 5.9621 13.6046 6.56918 13.3029C6.59425 13.2885 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2047 3.84358 13.3848 3.32156 13.5127Z" fill="currentColor"></path>
                                    </svg>
                                </i>
                            </OverlayTrigger>
                            <span className="item-name">{userDetails.role == 'superadmin' ? 'Admins' : 'Users'}</span>
                            <i className="right-icon">
                                <svg className="icon-18" xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                                </svg>
                            </i>
                        </CustomToggle>
                        <Accordion.Collapse eventKey="sidebar-user">
                            <ul className="sub-nav">
                                <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/app/user-add' title={userDetails.role == 'superadmin' ? 'Add Admin' : 'Add User'} minititle='AU'></SidebarMenu>
                                <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/app/user-list' title={userDetails.role == 'superadmin' ? 'Admin List' : 'User List'} minititle='AL'></SidebarMenu>
                            </ul>
                        </Accordion.Collapse>
                    </Accordion.Item>
                    }

                    {isUploadVisible && 
                    <Accordion.Item as="li" eventKey="sidebar-upload" bsPrefix={`nav-item ${location.pathname === '/dashboard/reports/brc-report' ? 'active' : '' || location.pathname === '/dashboard/app/user-add' ? 'active' : '' || location.pathname === '/dashboard/app/user-list' ? 'active' : ''} `} onClick={() => setActive('reports')} >
                        <CustomToggle eventKey="sidebar-upload" onClick={(activeKey) => setActiveMenu(activeKey)}>
                            <OverlayTrigger placement="right" overlay={<Tooltip>Upload Docs</Tooltip>}>
                                <i className="icon">
                                    <svg className="icon-20" width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.4" d="M11.9912 18.6215L5.49945 21.864C5.00921 22.1302 4.39768 21.9525 4.12348 21.4643C4.0434 21.3108 4.00106 21.1402 4 20.9668V13.7087C4 14.4283 4.40573 14.8725 5.47299 15.37L11.9912 18.6215Z" fill="currentColor"></path>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.89526 2H15.0695C17.7773 2 19.9735 3.06605 20 5.79337V20.9668C19.9989 21.1374 19.9565 21.3051 19.8765 21.4554C19.7479 21.7007 19.5259 21.8827 19.2615 21.9598C18.997 22.0368 18.7128 22.0023 18.4741 21.8641L11.9912 18.6215L5.47299 15.3701C4.40573 14.8726 4 14.4284 4 13.7088V5.79337C4 3.06605 6.19625 2 8.89526 2ZM8.22492 9.62227H15.7486C16.1822 9.62227 16.5336 9.26828 16.5336 8.83162C16.5336 8.39495 16.1822 8.04096 15.7486 8.04096H8.22492C7.79137 8.04096 7.43991 8.39495 7.43991 8.83162C7.43991 9.26828 7.79137 9.62227 8.22492 9.62227Z" fill="currentColor"></path>
                                    </svg>
                                </i>
                            </OverlayTrigger>
                            <span className="item-name">Upload Docs</span>
                            <i className="right-icon">
                                <svg className="icon-18" xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                                </svg>
                            </i>
                        </CustomToggle>
                        <Accordion.Collapse eventKey="sidebar-upload">
                            <ul className="sub-nav">

                                    {userReports && userReports.map((menuItem, index) => (
                                        menuItem.page_type == "UPLOAD" && 
                                            <SidebarMenu
                                            key={index}
                                            isTag="false"
                                            staticIcon="true"
                                            pathname={menuItem.page_ui_path}
                                            title={menuItem.page_name}
                                            minititle={menuItem.page_desc}
                                        />
                                        
                                    ))}
                                {/* <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/upload/upload-files?__card__=ADV_AUTH' title='Advance Auth' minititle='ADV AUTH'></SidebarMenu>
                                <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/upload/upload-files?__card__=AEO' title='AEO' minititle='AEO'></SidebarMenu>
                                <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/upload/upload-files?__card__=BOND' title='BOND' minititle='BOND'></SidebarMenu>
                                <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/upload/upload-files?__card__=BRC' title='BRC' minititle='BRC'></SidebarMenu>
                                <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/upload/upload-files?__card__=EH' title='EH' minititle='EH'></SidebarMenu>
                                <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/upload/upload-files?__card__=EPCG' title='EPCG' minititle='EPCG'></SidebarMenu>
                                <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/upload/upload-files?__card__=DBK_PENDING' title='DBK PENDING' minititle='DBK PENDING'></SidebarMenu>
                                <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/upload/upload-files?__card__=DBK_SCROLL' title='DBK SCROLL' minititle='DBK_SC'></SidebarMenu>
                                <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/upload/upload-files?__card__=DSC' title='DSC' minititle='DSC'></SidebarMenu>
                                <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/upload/upload-files?__card__=FTA' title='FTA' minititle='FTA'></SidebarMenu>
                                <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/upload/upload-files?__card__=FSP' title='FSP' minititle='FSP'></SidebarMenu>
                                <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/upload/upload-files?__card__=IGST_SCROLL' title='IGST' minititle='IGST'></SidebarMenu>
                                <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/upload/upload-files?__card__=RCMC' title='RCMC' minititle='RCMC'></SidebarMenu>
                                <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/upload/upload-files?__card__=RODTEP_SCRIP' title='RODTEP_SCRIP' minititle='RODTEP_SCRIP'></SidebarMenu>
                                <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/upload/upload-files?__card__=RODTEP_SCROLL' title='RODTEP_SCROLL' minititle='RODTEP_SCROLL'></SidebarMenu> */}
                            </ul>
                        </Accordion.Collapse>
                    </Accordion.Item>
                    }
                    {userDetails.role !== 'superadmin' && (
                        <Accordion.Item as="li" eventKey="sidebar-report" bsPrefix={`nav-item ${location.pathname === '/dashboard/reports/brc-report' ? 'active' : '' || location.pathname === '/dashboard/app/user-add' ? 'active' : '' || location.pathname === '/dashboard/app/user-list' ? 'active' : ''} `} onClick={() => setActive('reports')} >
                            <CustomToggle eventKey="sidebar-report" onClick={(activeKey) => setActiveMenu(activeKey)}>
                                <OverlayTrigger placement="right" overlay={<Tooltip>Reports</Tooltip>}>
                                    <i className="icon">
                                        <svg className="icon-20" width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.9488 14.54C8.49884 14.54 5.58789 15.1038 5.58789 17.2795C5.58789 19.4562 8.51765 20.0001 11.9488 20.0001C15.3988 20.0001 18.3098 19.4364 18.3098 17.2606C18.3098 15.084 15.38 14.54 11.9488 14.54Z" fill="currentColor"></path>
                                            <path opacity="0.4" d="M11.949 12.467C14.2851 12.467 16.1583 10.5831 16.1583 8.23351C16.1583 5.88306 14.2851 4 11.949 4C9.61293 4 7.73975 5.88306 7.73975 8.23351C7.73975 10.5831 9.61293 12.467 11.949 12.467Z" fill="currentColor"></path>
                                            <path opacity="0.4" d="M21.0881 9.21923C21.6925 6.84176 19.9205 4.70654 17.664 4.70654C17.4187 4.70654 17.1841 4.73356 16.9549 4.77949C16.9244 4.78669 16.8904 4.802 16.8725 4.82902C16.8519 4.86324 16.8671 4.90917 16.8895 4.93889C17.5673 5.89528 17.9568 7.0597 17.9568 8.30967C17.9568 9.50741 17.5996 10.6241 16.9728 11.5508C16.9083 11.6462 16.9656 11.775 17.0793 11.7948C17.2369 11.8227 17.3981 11.8371 17.5629 11.8416C19.2059 11.8849 20.6807 10.8213 21.0881 9.21923Z" fill="currentColor"></path>
                                            <path d="M22.8094 14.817C22.5086 14.1722 21.7824 13.73 20.6783 13.513C20.1572 13.3851 18.747 13.205 17.4352 13.2293C17.4155 13.232 17.4048 13.2455 17.403 13.2545C17.4003 13.2671 17.4057 13.2887 17.4316 13.3022C18.0378 13.6039 20.3811 14.916 20.0865 17.6834C20.074 17.8032 20.1698 17.9068 20.2888 17.8888C20.8655 17.8059 22.3492 17.4853 22.8094 16.4866C23.0637 15.9589 23.0637 15.3456 22.8094 14.817Z" fill="currentColor"></path>
                                            <path opacity="0.4" d="M7.04459 4.77973C6.81626 4.7329 6.58077 4.70679 6.33543 4.70679C4.07901 4.70679 2.30701 6.84201 2.9123 9.21947C3.31882 10.8216 4.79355 11.8851 6.43661 11.8419C6.60136 11.8374 6.76343 11.8221 6.92013 11.7951C7.03384 11.7753 7.09115 11.6465 7.02668 11.551C6.3999 10.6234 6.04263 9.50765 6.04263 8.30991C6.04263 7.05904 6.43303 5.89462 7.11085 4.93913C7.13234 4.90941 7.14845 4.86348 7.12696 4.82926C7.10906 4.80135 7.07593 4.78694 7.04459 4.77973Z" fill="currentColor"></path>
                                            <path d="M3.32156 13.5127C2.21752 13.7297 1.49225 14.1719 1.19139 14.8167C0.936203 15.3453 0.936203 15.9586 1.19139 16.4872C1.65163 17.4851 3.13531 17.8066 3.71195 17.8885C3.83104 17.9065 3.92595 17.8038 3.91342 17.6832C3.61883 14.9167 5.9621 13.6046 6.56918 13.3029C6.59425 13.2885 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2047 3.84358 13.3848 3.32156 13.5127Z" fill="currentColor"></path>
                                        </svg>
                                    </i>
                                </OverlayTrigger>
                                <span className="item-name">Reports</span>
                                <i className="right-icon">
                                    <svg className="icon-18" xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                                    </svg>
                                </i>
                            </CustomToggle>
                            <Accordion.Collapse eventKey="sidebar-report">
                                <ul className="sub-nav">
                                    {userReports && userReports.map((menuItem, index) => (
                                        menuItem.page_type == "REPORT" && 
                                            <SidebarMenu
                                            key={index}
                                            isTag="false"
                                            staticIcon="true"
                                            pathname={menuItem.page_ui_path}
                                            title={menuItem.page_name}
                                            minititle={menuItem.page_desc}
                                        />
                                        
                                        
                                    ))}
                                    {/* <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/reports/advauth-export-report' title='Advance Auth Export' minititle='ADV EX'></SidebarMenu>
                                    <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/reports/advauth-import-report' title='Advance Auth Import' minititle='ADV IMP'></SidebarMenu>
                                    <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/reports/advauth-excess-import-report' title='Adv Auth Excess Imp' minititle='ADV EX IMP'></SidebarMenu>
                                    <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/reports/brc-report' title='BRC Report' minititle='BR'></SidebarMenu>
                                    <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/reports/bond-report' title='Bond Report' minititle='BO'></SidebarMenu>
                                    <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/reports/drawback-report' title='DBK AIR Status and Liability' minititle='DBK'></SidebarMenu>
                                    <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/reports/epcg-report' title='EPCG Report' minititle='EPCG'></SidebarMenu>
                                    <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/reports/igst-report' title='IGST Status' minititle='IGST'></SidebarMenu>
                                    <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/reports/rodtep-export-report' title='RODTEP Status /Scrip and Liability' minititle='RD'></SidebarMenu>
                                    <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/reports/rodtep-import-report' title='RODTEP Scrip Ledger' minititle='RSL'></SidebarMenu>
                                    <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/reports/clearance-time-import-report' title='Clearance Time Import' minititle='CTI'></SidebarMenu>
                                    <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/reports/clearance-time-export-report' title='Clearance Time Export' minititle='CTE'></SidebarMenu>
                                    <SidebarMenu isTag="false" staticIcon="true" pathname='/dashboard/reports/fta-report' title='FTA' minititle='FTA'></SidebarMenu> */}
                                </ul>
                            </Accordion.Collapse>
                        </Accordion.Item>
                    )}
                    <li><hr className="hr-horizontal" /></li>
                </Accordion>
            )}
        </Fragment>
    )
})

VerticalNav.displayName = "VerticalNav"
export default VerticalNav
