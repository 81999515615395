import axios from 'axios';
import { SERVER_URL } from "../const";

const token = localStorage.getItem('token');
export const getBondReportDataService = async (iec_no) => {
  try {
    // Make a GET request to fetch IGST data based on the IEC number
    const response = await axios.get(`${SERVER_URL}/get-bond-report-details?iec_no=${iec_no}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
    return response.data; // Return the data from the response
  } catch (error) {
    throw new Error('Error fetching IGST data:', error);
  }
};


export const getAdvAuthLedgerDataService = async (iec_no) => {
  try {
    // Make a GET request to fetch Rodtep data based on the IEC number
    const response = await axios.get(`${SERVER_URL}/get-adv-auth-ledger-details?iec_no=${iec_no}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
    return response.data; // Return the data from the response
  } catch (error) {
    throw new Error('Error fetching Rodtep data:', error);
  }
};