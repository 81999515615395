import React, { useState } from 'react';
import { Form, Button, Col, Row, Card } from 'react-bootstrap';
import { Spin } from 'antd';
import './frontpage.scss';
import { SERVER_URL } from '../../const';
import axios from "axios";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    designation: '',
    company: '',
    address: '',
    contact_no: '',
    email: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await addContactUsDetails();
      console.log(response);
      setLoading(false);
      // Optionally add success handling (e.g., clear form, show success message)
      window.location.reload(); // Refresh the page
    } catch (error) {
      setLoading(false);
      console.error('Error adding contact details:', error.message);
      // Optionally add error handling (e.g., show error message)
    }
    // Uncomment to enable email or WhatsApp functionality
    // sendEmail();
    // sendWhatsAppMessage();
  };

  const addContactUsDetails = async () => {
    try {
      console.log(formData); // Ensure formData is defined here
      const apiUrl = '/contact-us'; // Update with your API endpoint
      const response = await axios.post(SERVER_URL + apiUrl, formData);
      return response.data;
    } catch (error) {
      throw new Error(`Error adding contact details: ${error.message}`);
    }
  };

  const sendEmail = () => {
    const emailParams = {
      from_name: formData.name,
      designation: formData.designation,
      company: formData.company,
      address: formData.address,
      contact_no: formData.contact_no,
      email: formData.email,
      message: formData.message,
    };

    // Uncomment to enable email functionality
    // emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', emailParams, 'YOUR_USER_ID')
    //   .then((response) => {
    //     console.log('Email sent successfully!', response.status, response.text);
    //   })
    //   .catch((error) => {
    //     console.error('Failed to send email:', error);
    //   });
  };

  const sendWhatsAppMessage = () => {
    const whatsappMessage = `Name: ${formData.name}\nDesignation: ${formData.designation}\nCompany: ${formData.company}\nAddress: ${formData.address}\nContact No: ${formData.contact_no}\nEmail: ${formData.email}\nMessage: ${formData.message}`;
    const whatsappUrl = `https://wa.me/918007004160?text=${encodeURIComponent(whatsappMessage)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="contact-us-container">
      <Spin spinning={loading} size="large">

      
      <Card className="contact-us-card">
        <Card.Header as="h2" className="contact-us-card-header">Contact Us</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} controlId="formName" className="mb-2">
              <Form.Label column sm="3" className="contact-us-form-label">Name</Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  name="name"
                  className="contact-us-form-control"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formDesignation" className="mb-2">
              <Form.Label column sm="3" className="contact-us-form-label">Designation</Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  name="designation"
                  className="contact-us-form-control"
                  value={formData.designation}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formCompany" className="mb-2">
              <Form.Label column sm="3" className="contact-us-form-label">Co. Name</Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  name="company"
                  className="contact-us-form-control"
                  value={formData.company}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formAddress" className="mb-2">
              <Form.Label column sm="3" className="contact-us-form-label">Address</Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  name="address"
                  className="contact-us-form-control"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formContactNo" className="mb-2">
              <Form.Label column sm="3" className="contact-us-form-label">Contact No.</Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  name="contact_no"
                  className="contact-us-form-control"
                  value={formData.contact_no}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formEmail" className="mb-2">
              <Form.Label column sm="3" className="contact-us-form-label">E-Mail ID</Form.Label>
              <Col sm="9">
                <Form.Control
                  type="email"
                  name="email"
                  className="contact-us-form-control"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formMessage" className="mb-2">
              <Form.Label column sm="3" className="contact-us-form-label">Message</Form.Label>
              <Col sm="9">
                <Form.Control
                  as="textarea"
                  name="message"
                  className="contact-us-form-control"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Form.Group>

            <Row className="justify-content-center">
              <Button type="submit" variant="primary" className="contact-us-submit-btn mt-3">
                Submit
              </Button>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      </Spin>
    </div>
  );
};

export default ContactUs;
