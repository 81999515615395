import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import CardComponent from './CardComponent';
import BondFormComponent from './BondFormComponent';
import { useLocation } from 'react-router-dom';
import StdCretificateFormComponent from './ManualDataEntryComponent';

const UploadFiles = () => {
  const [selectedCardType, setSelectedCardType] = useState('AEO'); // Default value for selected card type
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const cardTypeFromURL = params.get('__card__');

    if (cardTypeFromURL) {
      setSelectedCardType(cardTypeFromURL);
    } else {
      setSelectedCardType('AEO'); // Default selected card type
    }
  }, [location]);

  const renderSelectedCard = () => {
    switch (selectedCardType) {
      case 'IEC':
      case 'BRC':
      case 'EPCG':
      case 'ADV_AUTH':
      case 'DBK_SCROLL':
      case 'DBK_PENDING':
      case 'RODTEP_SCROLL':
      case 'RODTEP_SCRIP':
      case 'IGST_SCROLL':
      case 'FTA':
        return <CardComponent title={selectedCardType} />;

      case 'BOND':
        return <BondFormComponent title="BOND" />;

      case 'EH':
      case 'AEO':
      case 'DSC':
      case 'RCMC':
      case 'FSP':
        return <StdCretificateFormComponent title={selectedCardType} />;

      default:
        return null;
    }
  };

  return (
    <Fragment>
      <Row>
        <Col xl="12" lg="12">
          {renderSelectedCard()}
        </Col>
      </Row>
    </Fragment>
  );
};

export default UploadFiles;
