import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import './frontpage.scss'
const KeyFeatures = () => {
  return (
    <Container className="my-5">
      <h1 className="mb-4">Key Features</h1>
      <Row className="mt-4">
        <Col md={6}>
          <Card className="mb-4 custom-card">
            <Card.Header className="card-title">Standard Registrations and Certifications</Card.Header>
            <Card.Body className="card-text">
              <ul>
                <li>Stay up to date with renewal and expiration alerts for essential registrations:</li>
                <ul>
                  <li>Importer Exporter Code (IEC)</li>
                  <li>Registration-Cum-Membership Certificate (RCMC)</li>
                  <li>Status certificate</li>
                  <li>Authorized Economic Operator (AEO)</li>
                  <li>Digital Signature Certificate (DSC)</li>
                </ul>
              </ul>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="mb-4 custom-card">
            <Card.Header className="card-title">Tax-Efficient Supply Chain Structuring</Card.Header>
            <Card.Body className="card-text">
              <p>Optimize your supply chain by considering clearance turnaround time (TAT) and penalties related to interest fines.</p>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="mb-4 custom-card">
            <Card.Header className="card-title">Free Trade Agreements (FTAs)</Card.Header>
            <Card.Body className="card-text">
              <p>Opportunities to effectively Import Duty saving under FTA / PTA</p>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="mb-4 custom-card">
            <Card.Header className="card-title">EDPMS Status and Alerts</Card.Header>
            <Card.Body className="card-text">
              <p>Monitor Export Data Processing and Monitoring System (EDPMS) status with timely alerts with respect to Export proceed realisation and FEMA</p>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="mb-4 custom-card">
            <Card.Header className="card-title">Duty Exemption Authorizations and Obligations</Card.Header>
            <Card.Body className="card-text">
              <ul>
                <li>Users can access detailed itemized controls for EO (Export Obligation) fulfillment under Advance Authorisation and EPCG schemes.</li>
                <li>Track your import entitlements, ledgers, and expiration alerts for Authorizations under the Foreign Trade Policy.</li>
                <li>Control and track your export obligations, and Obligation period expiration alerts for Advance Authorizations and EPCG Authorizations, incidental to EDPMS status.</li>
              </ul>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="mb-4 custom-card">
            <Card.Header className="card-title">Duty Refund and Incentives</Card.Header>
            <Card.Body className="card-text">
              <ul>
                <li>Calculate entitlements, status, and EDPMS-based liability for:</li>
                <ul>
                  <li>Duty Drawback (AIR-DBK)</li>
                  <li>Remission of Duties and Taxes on Exported Products (RoDTEP)</li>
                </ul>
                <li>Monitor RoDTEP utilization, balance confirmation, and validity with alerts.</li>
                <li>Check IGST refund entitlement and status.</li>
              </ul>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="mb-4 custom-card">
            <Card.Header className="card-title">Customs Various Bonds/BGs</Card.Header>
            <Card.Body className="card-text">
              <p>Efficiently manage utilization, balance confirmation, and validity of various Customs bonds.</p>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="mb-4 custom-card">
            <Card.Header className="card-title">Clearance Turnaround Time (TAT)</Card.Header>
            <Card.Body className="card-text">
              <p>Importers and exporters can find information on the expected time for customs clearance.</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default KeyFeatures;
