import React, { useEffect, memo, Fragment, useState } from "react";
import { useLocation, Outlet } from "react-router-dom";

// header
import Headerpro from "../../components/partials/pro/headerstyle/header-pro";

//subheader
import SubHeader from "../../components/partials/dashboard/headerstyle/sub-header";

//sidebar
import Sidebar from "../../components/partials/dashboard/sidebarstyle/sidebar";

//footer
import Footer from "../../components/partials/dashboard/footerstyle/footer";

//seetingoffCanvas
import SettingOffCanvas from "../../components/setting/SettingOffCanvas";

import Loader from "../../components/Loader";

// Import selectors & action from setting store
import * as SettingSelector from "../../store/setting/selectors";

// Redux Selector / Action
import { useSelector } from "react-redux";
import { Suspense } from "react";

const Default = memo((props) => {
  let location = useLocation();
  const pageLayout = useSelector(SettingSelector.page_layout);
  const appName = useSelector(SettingSelector.app_name);
  const [userDetails, setUserDetails] = useState({
    role: localStorage.getItem('role'),
    iec: localStorage.getItem('iec_no'),
    name: localStorage.getItem('name')
})
  useEffect(() => {});


  var subHeader = "";
  var commanclass = "";
  switch (location.pathname) {
    case "/dashboard/special-pages/calender":
    case "/dashboard/special-pages/billing":
    case "/dashboard/special-pages/kanban":
    case "/dashboard/special-pages/pricing":
    case "/dashboard/special-pages/timeline":
    case "/plugins/ui-color":
    case "/dashboard/widget/widgetbasic":
    case "/dashboard/widget/widgetchart":
    case "/dashboard/widget/widgetcard":
    case "/dashboard/map/google":
    case "/dashboard/map/vector":
    case "/dashboard/form/form-element":
    case "/dashboard/form/form-wizard":
    case "/dashboard/form/form-validation":
    case "/dashboard/table/table-data":
    case "/dashboard/table/bootstrap-table":
    case "/dashboard/table/border-table":
    case "/dashboard/table/fancy-table":
    case "/dashboard/table/fixed-table":
    case "/dashboard/app/user-list":
    case "/dashboard/icon/solid":
    case "/dashboard/icon/outline":
    case "/dashboard/icon/dual-tone":
      subHeader = <SubHeader />;
      commanclass = "iq-banner default";
      break;
    default:
      break;
  }

  return (
    <Fragment>
        <Loader />
        {userDetails && userDetails.role ? <Sidebar app_name={appName} /> : null}
        
        <main className="main-content">
          <div className={`${commanclass} position-relative `}>
            <Headerpro />
            {subHeader}
          </div>
          <div className={` ${pageLayout} content-inner pb-0`}>
            <Suspense fallback={<div className="react-load"></div>}>
              <Outlet></Outlet>
            </Suspense>
          </div>
          <Footer />
        </main>
        {/* <SettingOffCanvas /> */}
    </Fragment>
  );
});

Default.displayName = "Default";
export default Default;
