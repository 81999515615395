import  React,{ memo, Fragment,useState,useEffect } from 'react'

// React-bootstrap
import { Row, Col,  FormCheck, Form, Button,Alert } from 'react-bootstrap'

// Components
import Card from '../../../../../components/bootstrap/card'

// Router
import { useNavigate } from 'react-router-dom'; // version 5.2.0
import { loginService } from '../../../../../services/UserService';

const SignIn = memo(() => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [signInError, setSignInError] = useState(null); // State to hold sign-in error

    const handleSignIn = async (e) => {
      e.preventDefault();
      const userDetails = {
        email: email,
        password: password
    }
    localStorage.clear();
      // Perform API call for sign in
      try {
        console.log('e',userDetails)
        const response = await loginService(userDetails);
        console.log('response--',response)
        if (response.status === 202) {
          // Handle successful sign-in (e.g., redirect to dashboard)
          console.log('Sign-in successful!');
          localStorage.setItem('token', response.data.data.token);
          localStorage.setItem('role', response.data.data.role);
          localStorage.setItem('name', response.data.data.name);
          localStorage.setItem('iec_no', response.data.data.iec_no);
          navigate('/dashboard');
          window.location.reload();
        } else {
           // Handle sign-in error (e.g., show error message)
           console.error('Sign-in failed');
           setSignInError('Invalid email or password.'); // Set sign-in error message

         }
      } catch (error) {
        console.error('Error during sign-in:', error);
        setSignInError('Invalid email or password.'); // Set sign-in error message

      }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token){
            if (token.length > 0){
                navigate("/");
            }
        }
    }, []);

    return (
        <Fragment>
            <section className="iq-auth-page">
                <Row className="d-flex align-items-center justify-content-center  w-100" style={{height:"90vh"}}>
                    <Col md="10" xl="10">
                        <Card className="p-4">
                            <Card.Body>
                                <h3 className="text-center">Sign In</h3>
                                <p className="text-center">Sign in to stay connected</p>
                                <Form onSubmit={handleSignIn}>
                                 <Form.Group className="mb-3">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter email"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Enter password"
                                    />
                                </Form.Group>
                                <div className="d-flex justify-content-between">
                                    {/* <Form.Check className="d-inline-block mt-2 pt-1">
                                        <FormCheck.Input type="checkbox" id="customCheck11"></FormCheck.Input>
                                        <Form.Check.Label htmlFor="customCheck11">Remember Me</Form.Check.Label>
                                    </Form.Check> */}
                                    {/* <Link to="/auth/reset-password">Forget password</Link> */}
                                </div>
                                <div className="text-center mt-2 pb-3">
                                    <Button type="submit">Sign in</Button>
                                </div>
                                </Form>
                                {signInError && <Alert variant="danger">{signInError}</Alert>} {/* Display sign-in error message */}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </section>
        </Fragment>
    )
})

SignIn.displayName = "SignIn"
export default SignIn
