import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Table, Button ,message ,Spin} from 'antd';
import Card from '../../../components/bootstrap/card';
import { Col, Row } from 'react-bootstrap';
import { Excel } from 'antd-table-saveas-excel';
import { iecWiseAdvAuthDetailsService } from '../../../services/AdvanceAuthReportService';
import { determineRemark } from '../utils/RemarkAllocation';
import { getReportSummaryData } from '../../../services/FileDataService';
import { getTodaysDate } from '../../../utilities/utils';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const AdvAuthDetailsReport = ({ data }) => {
    const reportType = 'ADV_AUTH';
    const [loading, setLoading] = useState(true);
    const [isLoading2, setIsLoading2] = useState(false);
    const [summaryDashboardData, setSummaryDashboardData] = useState([]);
    const [jsonData, setJsonData] = useState([]);
    const [error, setError] = useState(null);
    const iec_no = localStorage.getItem('iec_no');
    const username = localStorage.getItem('name');
    const today = new Date();
  // Extract day, month, and year components
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const year = today.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;

      const getDateOnly = (dateString) => {
        if(dateString && dateString.length !== 0){
          const date = new Date(dateString);
          return date;
        }
        else{
          return '';
        }
      }
    
      const exportToExcel = async () => {
        let workbook = new ExcelJS.Workbook();
        let summaryWorksheet = workbook.addWorksheet('Summary');
        let worksheet = workbook.addWorksheet('Data');
        var data = jsonData;
    
        // Define columns for Excel export
        summaryWorksheet.columns = dashboardReportSummaryColumns;
        // Apply styles to the header row (Row 1)
        summaryWorksheet.getRow(1).eachCell((cell) => {
          cell.font = { bold: true }; // Bold text
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFC000' }, // Yellow background
          };
          cell.alignment = { vertical: 'middle'}; // Center align
        });
    
        summaryDashboardData.forEach((obj) => {
          summaryWorksheet.addRow({
            ...obj,
            import_date: getDateOnly(obj.import_date),
          })
        })
    
        worksheet.columns = columns;
        // Apply styles to the header row (Row 1)
        worksheet.getRow(1).eachCell((cell) => {
          cell.font = { bold: true }; // Bold text
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFC000' }, // Yellow background
          };
          cell.alignment = { vertical: 'middle' }; // Center align
        });
    
        data.forEach((obj) => {
          worksheet.addRow({
            ...obj,
            import_date: getDateOnly(obj.import_date),
            sb_date: getDateOnly(obj.sb_date),
            export_obligation_period: getDateOnly(obj.export_obligation_period),
            date_of_realization : getDateOnly(obj.date_of_realization),
            fob_value : obj.fob_value ? parseFloat(obj.fob_value) : '',
            fob_for_value : obj.fob_for_value ? parseFloat(obj.fob_for_value) : '',
            quantity: obj.quantity ? parseFloat(obj.quantity) : '',
            expqty: obj.expqty ? parseFloat(obj.expqty) : '',
            value_difference: obj.value_difference ? parseFloat(obj.value_difference) : '',
            realised_value: obj.realised_value ? parseFloat(obj.realised_value) : '',
          })
          
        });
    
        // Generate Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        
        // Use FileSaver to save the file
        saveAs(blob, `Lucrative's CIP DSS - Advance Authorisation EO as on ${getTodaysDate()} of ${iec_no}.xlsx`);
      };
    
      const columns = [
        {
          title: 'ADV. AUTH. NO.',
          dataIndex: 'auth_number',
          key: 'auth_number',
          header: 'ADV. AUTH. NO.',
        },
        {
          title: 'ADV. AUTH. DT.',
          dataIndex: 'import_date',
          key: 'import_date',
          header: 'ADV. AUTH. DT.',
          render: (text, record) => {
            if(record.import_date){
              return formatDateToDDMMYY(record.import_date);
            }
          }
        },
        {
          title: 'FY',
          dataIndex: 'fy',
          key: 'fy',
          header: 'FY',
        },
        {
          title: 'EXPORT ITEM SR. NO. AS PER AUTH.',
          dataIndex: 'export_sr_no',
          key: 'export_sr_no',
          header: 'EXPORT ITEM SR. NO. AS PER AUTH.',
        },
        {
          title: 'EO-DESCRIPTION',
          dataIndex: 'eo_description',
          key: 'eo_description',
          header: 'EO-DESCRIPTION',
        },
        {
          title: 'EO-QTY.',
          dataIndex: 'quantity',
          key: 'quantity',
          header: 'EO-QTY.',
        },
        {
          title: 'EO-UOM',
          dataIndex: 'uom',
          key: 'uom',
          header: 'EO-UOM',
        },
        {
          title: 'EO-VALUE IN INR',
          dataIndex: 'fob_for_value',
          key: 'fob_for_value',
          header: 'EO-VALUE IN INR',
        },
        {
          title: 'IEC/BR CODE',
          dataIndex: 'iec_br',
          key: 'iec_br',
          header: 'IEC/BR CODE',
        },
        {
          title: 'PORT',
          dataIndex: 'port_code',
          key: 'port_code',
          header: 'PORT',
        },
        {
          title: 'REF. CODE',
          dataIndex: 'reference_code',
          key: 'reference_code',
          header: 'REF. CODE',
        },
        {
          title: 'SHB NO.',
          dataIndex: 'sb_no',
          key: 'sb_no',
          header: 'SHB NO.',
        },
        {
          title: 'SHB DT.',
          dataIndex: 'sb_date',
          key: 'sb_date',
          header: 'SHB DT.',
        },
        {
          title: 'HSN CODE',
          dataIndex: 'hs_cd',
          key: 'hs_cd',
          header: 'HSN CODE',
        },
        {
          title: 'EXP. ITEM SR. NO.',
          dataIndex: 'exp_s_no',
          key: 'exp_s_no',
          header: 'EXP. ITEM SR. NO.',
        },
        {
          title: 'SHB - DESCRIPTION',
          dataIndex: 'description',
          key: 'description',
          header: 'SHB - DESCRIPTION',
        },
        {
          title: 'SHB-QTY.',
          dataIndex: 'expqty',
          key: 'expqty',
          header: 'SHB-QTY.',
        },
        {
          title: 'SHB-VALUE IN INR',
          dataIndex: 'fob_value',
          key: 'fob_value',
          header: 'SHB-VALUE IN INR',
        },
        {
          title: 'EOP DT.',
          dataIndex: 'export_obligation_period',
          key: 'export_obligation_period',
          header: 'EOP DT.',
          render: (text, record) => {
            if(record.export_obligation_period){
              return formatDateToDDMMYY(record.export_obligation_period);
            }
          },
        },
        {
          title: 'BRC NO.',
          dataIndex: 'brcNo',
          key: 'brcNo',
          header: 'BRC NO.',
        },
        {
          title: 'DATE OF REALISATION',
          dataIndex: 'date_of_realization',
          key: 'date_of_realization',
          header: 'DATE OF REALISATION',
          render: (text, record) => {
            if (record.date_of_realization) {
              return formatDateToDDMMYY(record.date_of_realization);
            }
          }
        },
        {
          title: 'VALUE',
          dataIndex: 'realised_value',
          key: 'realised_value',
          header: 'VALUE',
        },
        {
          title: 'FCC',
          dataIndex: 'currency',
          key: 'currency',
          header: 'FCC',
        },
        {
          title: 'PORT/FCC DIFF.',
          dataIndex: 'port_currency_difference',
          key: 'port_currency_difference',
          header: 'PORT/FCC DIFF.',
        },
        {
          title: 'VALUE DIFF.',
          dataIndex: 'value_difference',
          key: 'value_difference',
          header: 'VALUE DIFF.',
        },
        {
          title: 'RELEASED IN (DAYS)',
          dataIndex: 'payment_released_in_days',
          key: 'payment_released_in_days',
          header: 'RELEASED IN (DAYS)',
        },
        {
          title: 'OBSERVATION',
          dataIndex: 'brc_remark',
          key: 'brc_remark',
          header: 'OBSERVATION',
        },
      ];
      
      const dashboardReportSummaryColumns = [
        {
          title: 'ADV. AUTH. NO.',
          dataIndex: 'auth_number',
          key: 'auth_number',
          header: 'ADV. AUTH. NO.',
        },
        {
          title: 'ADV. AUTH DATE',
          dataIndex: 'import_date',
          key: 'import_date',
          header: 'ADV. AUTH DATE',
        },
        {
          title: 'EXPORT ITEM SR. NO. AS PER AUTH.',
          dataIndex: 'export_sr_no',
          key: 'export_sr_no',
          header: 'EXPORT ITEM SR. NO. AS PER AUTH.',
          align: 'right'
        },
        {
          title: 'EO-DESCRIPTION',
          dataIndex: 'eo_description',
          key: 'eo_description',
          header: 'EO-DESCRIPTION',
        },
        {
          title: 'EO-QTY',
          dataIndex: 'eo_qty',
          key: 'eo_qty',
          header: 'EO-QTY',
          align: 'right'
        },
        {
          title: 'EO-UOM',
          dataIndex: 'uom',
          key: 'uom',
          header: 'EO-UOM',
        },
        {
          title: 'EO-VALUE IN INR',
          dataIndex: 'eo_value_in_fcc',
          key: 'eo_value_in_fcc',
          header: 'EO-VALUE IN INR',
          align: 'right'
        },
        {
          title: 'EO-FULFILLED QTY',
          dataIndex: 'eo_fulfilled_qty',
          key: 'eo_fulfilled_qty',
          header: 'EO-FULFILLED QTY',
          align: 'right'
        },
        {
          title: 'EO-FULFILLED VALUE',
          dataIndex: 'eo_fulfilled_val',
          key: 'eo_fulfilled_val',
          header: 'EO-FULFILLED VALUE',
          align: 'right'
        },
        {
          title: 'BAL. EO - QTY',
          dataIndex: 'bal_eo_qty',
          key: 'bal_eo_qty',
          header: 'BAL. EO - QTY',
          align: 'right'
        },
        {
          title: 'BAL. EO - VALUE',
          dataIndex: 'bal_eo_value',
          key: 'bal_eo_value',
          header: 'BAL. EO - VALUE',
          align: 'right'
        },
        {
          title: 'BAL. EOP',
          dataIndex: 'bal_eop',
          key: 'bal_eop',
          header: 'BAL. EOP',
          align: 'right'
        },
      ];
      

  function formatDateToDDMMYY(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return ''; // Return empty string if the dateString is not a valid date
    }
    else {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear()).slice(-2);
      return `${day}/${month}/${year}`;
    }

  }

  const getSummaryDashboard = async () => {
    try{
        setIsLoading2(true)
        let reportTypes = 'AdvAuth-EO';
        const response = await getReportSummaryData(reportTypes);

        if(response){
            console.log("response: ", response.data);
            if(response.data){
                setSummaryDashboardData(response.data);
            }
        }
        else{
            console.log("getSummaryDashboard :: no reponse")
        }
        setIsLoading2(false)
    }
    catch(err){
        setIsLoading2(false)
        console.log(err);
    }  
  }

  const fetchData = async () => {
    try {
        const response = await iecWiseAdvAuthDetailsService(iec_no);
        setJsonData(response);
    } catch (error) {
        setError(error.message);
        console.error('Error fetching data:', error);
        message.error(`Error fetching data: ${error.message}`);
    } finally {
        setLoading(false);
    }
  };

  useEffect(() => {
    console.log("userState");
    getSummaryDashboard();
    fetchData();
}, [iec_no]); 

  const preprocessData = (data) => {
    return data.map(record => {
      if (record.sb_date) {
        const sbDate = new Date(record.sb_date);
        // Extract fiscal year from sb_date
        // const fiscalYearStartYear = sbDate.getMonth() >= 3 ? sbDate.getFullYear() : sbDate.getFullYear() - 1;
        // const fiscalYearEndYear = fiscalYearStartYear + 1;
        // const fiscalYear = `AM${fiscalYearEndYear.toString().slice(-2)}`;
        // record.FY = fiscalYear;
        // Format sb_date

        record.sb_date = formatDateToDDMMYY(sbDate);
      } else {
        // record.FY = '';
        // record.sb_date = '';
        // record.sbDate = '';
      }
      
      return record;
    });
  };

  return (
    <Fragment>
    <Row>
        <Col sm="12">
            <Card>
                <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                        <h4 className="card-title">Lucrative's CIP DSS  -  Advance Authorisation EO as on {formattedDate} of {username}</h4>
                    </div>
                    <div>
                        <button
                        className="btn btn-primary"
                         
                            onClick={exportToExcel}
                       
                            disabled={loading || error} // Disable button if loading or error
                        >
                            Export to Excel
                        </button>
                    </div>
                </Card.Header>
                <Card.Body className="px-0">
                <div className="table-responsive">
                  <Table
                      className="report-summary-table"
                      width={400}
                      columns={dashboardReportSummaryColumns} 
                      dataSource={summaryDashboardData}
                      pagination={false}
                      loading={isLoading2}
                    />
                </div>
                  
                    <hr />
                    <div className="table-responsive">
                        {loading ? (
                            <div style={{ textAlign: 'center', padding: '20px' }}>
                                <Spin size="large" />
                            </div>
                        ) : error ? (
                            <div style={{ textAlign: 'center', padding: '20px' }}>
                                <p>Error loading data: {error}</p>
                            </div>
                        ) : (
                            <Table columns={columns} dataSource={jsonData} pagination={false} />
                        )}
                    </div>
                </Card.Body>
            </Card>
        </Col>
    </Row>
</Fragment>
  );
};

export default AdvAuthDetailsReport;
