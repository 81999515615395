import React,{ useEffect , memo,Fragment} from 'react'
import  HeaderStyle4 from  '../../components/partials/dashboard/headerstyle/header-style-4'

const DualCompact = memo((props) => {

  useEffect(() => {
    document.body.classList.add('dual-compact')
      return () =>{
        document.body.classList.remove('dual-compact')
      }
  })

    return (
        <Fragment>
          <span className="screen-darken"></span>
          <main className="main-content">
            <HeaderStyle4 />         
          </main>
        </Fragment>
    )
}
)

DualCompact.displayName="DualCompact"
export default DualCompact
