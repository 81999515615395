// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-links .nav-link{
    font-size: 1.2rem !important;
}

/* .contact-us-link{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
} */`, "",{"version":3,"sources":["webpack://./src/components/partials/dashboard/headerstyle/header-style.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;;AAEA;;;;GAIG","sourcesContent":[".nav-links .nav-link{\n    font-size: 1.2rem !important;\n}\n\n/* .contact-us-link{\n    padding-top: 0.75rem;\n    padding-bottom: 0.75rem;\n    color: #6c757d;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
