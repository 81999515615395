import React, { Fragment, memo, useEffect } from "react";
import {  Col, Row } from "react-bootstrap";
import { Link, useLocation } from 'react-router-dom'


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// image
import image1 from "../../../../assets/modules/landing-pages/images/home-4/Picture2.jpg";

import Footer from "../../../../components/partials/dashboard/footerstyle/footer";
import SignIn from "../../auth/pages/simple/sign-in";
import DualCompact from "../../../../layouts/dashboard/dual-compact";
const StartUpLandingPage = memo((props) => {
  return (
    <Fragment>
      <div className="inner-box ">
        <DualCompact />
        {/* <Header1 /> */}
        <Row className="align-items-center bg-image-main" style={{height: '60vh !important'}}> 
          <Col lg={8} style={{height:"62vh"}}>
          <p style={{color:"black",marginLeft:'5rem', textAlign:'justify', fontSize: '1.17rem'}}>Welcome to the Signal of <b>Compliance Information Portal (CIP-Signal) for Export and Imports </b>– your comprehensive resource for Customs procedures, compliance, and trade facilitation. Whether you’re involved in imports or exports, CIP provides essential information to streamline your trade operations</p>
          <p style={{color:"black",marginLeft:'5rem', textAlign:'justify', fontSize: '1.17rem'}}><b><u>Our aim is to create compliance-oriented Industry and we are pioneers in ground-breaking solutions with most effective Implementation of Trade Compliance SOP’s . We are India’s 1st SaaS based COMPLIANCE INFORMATION PORTAL 
          </u></b></p>
          <p style={{color:"black",marginLeft:'5rem', textAlign:'justify', fontSize: '1.17rem'}}>With 20 years ample experience we understand the importance of timely Compliance with efficiency and accuracy. Anyone who is a part of Internation Tade operations must aware the importance of Rules &  Regulations and consequences of non-complaint.</p>
          <p style={{color:"black",marginLeft:'5rem', textAlign:'justify', fontSize: '1.17rem'}}>Thus, we wish to provide you a solution which has been defined by our experiences on Customs compliances and Foreign Trade Policy supported with our knowledge of the key issues of operations and reporting.</p>
          
          <p style={{color:"black",marginLeft:'5rem', textAlign:'justify', fontSize: '1.17rem'}}>
            By using CIP  - Signal,  Any company can benefited from growing goodwill and It unlocks a treasure trove of benefits for your business. Say goodbye to penalty fees and unnecessary interest charges with Signal's proactive alerts. Streamlined compliance processes save you valuable time and resources, allowing you to focus on growth. Signal helps you maximize claims of export incentives and benefits, boosting your profitability. And most importantly, Signal empowers you with confidence and peace of mind, knowing that your export operations are compliant and risk-free.
          </p>
          </Col>
          <Col lg={4}>
            <div className="d-flex align-items-center mb-lg-0 mb-0 pt-2 mt-5" style={{
              position: 'relative',
              zIndex: '1'
            }}>
              <SignIn />
            </div>
          </Col>
          <Footer />
        </Row>
      </div>
    </Fragment>
  );
});

export default StartUpLandingPage;
