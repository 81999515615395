import React, { Fragment, useState, useEffect, useRef } from 'react';
import { iecWiseBrcDetailsService } from '../../../services/BrcReportService';
import { Table, Button,Spin, message  } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { Excel } from 'antd-table-saveas-excel';
import Card from '../../../components/bootstrap/card';
import { getAdvAuthLedgerDataService } from '../../../services/BOEReportService';
import { getReportSummaryData } from '../../../services/FileDataService';
import { getTodaysDate } from '../../../utilities/utils';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const AdvAuthLedgerReport = ({ data }) => {
    const [jsonData, setJsonData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isLoading2, setIsLoading2] = useState(false);
    const [summaryDashboardData, setSummaryDashboardData] = useState([]);
    const [error, setError] = useState(null);
    const iec_no = localStorage.getItem('iec_no');
    const username = localStorage.getItem('name');

    const today = new Date();
    // Extract day, month, and year components
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = today.getFullYear();
    const reportType = 'BRC';
    // Concatenate components with hyphens
    const formattedDate = `${day}-${month}-${year}`;
  // Define columns for the table
  const columns = [
    {
      title: 'ADV. AUTH. NO.',
      dataIndex: 'auth_number',
      key: 'auth_number',
      header: 'ADV. AUTH. NO.',
    },
    {
      title: 'ADV. AUTH. DT.',
      dataIndex: 'import_date',
      key: 'import_date',
      header: 'ADV. AUTH. DT.',
      render: (text, record) => {
        if(record.import_date){
          return formatDateToDDMMYY(record.import_date);
        }
      }
    },  
    {
      title: 'IMPORT ITEM SR. NO. AS PER LIC.',
      dataIndex: 'import_sr_no',
      key: 'import_sr_no',
      header: 'IMPORT ITEM SR. NO. AS PER LIC.',
    },
    {
      title: 'AUTH. DESCRIPTION',
      dataIndex: 'auth_description',
      key: 'auth_description',
      header: 'AUTH. DESCRIPTION',
    },
    {
      title: 'ATHO. QTY.',
      dataIndex: 'quantity',
      key: 'quantity',
      header: 'ATHO. QTY.',
    },
    {
      title: 'ATHO. UOM.',
      dataIndex: 'uom',
      key: 'uom',
      header: 'ATHO. UOM.',
    },
    {
      title: 'IEC/BR CODE',
      dataIndex: 'iec_br',
      key: 'iec_br',
      header: 'IEC/BR CODE',
    },
    {
      title: 'PORT',
      dataIndex: 'port',
      key: 'port',
      header: 'PORT',
    },
    {
      title: 'REF. CODE',
      dataIndex: 'reference_code',
      key: 'reference_code',
      header: 'REF. CODE',
    },
    {
      title: 'BOE NO.',
      dataIndex: 'be_no',
      key: 'be_no',
      header: 'BOE NO.',
    },
    {
      title: 'BOE DTD.',
      dataIndex: 'be_date',
      key: 'be_date',
      header: 'BOE DTD.',
      render: (text, record) => {
        if (record.be_date) {
          return formatDateToDDMMYY(record.be_date);
        }

      }
    },
    {
      title: 'BOE QTY',
      dataIndex: 'qty',
      key: 'qty',
      header: 'BOE QTY',
    },
  ];
  
  const dashboardReportSummaryColumns = [
    {
      title: 'ADV. AUTH. NO.',
      dataIndex: 'auth_number',
      key: 'auth_number',
      header: 'ADV. AUTH. NO.',
    },
    {
      title: 'ADV. AUTH. DT.',
      dataIndex: 'import_date',
      key: 'import_date',
      header: 'ADV. AUTH. DT.',
    },
    {
      title: 'IMPORT ITEM SR. NO. AS PER LIC.',
      dataIndex: 'import_sr_no',
      key: 'import_sr_no',
      header: 'IMPORT ITEM SR. NO. AS PER LIC.',
    },
    {
      title: 'AUTH. DESCRIPTION',
      dataIndex: 'auth_description',
      key: 'auth_description',
      header: 'AUTH. DESCRIPTION',
    },
    {
      title: 'ATHO. QTY.',
      dataIndex: 'auth_qty',
      key: 'auth_qty',
      header: 'ATHO. QTY.',
    },
    {
      title: 'ATHO. UOM.',
      dataIndex: 'auth_uom',
      key: 'auth_uom',
      header: 'ATHO. UOM.',
    },
    {
      title: 'TOL. QTY. IMPORTED',
      dataIndex: 'total_qty',
      key: 'total_qty',
      header: 'TOL. QTY. IMPORTED',
    },
    {
      title: 'BAL. QTY.',
      dataIndex: 'bal_qty',
      key: 'bal_qty',
      header: 'BAL. QTY.',
    },
    {
      title: 'VALID TILL',
      dataIndex: 'import_validity',
      key: 'import_validity',
      header: 'VALID TILL',
    },
    {
      title: 'VALID FOR (DAYS)',
      dataIndex: 'valid_for',
      key: 'valid_for',
      header: 'VALID FOR (DAYS)',
    },
    
  ];

  const getSummaryDashboard = async () => {
    try{
        setIsLoading2(true)
        let reportTypes = 'AdvAuth-ScripLedger';
        const response = await getReportSummaryData(reportTypes);

        if(response){
            console.log("response: ", response.data);
            if(response.data){
                setSummaryDashboardData(response.data);
            }
        }
        else{
            console.log("getSummaryDashboard :: no reponse")
        }
        setIsLoading2(false)
    }
    catch(err){
        setIsLoading2(false)
        console.log(err);
    }  
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAdvAuthLedgerDataService(iec_no);

        setJsonData(response);
      } catch (error) {
        setError(error.message);
        console.error('Error fetching data:', error);
        message.error(`Error fetching data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };
    getSummaryDashboard();
    fetchData();
  }, [iec_no]);

  const preprocessData = (data) => {
    return data.map(record => {
      if (record.sb_date) {
        const sbDate = new Date(record.sb_date);
        // Extract fiscal year from sb_date
        const fiscalYearStartYear = sbDate.getMonth() >= 3 ? sbDate.getFullYear() : sbDate.getFullYear() - 1;
        const fiscalYearEndYear = fiscalYearStartYear + 1;
        const fiscalYear = `AM${fiscalYearEndYear.toString().slice(-2)}`;
        record.FY = fiscalYear;
        // Format sb_date

        record.sb_date = formatDateToDDMMYY(sbDate);
      } else {
        record.FY = '';
        record.sb_date = '';
        record.sbDate = '';
      }
      return record;
    });
  };
  
  function formatDateToDDMMYY(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return ''; // Return empty string if the dateString is not a valid date
    }
    else {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear()).slice(-2);
      return `${day}/${month}/${year}`;
    }

  }

  const getDateOnly = (dateString) => {
    if(dateString && dateString.length !== 0){
      const date = new Date(dateString);
      return date;
    }
    else{
      return '';
    }
  }

  const exportToExcel = async () => {
    let workbook = new ExcelJS.Workbook();
    let summaryWorksheet = workbook.addWorksheet('Summary');
    let worksheet = workbook.addWorksheet('Data');
    var data = jsonData;

    // Define columns for Excel export
    summaryWorksheet.columns = dashboardReportSummaryColumns;
    // Apply styles to the header row (Row 1)
    summaryWorksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true }; // Bold text
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Yellow background
      };
      cell.alignment = { vertical: 'middle'}; // Center align
    });

    summaryDashboardData.forEach((obj) => {
      summaryWorksheet.addRow({
        ...obj,
        import_date: getDateOnly(obj.import_date),
        import_validity: getDateOnly(obj.import_validity)
      })
    })

    worksheet.columns = columns;
    // Apply styles to the header row (Row 1)
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true }; // Bold text
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Yellow background
      };
      cell.alignment = { vertical: 'middle' }; // Center align
    });

    data.forEach((obj) => {
      worksheet.addRow({
        ...obj,
        import_date: getDateOnly(obj.import_date),
        be_date: getDateOnly(obj.be_date),
        qty: obj.qty ? parseFloat(obj.qty) : '',
      })
      
    });

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    // Use FileSaver to save the file
    saveAs(blob, `Lucrative's CIP DSS - Adv. Auth. Ledger as on ${getTodaysDate()} of ${iec_no}.xlsx`);
  };

  return (
    <Fragment>
    <Row>
      <Col sm="12">
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">Lucrative's CIP DSS  -  Adv. Auth. Ledger as on {formattedDate} of {username}</h4>
            </div>
            <div>
              <button
              className="btn btn-primary"
              
                onClick={exportToExcel}
                
                disabled={loading || error} // Disable button if loading or error
              >
                Export to Excel
              </button>
            </div>
          </Card.Header>
          <Card.Body className="px-0">
            <div className="table-responsive">
              <Table
                  className="report-summary-table"
                  width={100}
                  columns={dashboardReportSummaryColumns} 
                  dataSource={summaryDashboardData}
                  pagination={false}
                  loading={isLoading2}
              />
            </div>
            
            <hr />
            <div className="table-responsive">
              {loading ? (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                  <Spin size="large" />
                </div>
              ) : error ? (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                  <p>Error loading data: {error}</p>
                </div>
              ) : (
                <Table bordered columns={columns} dataSource={jsonData} pagination={false} />
              )}
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Fragment>
  );
};

export default AdvAuthLedgerReport;
