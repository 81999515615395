import React, { useState, useEffect, memo, Fragment } from 'react'
import { Row, Col, Collapse, Button, Card, Modal } from 'react-bootstrap'
import { Table } from 'antd';
import { DownOutlined, UpOutlined, ArrowRightOutlined } from "@ant-design/icons"
import { GetLatestStdCertificateDataService, getDashboardReportSummaryData } from '../../services/FileDataService';
import '../../assets/scss/dashboard.scss';
import { useNavigate } from 'react-router-dom'
import Progress from '../../components/progress';



// Redux Selector / Action
import { useSelector } from 'react-redux';

// Import selectors & action from setting store
import * as SettingSelector from '../../store/setting/selectors'

//Count-up
import SubHeader from '../../components/partials/dashboard/headerstyle/sub-header';
import moment from 'moment';

const Index = memo((props) => {
    useSelector(SettingSelector.theme_color)
    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState({
        role: localStorage.getItem('role'),
        iec: localStorage.getItem('iec_no'),
        name: localStorage.getItem('name')
    })
    const [showModal, setShowModal] = useState(false);
    const [hoveredText, setHoveredText] = useState(null); // State to track hovered text
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);

    const [openCollapse, setOpenCollapse] = useState({
        "EDPMS": false,
        "DBK-AIR": false,
        "RODTEP-Exp": false,
        "AdvAuth-EO": false,
        "IGST-Refund": false,
        "EPCG-Auth-EO": false,
        "FTA-Opportunity": false,
        "Bond-Utilization": false,
        "Rodtep-Util": false,
        "AdvAuth-Utilization": false,
        "AdvAuthExcessImportLiab": false,
        "TAT-ImportClearance": false,
        "TAT-ExportClearance": false
    })

    const [summaryDashboardData, setSummaryDashboardData] = useState({});

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const showCollapseCard = (name) => {

        setOpenCollapse(prevState => {
            if(prevState[name] == false){
                getSummaryDashboard(name);
            }
            // Create a new state object with all values set to false
            const newState = Object.keys(prevState).reduce((acc, key) => {
                acc[key] = false;
                return acc;
            }, {});

            // Toggle the specified card's state
            newState[name] = !prevState[name];

            return newState;
        });
    }

    const [stdCertData, setStdCertData] = useState({})

    // Calculate days left until 31st March
    const today = moment();
    const currentYear = today.year();
    const nextMarch31 = today.isAfter(moment(`${currentYear}-03-31`))
        ? moment(`${currentYear + 1}-03-31`)
        : moment(`${currentYear}-03-31`);
    const daysLeft = nextMarch31.diff(today, 'days');
    const expiryDate = nextMarch31.format('MMMM Do, YYYY');

    const getVariableColor = () => {
        let prefix = getComputedStyle(document.body).getPropertyValue('--prefix') || 'bs-';
        if (prefix) {
            prefix = prefix.trim()
        }
        const color1 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary`);
        const color2 = getComputedStyle(document.body).getPropertyValue(`--${prefix}info`);
        const color3 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary-tint-20`);
        const color4 = getComputedStyle(document.body).getPropertyValue(`--${prefix}warning`);
        return {
            primary: color1.trim(),
            info: color2.trim(),
            warning: color4.trim(),
            primary_light: color3.trim(),
        };
    }
    const variableColors = getVariableColor();
    const colors = [variableColors.primary, variableColors.info];

    var subHeader = "";
    subHeader = <SubHeader />;
    useEffect(() => {
        return () => colors

    })



    const getSummaryDashboard = async (complianceType) => {
        try {
            setIsLoading2(true)
            // let reportTypes = ['EDPMS', 'DBK-AIR', 'RODTEP-Exp', 'IGST-Refund', 'AdvAuth-EO', 'EPCG-Auth-EO', 'FTA-Opportunity', 'TAT-ImportClearance', 'TAT-ExportClearance', 'Rodtep-Util']
            let reportTypes = []
            reportTypes.push(complianceType);
            const response = await getDashboardReportSummaryData(reportTypes);

            if (response) {
                console.log("response: ", response.data);
                if (response.data) {

                    Object.keys(response.data).forEach((key) => {
                        const data = response.data[key];
                    
                        if (data && data["headers"]) {
                            const headers = data["headers"];
                    
                            // Find the object with dataIndex = "summary"
                            const targetObject = headers.find(header => header.dataIndex === "summary");
                    
                            if (targetObject) {
                                // Add the 'render' function to the found object
                                targetObject.render = (text, record) => (
                                    <span style={{ color: record.color, fontSize: "13px" }}>
                                        <b>{text}</b>
                                    </span>
                                );
                            }
                        }
                    });
                    
                    setSummaryDashboardData(response.data);
                }

            }
            else {
                console.log("getSummaryDashboard :: no reponse")
            }
            setIsLoading2(false)
        }
        catch (err) {
            setIsLoading2(false)
            console.log(err);
        }
    }

    const getStdCertificatesDashboardData = async () => {
        const token = localStorage.getItem('token');
        // Today's date
        setIsLoading(true);
        var today = new Date();

        try {
            const response = await GetLatestStdCertificateDataService('', 3, token);

            if (response) {
                console.log("response: ", response.data.EH);
                if (response.data) {

                    setStdCertData(response.data);
                }
                else {
                    console.log("not found")
                    const statusMessage = {
                        'status_code': 404,
                        'expiry_status': 'Data not found'
                    }
                    setStdCertData(statusMessage);
                }
            }
            setIsLoading(false)
        }
        catch (err) {
            setIsLoading(false)
            console.log(err);
        }

    }

    const viewFileDetails = (billType) => {
        navigate("/dashboard/upload/upload-files?__card__=" + billType);
    }

    useEffect(() => {
        // getSummaryDashboard();
        getStdCertificatesDashboardData();
        console.log(stdCertData);

    }, [])

    const dashboardReportSummaryColumns = [

        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',

        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },

        {
            title: 'Summary',
            dataIndex: 'summary',
            key: 'summary',
            align: 'center',
            render: (text, record) => (
                <span style={{ color: record.color, fontSize: "13px" }}>
                    <b>{text}</b>
                </span>
            )
        },

    ]

    const stdCertSummaryColumns = [
        {
          title: 'File no',
          dataIndex: 'file_no',
          key: 'file_no',
          width: 50,
          render: (text, record) => (
            <span
              style={{ fontSize: "13px", color: "#0b112e", display: "inline-block", maxWidth: "100%" }}
              title={text} // Tooltip text to show full file no on hover
              onMouseEnter={() => setHoveredText(text)}
              onMouseLeave={() => setHoveredText(null)}
            >
              <b>{hoveredText === text ? text : text.length <= 5 ? text : `${text.substring(0, 5)}...`}</b>
            </span>
          )
        },
        {
          title: 'Expiry',
          dataIndex: 'expiry_date',
          key: 'expiry_date',
          width: 50,
          render: (text, record) => (
            <span
              style={{ fontSize: "13px", display: "inline-block", maxWidth: "100%" }}
              title={text} // Tooltip text to show full expiry date on hover
              onMouseEnter={() => setHoveredText(text)}
              onMouseLeave={() => setHoveredText(null)}
            >
              {hoveredText === text ? text : text.length <= 10 ? text : `${text.substring(0, 10)}...`}
            </span>
          )
        },
        {
          title: 'Status',
          dataIndex: 'expiry_status',
          key: 'expiry_status',
          width: 50,
          render: (text, record) => (
            <span
              style={{ color: record.color, fontSize: "13px", display: "inline-block", maxWidth: "100%" }}
              title={text} // Tooltip text to show full status on hover
              onMouseEnter={() => setHoveredText(text)}
              onMouseLeave={() => setHoveredText(null)}
            >
              <b>{hoveredText === text ? text : text.length <= 8 ? text : `${text.substring(0, 8)}...`}</b>
            </span>
          )
        }
      ];
    

    const clearanceReportSummaryColumns = [

        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',

        },
        {
            title: 'Summary',
            dataIndex: 'summary',
            key: 'summary',
            align: 'center',
            render: (text, record) => (
                <span style={{ color: record.color, fontSize: "13px" }}>
                    <b>{text}</b>
                </span>
            )
        },
        {
            title: 'Days',
            dataIndex: 'days',
            key: 'days',
        },

    ]
    const handleUploadClick = () => {
        navigate("/dashboard/upload/upload-files")
    };
    return (
        <Fragment>

            <div className="justify-content-between align-items-center flex-wrap mb-2 gap-2">
                <Row>
                    <Card>
                        <Card.Body>
                            <Col lg="12" md="12">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <p style={{ marginBottom: '0' }}>
                                            <h4 style={{ display: 'inline' }}>Welcome to CIP</h4> - <h4 style={{ display: 'inline' }}>{userDetails.name}</h4>
                                        </p>
                                    </div>
                                    <div className="d-flex gap-2">
                                        {userDetails.role != 'superadmin' && <Button variant="primary" onClick={handleShow}>IEC : {userDetails.iec}</Button>}
                                        <Button variant="primary" onClick={handleUploadClick}>Upload</Button>
                                    </div>

                                </div>
                            </Col>
                        </Card.Body>
                    </Card>
                </Row>
                <Modal show={showModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>IEC Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* You can put more detailed information or forms here */}
                        <p>IEC: {userDetails.iec}</p>
                        <p>Name: {userDetails.name}</p>
                        <p>Expiry Date: {expiryDate}</p>
                        <p>Days left until expiry: {daysLeft}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>

             {/* <Row>
                <Col lg="4" md="6" sm="12">
                    <Card>
                        <Card.Body>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <span><b>RCMC</b></span>

                                </div>
                                <div>
                                    <span className="badge bg-primary">View</span>
                                </div>
                            </div>

                            <div className="justify-content-between mt-2">
                                <div>
                                    <Row>
                                        <Col lg="5" md="5" sm="5">
                                            <h5>File no.</h5>
                                        </Col>
                                        
                                        <Col lg="6" md="6" sm="6">
                                            <h5 className="counter">: 40372456390</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="5" md="5" sm="5">
                                            <h5>Issue Date</h5>
                                        </Col>
                                        
                                        <Col lg="6" md="6" sm="6">
                                            <h5 className="counter">: 2nd Jan 2024</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="5" md="5" sm="5">
                                            <h5>Expiry Date</h5>
                                        </Col>
                                        
                                        <Col lg="6" md="6" sm="6">
                                            <h5 className="counter">: 2nd Jan 2024</h5>
                                        </Col>
                                    </Row>
                                     
                                    No action required
                                </div>
                                
                            </div>

                           
                            <div className="mt-3">
                                <Progress softcolors="success" color="success" className="shadow-none w-100" value={100} minvalue={0} maxvalue={100} style={{ height: "6px" }} />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="4" md="6" sm="12">
                    <Card>
                        <Card.Body>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <span><b>EH</b></span>
                                </div>
                                <div>
                                    <span className="badge bg-primary">View</span>
                                </div>
                            </div>  
                            <div className="justify-content-between mt-2">
                                <div>
                                    <Row>
                                        <Col lg="5" md="5" sm="5">
                                            <h5>File no.</h5>
                                        </Col>
                                        
                                        <Col lg="6" md="6" sm="6">
                                            <h5 className="counter">: 40372456390</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="5" md="5" sm="5">
                                            <h5>Issue Date</h5>
                                        </Col>
                                        
                                        <Col lg="6" md="6" sm="6">
                                            <h5 className="counter">: 2nd Jan 2024</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="5" md="5" sm="5">
                                            <h5>Expiry Date</h5>
                                        </Col>
                                        
                                        <Col lg="6" md="6" sm="6">
                                            <h5 className="counter">: 2nd Jan 2024</h5>
                                        </Col>
                                    </Row>
                                     
                                    
                                    No action required
                                </div>
                                
                            </div>
                            <div className="mt-3">
                                <Progress softcolors="primary" color="primary" className="shadow-none w-100" value={100} minvalue={0} maxvalue={100} style={{ height: "6px" }} />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                </Row> */}

            {userDetails.role !== 'superadmin' && <>
                <p className="text-primary mb-2">Standards</p>
                <Row>
                    <Col className="col-5ths">
                        <Card className="std-cert-card">
                            <Card.Body>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <span><b style={{ color: 'black' }}>RCMC</b></span>
                                    </div>
                                    <div>
                                        <a style={{ cursor: "pointer" }} onClick={() => viewFileDetails("RCMC")}>
                                            <span className="badge bg-primary">View</span>
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <Table
                                        className="std-cert-table"
                                        width={100}
                                        columns={stdCertSummaryColumns}
                                        dataSource={stdCertData['RCMC']}
                                        pagination={false}
                                        loading={isLoading}
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="col-5ths">
                        <Card className="std-cert-card">
                            <Card.Body>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <span><b style={{ color: 'black' }}>EH</b></span>
                                    </div>
                                    <div>
                                        <a style={{ cursor: "pointer" }} onClick={() => viewFileDetails("EH")}>
                                            <span className="badge bg-primary">View</span>
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <Table
                                        className="std-cert-table"
                                        width={100}
                                        columns={stdCertSummaryColumns}
                                        dataSource={stdCertData['EH']}
                                        pagination={false}
                                        loading={isLoading}
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="col-5ths">
                        <Card className="std-cert-card">
                            <Card.Body>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <span><b style={{ color: 'black' }}>AEO</b></span>
                                    </div>
                                    <div>
                                        <a style={{ cursor: "pointer" }} onClick={() => viewFileDetails("AEO")}>
                                            <span className="badge bg-primary">View</span>
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <Table
                                        className="std-cert-table"
                                        width={100}
                                        columns={stdCertSummaryColumns}
                                        dataSource={stdCertData['AEO']}
                                        pagination={false}
                                        loading={isLoading}
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="col-5ths">
                        <Card className="std-cert-card">
                            <Card.Body>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <span><b style={{ color: 'black' }}>DSC</b></span>
                                    </div>
                                    <div>
                                        <a style={{ cursor: "pointer" }} onClick={() => viewFileDetails("DSC")}>
                                            <span className="badge bg-primary">View</span>
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <Table
                                        className="std-cert-table"
                                        width={100}
                                        columns={stdCertSummaryColumns}
                                        dataSource={stdCertData['DSC']}
                                        pagination={false}
                                        loading={isLoading}
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>}

            {userDetails.role !== 'superadmin' && <>
                <p className="text-primary mb-2">Compliances</p>
                <Row className="mt-2 btns-row" style={{justifyContent: "center", alignItems: "center"}}>
                    <Col lg="2" md="4" className="mb-2">
                        <Button className="custom-button" onClick={() => showCollapseCard('EDPMS')} aria-controls="EDPMS" aria-expanded={openCollapse['EDPMS']}>
                            EDPMS &nbsp;
                            {openCollapse['EDPMS'] ? <UpOutlined /> : <DownOutlined />}
                        </Button>
                    </Col>
                    <Col lg="2" md="4" className="mb-2">
                        <Button className="custom-button" onClick={() => showCollapseCard('DBK-AIR')} aria-controls="DBK-AIR" aria-expanded={openCollapse['DBK-AIR']}>
                            DBK-AIR &nbsp;
                            {openCollapse['DBK-AIR'] ? <UpOutlined /> : <DownOutlined />}
                        </Button>
                    </Col>
                    <Col lg="2" md="4" className="mb-2">
                        <Button className="custom-button" onClick={() => showCollapseCard('RODTEP-Exp')} aria-controls="RODTEP-Exp" aria-expanded={openCollapse['RODTEP-Exp']}>
                            RODTEP - Exp &nbsp;
                            {openCollapse['RODTEP-Exp'] ? <UpOutlined /> : <DownOutlined />}
                        </Button>
                    </Col>
                    <Col lg="2" md="4" className="mb-2">
                        <Button className="custom-button" onClick={() => showCollapseCard('IGST-Refund')} aria-controls="IGST-Refund" aria-expanded={openCollapse['IGST-Refund']}>
                            IGST refund &nbsp;
                            {openCollapse['IGST-Refund'] ? <UpOutlined /> : <DownOutlined />}
                        </Button>
                    </Col>
                    <Col lg="2" md="4" className="mb-2">
                        <Button className="custom-button" onClick={() => showCollapseCard('AdvAuth-EO')} aria-controls="AdvAuth-EO" aria-expanded={openCollapse['AdvAuth-EO']}>
                            Adv. Auth-EO &nbsp;
                            {openCollapse['AdvAuth-EO'] ? <UpOutlined /> : <DownOutlined />}
                        </Button>
                    </Col>
                    <Col lg="2" md="4" className="mb-2">
                        <Button className="custom-button" onClick={() => showCollapseCard('EPCG-Auth-EO')} aria-controls="EPCG-Auth-EO" aria-expanded={openCollapse['EPCG-Auth-EO']}>
                            EPCG Auth-EO &nbsp;
                            {openCollapse['EPCG-Auth-EO'] ? <UpOutlined /> : <DownOutlined />}
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col lg="3" md="3"></Col>
                    <Col lg="6" md="6">
                        <Collapse in={openCollapse["EDPMS"]} >
                            <Card>
                                <Card.Header style={{ color: "black" }}><b>EDPMS</b></Card.Header>
                                <ul className="iq-header-sub-menu list-unstyled " id="EDPMS">
                                    <Table
                                        className="dash-summary-table"
                                        width={50}
                                        columns={summaryDashboardData["EDPMS"] && summaryDashboardData["EDPMS"]["headers"]}
                                        dataSource={summaryDashboardData["EDPMS"] && summaryDashboardData["EDPMS"]["dashboard_summary"]}
                                        pagination={false}
                                        loading={isLoading2}
                                    />
                                    <p style={{ paddingLeft: '5px', paddingRight: '5px', marginTop: '8px', marginBottom: "0px" }}>
                                        <a style={{ color: 'black' }} onClick={() => navigate('/dashboard/reports/brc-report')} href="#"> <ArrowRightOutlined />  Go to report summary</a>
                                    </p>
                                </ul>
                            </Card>
                        </Collapse>

                        <Collapse in={openCollapse["DBK-AIR"]} >
                            <Card>
                                <Card.Header style={{ color: "black" }}><b>DBK-AIR</b></Card.Header>
                                <ul className="iq-header-sub-menu list-unstyled " id="DBK-AIR">
                                    <Table
                                        className="dash-summary-table"
                                        width={100}
                                        columns={summaryDashboardData["DBK-AIR"] && summaryDashboardData["DBK-AIR"]["headers"]}
                                        dataSource={summaryDashboardData["DBK-AIR"] && summaryDashboardData["DBK-AIR"]["dashboard_summary"]}
                                        pagination={false}
                                        loading={isLoading2}
                                    />
                                    <p style={{ paddingLeft: '5px', paddingRight: '5px', marginTop: '8px', marginBottom: "0px" }}>
                                        <a onClick={() => navigate('/dashboard/reports/drawback-report')} href="#"> <ArrowRightOutlined />  Go to report summary</a>
                                    </p>
                                </ul>
                            </Card>
                        </Collapse>

                        <Collapse in={openCollapse["RODTEP-Exp"]} >
                            <Card>
                                <Card.Header style={{ color: "black" }}><b>RODTEP-Exp</b></Card.Header>
                                <ul className="iq-header-sub-menu list-unstyled " id="RODTEP-Exp">
                                    <Table
                                        className="dash-summary-table"
                                        width={100}
                                        columns={summaryDashboardData["RODTEP-Exp"] && summaryDashboardData["RODTEP-Exp"]["headers"]}
                                        dataSource={summaryDashboardData["RODTEP-Exp"] && summaryDashboardData["RODTEP-Exp"]["dashboard_summary"]}
                                        pagination={false}
                                        loading={isLoading2}
                                    />
                                    <p style={{ paddingLeft: '5px', paddingRight: '5px', marginTop: '8px', marginBottom: "0px" }}>
                                        <a onClick={() => navigate('/dashboard/reports/rodtep-export-report')} href="#"> <ArrowRightOutlined />  Go to report summary</a>
                                    </p>
                                </ul>
                            </Card>
                        </Collapse>

                        <Collapse in={openCollapse["AdvAuth-EO"]} >
                            <Card>
                                <Card.Header style={{ color: "black" }}><b>AdvAuth-EO</b></Card.Header>
                                <ul className="iq-header-sub-menu list-unstyled " id="AdvAuth-EO">
                                    <Table
                                        className="dash-summary-table"
                                        width={100}
                                        columns={summaryDashboardData["AdvAuth-EO"] && summaryDashboardData["AdvAuth-EO"]["headers"]}
                                        dataSource={summaryDashboardData["AdvAuth-EO"] && summaryDashboardData["AdvAuth-EO"]["dashboard_summary"]}
                                        pagination={false}
                                        loading={isLoading2}
                                    />
                                    <p style={{ paddingLeft: '5px', paddingRight: '5px', marginTop: '8px', marginBottom: "0px" }}>
                                        <a onClick={() => navigate('/dashboard/reports/advauth-export-report')} href="#"> <ArrowRightOutlined />  Go to report summary</a>
                                    </p>
                                </ul>
                            </Card>
                        </Collapse>

                        <Collapse in={openCollapse["IGST-Refund"]} >
                            <Card>
                                <Card.Header style={{ color: "black" }}><b>IGST-Refund</b></Card.Header>
                                <ul className="iq-header-sub-menu list-unstyled " id="IGST-Refund">
                                    <Table
                                        className="dash-summary-table"
                                        width={100}
                                        columns={summaryDashboardData["IGST-Refund"] && summaryDashboardData["IGST-Refund"]["headers"]}
                                        dataSource={summaryDashboardData["IGST-Refund"] && summaryDashboardData["IGST-Refund"]["dashboard_summary"]}
                                        pagination={false}
                                        loading={isLoading2}
                                    />
                                    <p style={{ paddingLeft: '5px', paddingRight: '5px', marginTop: '8px', marginBottom: "0px" }}>
                                        <a onClick={() => navigate('/dashboard/reports/igst-report')} href="#"> <ArrowRightOutlined />  Go to report summary</a>
                                    </p>
                                </ul>
                            </Card>
                        </Collapse>

                        <Collapse in={openCollapse["EPCG-Auth-EO"]} >
                            <Card>
                                <Card.Header style={{ color: "black" }}><b>EPCG-Auth-EO</b></Card.Header>
                                <ul className="iq-header-sub-menu list-unstyled " id="EPCG-Auth-EO">
                                    <Table
                                        className="dash-summary-table"
                                        width={100}
                                        columns={summaryDashboardData["EPCG-Auth-EO"] && summaryDashboardData["EPCG-Auth-EO"]["headers"]}
                                        dataSource={summaryDashboardData["EPCG-Auth-EO"] && summaryDashboardData["EPCG-Auth-EO"]["dashboard_summary"]}
                                        pagination={false}
                                        loading={isLoading2}
                                    />
                                    <p style={{ paddingLeft: '5px', paddingRight: '5px', marginTop: '8px', marginBottom: "0px" }}>
                                        <a onClick={() => navigate('/dashboard/reports/epcg-report')} href="#"> <ArrowRightOutlined />  Go to report summary</a>
                                    </p>
                                </ul>
                            </Card>
                        </Collapse>
                    </Col>
                    <Col lg="3" md="3"></Col>
                </Row>

                <Row className="btns-row" style={{justifyContent: "center", alignItems: "center"}}>
                    
                    <Col lg="2" md="4" className="mb-2">
                        <Button className="custom-button_row_two" onClick={() => showCollapseCard('FTA-Opportunity')} aria-controls="FTA-Opportunity" aria-expanded={openCollapse['FTA-Opportunity']}>
                            FTA-Opp &nbsp;
                            {openCollapse['FTA-Opportunity'] ? <UpOutlined /> : <DownOutlined />}
                        </Button>
                    </Col>
                    <Col lg="2" md="4" className="mb-2">
                        <Button className="custom-button_row_two" onClick={() => showCollapseCard('Bond-Utilization')} aria-controls="EDPMS" aria-expanded={openCollapse['EDPMS']}>
                            Bond-Utilization  &nbsp;
                            {openCollapse['Bond-Utilization'] ? <UpOutlined /> : <DownOutlined />}
                        </Button>

                    </Col>
                    <Col lg="2" md="4" className="mb-2">
                        <Button className="custom-button_row_two" onClick={() => showCollapseCard('Rodtep-Util')} aria-controls="Rodtep-Util" aria-expanded={openCollapse['Rodtep-Util']}>
                            Rodtep-Util  &nbsp;
                            {openCollapse['Rodtep-Util'] ? <UpOutlined /> : <DownOutlined />}
                        </Button>
                    </Col>
                    <Col lg="2" md="4" className="mb-2">
                        <Button className="custom-button_row_two" onClick={() => showCollapseCard('AdvAuth-Utilization')} aria-controls="AdvAuth-Utilization" aria-expanded={openCollapse['AdvAuth-Utilization']}>
                            AdvAuth-Util  &nbsp;
                            {openCollapse['AdvAuth-Utilization'] ? <UpOutlined /> : <DownOutlined />}
                        </Button>
                    </Col>
                    <Col lg="3" md="4" className="mb-2">
                        <Button className="custom-button_row_two" onClick={() => showCollapseCard('AdvAuthExcessImportLiab')} aria-controls="AdvAuthExcessImportLiab" aria-expanded={openCollapse['AdvAuthExcessImportLiab']}>
                            AdvAuthExcessImpLiab &nbsp;
                            {openCollapse['AdvAuthExcessImportLiab'] ? <UpOutlined /> : <DownOutlined />}
                        </Button>
                    </Col>
                    
                </Row>

                <Row>
                    <Col lg="3" md="3"></Col>
                    <Col lg="6" md="6">
                        <Collapse in={openCollapse["FTA-Opportunity"]} >
                            <Card>
                                <Card.Header style={{ color: "black" }}><b>FTA-Opportunity</b></Card.Header>
                                <ul className="iq-header-sub-menu list-unstyled " id="FTA-Opportunity">
                                    <Table
                                        className="dash-summary-table"
                                        width={100}
                                        columns={summaryDashboardData["FTA-Opportunity"] && summaryDashboardData["FTA-Opportunity"]["headers"]}
                                        dataSource={summaryDashboardData["FTA-Opportunity"] && summaryDashboardData["FTA-Opportunity"]["dashboard_summary"]}
                                        pagination={false}
                                        loading={isLoading2}
                                    />
                                    <p style={{ paddingLeft: '5px', paddingRight: '5px', marginTop: '8px', marginBottom: "0px" }}>
                                        <a onClick={() => navigate('/dashboard/reports/fta-report')} href="#"> <ArrowRightOutlined />  Go to report summary</a>
                                    </p>
                                </ul>
                            </Card>
                        </Collapse>

                        <Collapse in={openCollapse["AdvAuthExcessImportLiab"]} >
                            <Card>
                                <Card.Header style={{ color: "black" }}><b>AdvAuthExcessImportLiab</b></Card.Header>
                                <ul className="iq-header-sub-menu list-unstyled " id="AdvAuthExcessImportLiab">
                                    <Table
                                        className="dash-summary-table"
                                        width={100}
                                        columns={dashboardReportSummaryColumns}
                                        dataSource={summaryDashboardData["AdvAuthExcessImportLiab"] && summaryDashboardData["AdvAuthExcessImportLiab"]["dashboard_summary"]}
                                        pagination={false}
                                        loading={isLoading2}
                                    />
                                    <p style={{ paddingLeft: '5px', paddingRight: '5px', marginTop: '8px', marginBottom: "0px" }}>
                                        <a onClick={() => navigate('/dashboard/reports/advauth-excess-import-report')} href="#"> <ArrowRightOutlined />  Go to report summary</a>
                                    </p>
                                </ul>
                            </Card>
                        </Collapse>

                        <Collapse in={openCollapse["AdvAuth-Utilization"]} >
                            <Card>
                                <Card.Header style={{ color: "black" }}><b>AdvAuth-Utilization</b></Card.Header>
                                <ul className="iq-header-sub-menu list-unstyled " id="AdvAuth-Utilization">
                                    <Table
                                        className="dash-summary-table"
                                        width={100}
                                        columns={dashboardReportSummaryColumns}
                                        dataSource={summaryDashboardData["AdvAuth-Utilization"] && summaryDashboardData["AdvAuth-Utilization"]["dashboard_summary"]}
                                        pagination={false}
                                        loading={isLoading2}
                                    />
                                    <p style={{ paddingLeft: '5px', paddingRight: '5px', marginTop: '8px', marginBottom: "0px" }}>
                                        <a onClick={() => navigate('/dashboard/reports/advauth-import-report')} href="#"> <ArrowRightOutlined />  Go to report summary</a>
                                    </p>
                                </ul>
                            </Card>
                        </Collapse>

                        <Collapse in={openCollapse["Rodtep-Util"]} >
                            <Card>
                                <Card.Header style={{ color: "black" }}><b>Rodtep-Util</b></Card.Header>
                                <ul className="iq-header-sub-menu list-unstyled " id="Rodtep-Util">
                                    <Table
                                        className="dash-summary-table"
                                        width={100}
                                        columns={summaryDashboardData["Rodtep-Util"] && summaryDashboardData["Rodtep-Util"]["headers"]}
                                        dataSource={summaryDashboardData["Rodtep-Util"] && summaryDashboardData["Rodtep-Util"]["dashboard_summary"]}
                                        pagination={false}
                                        loading={isLoading2}
                                    />
                                    <p style={{ paddingLeft: '5px', paddingRight: '5px', marginTop: '8px', marginBottom: "0px" }}>
                                        <a onClick={() => navigate('/dashboard/reports/rodtep-import-report')} href="#"> <ArrowRightOutlined />  Go to report summary</a>
                                    </p>
                                </ul>
                            </Card>
                        </Collapse>

                        <Collapse in={openCollapse["Bond-Utilization"]} >
                            <Card>
                                <Card.Header style={{ color: "black" }}><b>Bond-Utilization</b></Card.Header>
                                <ul className="iq-header-sub-menu list-unstyled " id="Bond-Utilization">
                                    <Table
                                        className="dash-summary-table"
                                        width={100}
                                        columns={dashboardReportSummaryColumns}
                                        dataSource={summaryDashboardData["Bond-Utilization"] && summaryDashboardData["Bond-Utilization"]["dashboard_summary"]}
                                        pagination={false}
                                        loading={isLoading2}
                                    />
                                    <p style={{ paddingLeft: '5px', paddingRight: '5px', marginTop: '8px', marginBottom: "0px" }}>
                                        <a onClick={() => navigate('/dashboard/reports/bond-report')} href="#"> <ArrowRightOutlined />  Go to report summary</a>
                                    </p>
                                </ul>
                            </Card>
                        </Collapse>
                    </Col>
                    <Col lg="3" md="3"></Col>
                </Row>

                <Row className="btns-row" style={{justifyContent: "center", alignItems: "center"}}>
                        <Col lg="3" md="5" className="mb-2">
                            <Button className="custom-button" onClick={() => showCollapseCard('TAT-ExportClearance')} aria-controls="TAT-ExportClearance" aria-expanded={openCollapse['TAT-ExportClearance']}>
                                TAT-ExportClearance &nbsp;
                                {openCollapse['TAT-ExportClearance'] ? <UpOutlined /> : <DownOutlined />}
                            </Button>
                        </Col>
                        <Col lg="3" md="5" className="mb-2">
                            <Button className="custom-button_row_two" onClick={() => showCollapseCard('TAT-ImportClearance')} aria-controls="TAT-ImportClearance" aria-expanded={openCollapse['TAT-ImportClearance']}>
                                TAT-ImportClearance &nbsp;
                                {openCollapse['TAT-ImportClearance'] ? <UpOutlined /> : <DownOutlined />}
                            </Button>
                        </Col>
                    
                </Row>

                <Row>
                    <Col lg="3" md="3"></Col>
                    <Col lg="6" md="6">
                        <Collapse in={openCollapse["TAT-ImportClearance"]} >
                            <Card>
                                <Card.Header style={{ color: "black" }}><b>TAT-ImportClearance</b></Card.Header>
                                <ul className="iq-header-sub-menu list-unstyled " id="TAT-ImportClearance">
                                    <Table
                                        className="dash-summary-table"
                                        width={100}
                                        columns={summaryDashboardData["TAT-ImportClearance"] && summaryDashboardData["TAT-ImportClearance"]["headers"]}
                                        dataSource={summaryDashboardData["TAT-ImportClearance"] && summaryDashboardData["TAT-ImportClearance"]["dashboard_summary"]}
                                        pagination={false}
                                        loading={isLoading2}
                                    />
                                    <p style={{ paddingLeft: '5px', paddingRight: '5px', marginTop: '8px', marginBottom: "0px" }}>
                                        <a onClick={() => navigate('/dashboard/reports/clearance-time-import-report')} href="#"> <ArrowRightOutlined />  Go to report summary</a>
                                    </p>
                                </ul>
                            </Card>
                        </Collapse>

                        <Collapse in={openCollapse["TAT-ExportClearance"]} >
                            <Card>
                                <Card.Header style={{ color: "black" }}><b>TAT-ExportClearance</b></Card.Header>
                                <ul className="iq-header-sub-menu list-unstyled " id="TAT-ExportClearance">
                                    <Table
                                        className="dash-summary-table"
                                        width={100}
                                        columns={summaryDashboardData["TAT-ExportClearance"] && summaryDashboardData["TAT-ExportClearance"]["headers"]}
                                        dataSource={summaryDashboardData["TAT-ExportClearance"] && summaryDashboardData["TAT-ExportClearance"]["dashboard_summary"]}
                                        pagination={false}
                                        loading={isLoading2}
                                    />
                                    <p style={{ paddingLeft: '5px', paddingRight: '5px', marginTop: '8px', marginBottom: "0px" }}>
                                        <a onClick={() => navigate('/dashboard/reports/clearance-time-export-report')} href="#"> <ArrowRightOutlined />  Go to report summary</a>
                                    </p>
                                </ul>
                            </Card>
                        </Collapse>
                    </Col>
                    <Col lg="3" md="3"></Col>
                </Row>
            </>}
            <hr />
        </Fragment>
    )
}
)

Index.displayName = "Index"
export default Index
