
export const formatDate = (inputDate) => {
    if(inputDate){
        const date = new Date(inputDate);
        // Define an array of month names
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        // Extract day, month, and year
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();

        // Format the date
        const formattedDate = `${day} ${monthNames[monthIndex]} ${year}`;
        return formattedDate;
    }
    else{
        return inputDate;
    }
}

export const getTodaysDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());

    const hours = date.getHours();   // Returns the hour (0-23)
    const minutes = date.getMinutes(); // Returns the minutes (0-59)
    const seconds = date.getSeconds(); // Returns the seconds (0-59)

    return `${day}-${month}-${year}`;

  }

export const getDaysDifferenceInDays = (date1, date2) => {
    // Convert dates to milliseconds
    var date1Ms = date1.getTime();
    var date2Ms = date2.getTime();

    // Check if date1 is later than date2
    if (date1Ms > date2Ms) {
        
        return 0;
    }
    // Calculate the difference in milliseconds
    var differenceMs = Math.abs(date2Ms - date1Ms);

    // Convert milliseconds to days
    var differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

    return differenceDays;
}

export const formatDateTime = (inputDateTime) => {
    // Convert input datetime string to Date object
    const dateTime = new Date(inputDateTime);

    // Define an array of month names
    const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    // Extract day, month, year, hours, minutes, and seconds
    const day = dateTime.getDate();
    const monthIndex = dateTime.getMonth();
    const year = dateTime.getFullYear();
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const seconds = dateTime.getSeconds();

    // Format the datetime
    const formattedDateTime = `${day} ${monthNames[monthIndex]} ${year} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
}