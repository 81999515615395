import axios from 'axios';
import { SERVER_URL } from "../const";

const token = localStorage.getItem('token');
export const getFtaDataService = async (iec_no) => {
  try {
    // Make a GET request to fetch Fta data based on the IEC number
    const response = await axios.get(`${SERVER_URL}/get-fta-details?iec_no=${iec_no}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      console.log(response.data)
    return response.data; // Return the data from the response
  } catch (error) {
    throw new Error('Error fetching Fta data:', error);
  }
};


