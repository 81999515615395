import React from 'react'

import Horizontal from '../layouts/dashboard/horizontal'
import DualHorizontal from '../layouts/dashboard/dual-horizontal'
import DualCompact from '../layouts/dashboard/dual-compact'
import { DefaultRouter } from './default-router'

// pro
import { SimpleRouter } from './simple-router';
import { SimpleAuthRouter } from '../views/modules/auth/router/simple-router';
import { MailRouter } from '../views/modules/mail/router/mail-router';
import { FilemanagerRouter } from '../views/modules/file-manager/router/file-manager-router';

export const IndexRouters = [
    {
        path: 'horizontal',
        element: <Horizontal />
    },
    {
        path: 'dual-horizontal',
        element: <DualHorizontal />
    },
    {
        path: 'dual-compact',
        element: <DualCompact />
    },
    ...DefaultRouter,
    ...FilemanagerRouter,
    ...MailRouter,
    ...SimpleRouter,
    ...SimpleAuthRouter
]