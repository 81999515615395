import React, { Fragment, useEffect, useState } from 'react';
import { getBondReportDataService, getIGSTDataService } from '../../../services/BOEReportService';
import { Table, Button } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { Excel } from 'antd-table-saveas-excel';
import Card from '../../../components/bootstrap/card';
import { getReportSummaryData } from '../../../services/FileDataService';
import { getTodaysDate } from '../../../utilities/utils';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const BondReport = ({ data }) => {
    const iec_no = localStorage.getItem('iec_no');
    const username = localStorage.getItem('name');

    const [jsonData, setJsonData] = useState([]);
    const [isLoading2, setIsLoading2] = useState(false);
    const [summaryDashboardData, setSummaryDashboardData] = useState([]);
    const today = new Date();
    // Extract day, month, and year components
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = today.getFullYear();
  
    // Concatenate components with hyphens
    const formattedDate = `${day}-${month}-${year}`;
  
  // Define columns for the table
  const columns = [
    { title: 'BOND NO.', dataIndex: 'bond_no', key: 'bond_no', header: 'BOND NO.' },
    { title: 'BOND DT.', dataIndex: 'date', key: 'date', header: 'BOND DT.' },
    { title: 'BOND TYPE', dataIndex: 'bond_type', key: 'bond_type', header: 'BOND TYPE' },
    { title: 'BOND VALUE', dataIndex: 'bond_value', key: 'bond_value', header: 'BOND VALUE' },
    { title: 'IEC/BR CODE', dataIndex: 'iec_br', key: 'iec_br', header: 'IEC/BR CODE' },
    { title: 'PORT', dataIndex: 'port', key: 'port', header: 'PORT' },
    { title: 'BOE NO.', dataIndex: 'be_no', key: 'be_no', header: 'BOE NO.' },
    { title: 'BOE DT.', dataIndex: 'be_date', key: 'be_date', header: 'BOE DT.' },
    { title: 'BOND DR. VALUE', dataIndex: 'boe_debt_amt', key: 'boe_debt_amt', header: 'BOND DR. VALUE' }
  ];

  const dashboardReportSummaryColumns = [
    { title: 'BOND NO.', dataIndex: 'bond_no', key: 'bond_no', header: 'BOND NO.' },
    { title: 'BOND DT.', dataIndex: 'date', key: 'date', header: 'BOND DT.' },
    { title: 'BOND TYPE', dataIndex: 'bond_type', key: 'bond_type', header: 'BOND TYPE' },
    { title: 'BOND VALUE', dataIndex: 'bond_value', key: 'bond_value', header: 'BOND VALUE' },
    { title: 'BOND DR. VALUE', dataIndex: 'bond_dr_value', key: 'bond_dr_value', header: 'BOND DR. VALUE' },
    { title: 'BOND BAL', dataIndex: 'bond_bal_amt', key: 'bond_bal_amt', header: 'BOND BAL' },
    { title: 'VALID TILL', dataIndex: 'expiry_date', key: 'expiry_date', header: 'VALID TILL' },
    { title: 'VALID FOR (DAYS)', dataIndex: 'valid_for', key: 'valid_for', header: 'VALID FOR (DAYS)' }
  ];

  const getSummaryDashboard = async () => {
    try{
        setIsLoading2(true)
        let reportTypes = 'BOND-Import';
        const response = await getReportSummaryData(reportTypes);

        if(response){
            console.log("response: ", response.data);
            if(response.data){
                setSummaryDashboardData(response.data);
            }
        }
        else{
            console.log("getSummaryDashboard :: no reponse")
        }
        setIsLoading2(false)
    }
    catch(err){
        setIsLoading2(false)
        console.log(err);
    }  
  }

  function formatDateToDDMMYY(dateString) {
    const date = new Date(dateString);
    console.log(dateString)
    if (isNaN(date.getTime())) {
      return ''; // Return empty string if the dateString is not a valid date
    }
    else {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear()).slice(-2);
      return `${day}/${month}/${year}`;
    }
  }

  const getDateOnly = (dateString) => {
    if(dateString && dateString.length !== 0){
      const date = new Date(dateString);
      return date;
    }
    else{
      return '';
    }
  }

  const exportToExcel = async () => {
    let workbook = new ExcelJS.Workbook();
    let summaryWorksheet = workbook.addWorksheet('Summary');
    let worksheet = workbook.addWorksheet('Data');
    var data = jsonData;

    // Define columns for Excel export
    summaryWorksheet.columns = dashboardReportSummaryColumns;
    // Apply styles to the header row (Row 1)
    summaryWorksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true }; // Bold text
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Yellow background
      };
      cell.alignment = { vertical: 'middle'}; // Center align
    });

    summaryDashboardData.forEach((obj) => {
      summaryWorksheet.addRow({
        ...obj,
        date: getDateOnly(obj.date) 
      })
    })

    worksheet.columns = columns;
    // Apply styles to the header row (Row 1)
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true }; // Bold text
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Yellow background
      };
      cell.alignment = { vertical: 'middle' }; // Center align
    });

    data.forEach((obj) => {
      worksheet.addRow({
        ...obj,
        date: getDateOnly(obj.date),
        sb_date: getDateOnly(obj.sb_date),
        leo_date: getDateOnly(obj.leo_date),
        date_of_realization : getDateOnly(obj.date_of_realization),
        bond_value: obj.bond_value ? parseFloat(obj.bond_value) : '',
        boe_debt_amt: obj.boe_debt_amt ? parseFloat(obj.boe_debt_amt) : '',
      })
      
    });

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    // Use FileSaver to save the file
    saveAs(blob, `Lucrative's CIP DSS - Import Bond Ledger as on ${getTodaysDate()} of ${iec_no}.xlsx`);
  };

  const preprocessData = (data) => {
    return data.map(record => {
      if (record.sb_date) {
        const sbDate = new Date(record.sb_date);
        // Extract fiscal year from sb_date
        const fiscalYearStartYear = sbDate.getMonth() >= 3 ? sbDate.getFullYear() : sbDate.getFullYear() - 1;
        const fiscalYearEndYear = fiscalYearStartYear + 1;
        const fiscalYear = `AM${fiscalYearEndYear.toString().slice(-2)}`;
        record.FY = fiscalYear;
        record.sb_date = formatDateToDDMMYY(sbDate);
      } else {
        record.FY = '';
        record.sb_date = '';
        record.sbDate =  '';

      }

     
      return record;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getBondReportDataService(iec_no);
        // const data = JSON.parse(response);
        console.log(response);
        // Check if data is empty
        // if (Object.keys(data).length === 0 && data.constructor === Object) {
        //   throw new Error('Response data is empty');
        // }
        // console.log('data-',data)
        // const processedData = preprocessData(response.data);
        setJsonData(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    
    getSummaryDashboard();
    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Lucrative's CIP DSS  -  Import Bond Ledger as on  {formattedDate} of {username}</h4>
              </div>
              <div>
                <button
                 className='btn btn-primary'
                  onClick={exportToExcel}
                 
                >
                  Export to Excel
                </button>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <Table
                  className="report-summary-table"
                  width={100}
                  columns={dashboardReportSummaryColumns} 
                  dataSource={summaryDashboardData}
                  pagination={false}
                  loading={isLoading2}
              />
              <hr />
              <div className="table-responsive">
                <Table bordered columns={columns} dataSource={jsonData} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default BondReport;
