import React,{memo,Fragment} from 'react'


// Redux Selector / Action
import { useSelector } from 'react-redux';

// Import selectors & action from setting store
import * as SettingSelector from '../../../../store/setting/selectors'

//React-router
import { Link} from 'react-router-dom'
const Footer = memo(() => {
    const footer = useSelector(SettingSelector.footer)
    return (
        <Fragment>
           <footer className={`footer ${footer}`} style={{
    position: 'fixed',
    marginTop: '77vh'}}>
                <div className="footer-body">
                    <div className="right-panel">
                        <script>document.write(new Date().getFullYear())</script>© LEO TECH EXIM SOLUTIONS LLP, INDIA - Developed By Jash Data Sciences Pvt. Ltd. - 2022
                    </div>
                </div>
            </footer>
        </Fragment>
    )
})

Footer.displayName="Footer"
export default Footer
