import axios from "axios";
import {SERVER_URL} from "../const";

const token = localStorage.getItem('token');
// API calls
// Login 
export async function loginService(userDetails){
    return await axios.post(SERVER_URL + "/login", userDetails);
}




export const AddAdminUserService = async (requestData) => {
  try {
    // Make a POST request to the API endpoint using Axios
    const response = await axios.post(SERVER_URL + "/add-admin", requestData,
    {headers : {'Authorization': 'Bearer ' + token}});

    // Handle the response as needed
    console.log('Response:', response.data);

    return response.data; // You can modify this based on your response structure
  } catch (error) {
    // Handle errors
    console.error('Error:', error);
    throw error; // Rethrow the error if needed
  }
};

export const UpdateUserPageAccessMapping = async (payload) => {
  try {
    // Make a POST request to the API endpoint using Axios
    const response = await axios.post(SERVER_URL + "/user/rbac/update", payload,
    {headers : {'Authorization': 'Bearer ' + token}});

    // Handle the response as needed
    console.log('Response:', response.data);

    return response.data; // You can modify this based on your response structure
  } catch (error) {
    // Handle errors
    console.error('Error:', error);
    // throw error; // Rethrow the error if needed
  }
}

export const resetUserPasswordService = async (id, newPassword, token) => {
    try {
      console.log('token',token)
      const response = await axios.put(SERVER_URL + `/user/reset-password/${id}`, 
        { new_password: newPassword},
        {
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
      });
  
      if (response.status === 200) {
        console.log('Password reset successfully:', response.data);
        return response.data;
      } else {
        
        throw new Error(response.statusText);
      }
    } catch (error) {
    console.error('Error resetting password:', error);
      throw error;
    }
  };

// List users
export async function getUsers(token, skip){
    
    return axios.get(SERVER_URL + "/users?skip=" + skip, {headers : {'Authorization': 'Bearer ' + token}})
    
}
// Get logged in user details
export async function userAccount(token){
    return await axios.get(SERVER_URL + "/user-account", {headers : {'Authorization': 'Bearer ' + token}});
}

// Get user rbac details
export async function userRbac(token){
  return await axios.get(SERVER_URL + "/user/rbac", {headers : {'Authorization': 'Bearer ' + token}});
}

// Get cip pages
export async function getCipPagesService(token){
  return await axios.get(SERVER_URL + "/cip-pages", {headers : {'Authorization': 'Bearer ' + token}});
}

// Get user role
export async function userRole(token){
    
    return await axios.get(SERVER_URL + "/user-role", {headers : {'Authorization': 'Bearer ' + token}});
    
}
// Change password
export async function changePassword(token, changePassword){
    return await axios.post(SERVER_URL + "/users/change-password", {password: changePassword} ,{headers : {'Authorization': 'Bearer ' + token}});
    
}


// Update user details
export async function updateUser(token, updateUserDetails){
    return await axios.put(SERVER_URL + "/update-user", updateUserDetails, {headers : {'Authorization': 'Bearer ' + token}});
    
}

// Delete user
export async function deleteUser(token, delete_user_schema){
    return await axios.delete(SERVER_URL + "/delete-user", {headers: { 'Authorization': 'Bearer ' + token}, data: delete_user_schema});
    
}


// Reset user password
export async function resetPassword(token, updateUserDetails){
    return await axios.put(SERVER_URL + "/reset-password", updateUserDetails, {headers : {'Authorization': 'Bearer ' + token}});
    
}
