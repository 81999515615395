import React, { Fragment, useState, useEffect } from 'react';
import { iecWiseBrcDetailsService } from '../../../services/BrcReportService';
import { Table, Button } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { Excel } from 'antd-table-saveas-excel';
import Card from '../../../components/bootstrap/card';
import { getReportSummaryData } from '../../../services/FileDataService';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import { getTodaysDate } from '../../../utilities/utils';

const BRCReport = () => {
  const [jsonData, setJsonData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [summaryDashboardData, setSummaryDashboardData] = useState([]);
  const iec_no = localStorage.getItem('iec_no');
  const username = localStorage.getItem('name');

  const today = new Date();
  // Extract day, month, and year components
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const year = today.getFullYear();
  // Concatenate components with hyphens
  const formattedDate = `${day}-${month}-${year}`;
  const columnNames = [
    {
      header: 'IEC/BR CODE',
      title: 'IEC/BR CODE',
      dataIndex: 'iec_br',
      key: 'iec_br',
    },
    {
      header: 'PORT',
      title: 'PORT',
      dataIndex: 'port_code',
      key: 'port_code',
    },
    {
      header: 'FY',
      title: 'FY',
      dataIndex: 'fy',
      key: 'fy',
    },
    {
      header: 'SHB NO.',
      title: 'SHB NO.',
      dataIndex: 'sb_no',
      key: 'sb_no',
    },
    {
      header: 'SHB DT.',
      title: 'SHB DT.',
      dataIndex: 'sb_date',
      key: 'sb_date',
      type: 'date'

    },
    {
      header: 'LEO DT.',
      title: 'LEO DT.',
      dataIndex: 'leo_date',
      key: 'leo_date',
      type: 'date'
    },
    {
      header: 'SHB VALUE IN FCC',
      title: 'SHB VALUE IN FCC',
      dataIndex: 'fcc_total',
      key: 'fcc_total',
    },
    {
      header: 'FCC',
      title: 'FCC',
      dataIndex: 'invoice_value_currency',
      key: 'invoice_value_currency',
    },
    {
      header: 'BRC NO',
      title: 'BRC NO',
      dataIndex: 'brc_no',
      key: 'brc_no',
    },
    {
      header: 'DATE OF REALIZATION',
      title: 'DATE OF REALIZATION',
      dataIndex: 'date_of_realization',
      key: 'date_of_realization',
      type: 'date'
    },
    {
      header: 'VALUE',
      title: 'VALUE',
      dataIndex: 'realised_value',
      key: 'realised_value',
    },
    {
      header: 'FCC',
      title: 'FCC',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      header: 'PORT',
      title: 'PORT',
      dataIndex: 'shb_port',
      key: 'shb_port',
    },
    {
      header: 'PORT/FCC DIFF.',
      title: 'PORT/FCC DIFF.',
      dataIndex: 'port_currency_difference',
      key: 'port_currency_difference',
    },
    {
      header: 'VALUE DIFF.',
      title: 'VALUE DIFF.',
      dataIndex: 'value_difference',
      key: 'value_difference',
    },
    {
      header: 'RELEASED IN (DAYS)',
      title: 'RELEASED IN (DAYS)',
      dataIndex: 'payment_released_in_days',
      key: 'payment_released_in_days',
    },
    {
      header: 'OBSERVATION',
      title: 'OBSERVATION',
      dataIndex: 'brc_remark',
      key: 'brc_remark',
    },
  ];

  const dashboardReportSummaryColumns = [
    {
      header: 'FY',
        title: 'FY',
        dataIndex: 'fy',
        key: 'fy',
    },
    {
      header: 'IEC/BR CODE',
      title: 'IEC/BR CODE',
      dataIndex: 'iec_br',
      key: 'iec_br',
    },
    {
      header: 'NO. OF SHB',
        title: 'NO. OF SHB',
        dataIndex: 'remark_count',
        key: 'remark_count',
        align: 'center',
    },
    {
      header: 'FCC TOTAL',
      title: 'FCC TOTAL',
      dataIndex: 'fcc_total_sum',
      key: 'fcc_total_sum',
    },
    {
      header: 'REMARK',
      title: 'REMARK',
      dataIndex: 'brc_remark',
      key: 'brc_remark',
    }
]
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await iecWiseBrcDetailsService(iec_no);
        const data = response;

        setJsonData(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      }
    };

    getSummaryDashboard();
    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once

  const preprocessData = (data) => {
    return data.map(record => {
      if (record.sb_date) {
        const sbDate = new Date(record.sb_date);

        record.sb_date = formatDateToDDMMYY(sbDate);
      }
      // removeUnusedKeys(record)
      return record;
    });
  };

  const getSummaryDashboard = async () => {
    try{
        setIsLoading2(true)
        let reportTypes = 'EDPMS';
        const response = await getReportSummaryData(reportTypes);

        if(response){
            console.log("response: ", response.data);
            if(response.data){
                setSummaryDashboardData(response.data);
            }
        }
        else{
            console.log("getSummaryDashboard :: no reponse")
        }
        setIsLoading2(false)
    }
    catch(err){
        setIsLoading2(false)
        console.log(err);
    }  
}

  const removeUnusedKeys = (data) => {
    // List of keys to keep
    const keysToKeep = [
      "port_code",
      "sb_no",
      "sb_date",
      "iec_br",
      "leo_date",
      "fcc_total",
      "invoice_value_currency",
      "brc_no",
      "realised_value",
      "shb_port",
      "currency",
      "date_of_realization",
      "port_currency_difference",
      "FY",
      "Payment_released_in_no_of_Days",
      "Value_Difference",
      "Remark",
      "total_realised_value"
    ];

    // Iterate over the keys of the data object
    for (const key in data) {
      if (key === "Remark" && Array.isArray(data[key])) {
        data[key] = data[key].join(", ");
      }
      if (!keysToKeep.includes(key)) {
        delete data[key];
      }
    }

    return data;
  }

  function formatDateToDDMMYY(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return ''; // Return empty string if the dateString is not a valid date
    }
    else {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear()).slice(-2);
      return `${day}/${month}/${year}`;
    }

  }

  const getDateOnly = (dateString) => {
    if(dateString && dateString.length !== 0){
      const date = new Date(dateString);
      return date;
    }
    else{
      return '';
    }
  }

  const exportToExcel = async () => {
    let workbook = new ExcelJS.Workbook();
    let summaryWorksheet = workbook.addWorksheet('Summary');
    let worksheet = workbook.addWorksheet('Data');
    var data = jsonData;

    // Define columns for Excel export
    summaryWorksheet.columns = dashboardReportSummaryColumns;
    // Apply styles to the header row (Row 1)
    summaryWorksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true }; // Bold text
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Yellow background
      };
      cell.alignment = { vertical: 'middle'}; // Center align
    });

    summaryDashboardData.forEach((obj) => {
      summaryWorksheet.addRow({
        ...obj,
      })
    })

    worksheet.columns = columnNames;
    // Apply styles to the header row (Row 1)
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true }; // Bold text
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Yellow background
      };
      cell.alignment = { vertical: 'middle' }; // Center align
    });

    data.forEach((obj) => {
      worksheet.addRow({
        ...obj,
        brc_date: getDateOnly(obj.brc_date),
        sb_date: getDateOnly(obj.sb_date),
        leo_date: getDateOnly(obj.leo_date),
        date_of_realization : getDateOnly(obj.date_of_realization)
      })
      
    });

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    // Use FileSaver to save the file
    saveAs(blob, `Lucrative's CIP DSS - EDPMS as on ${getTodaysDate()} of ${iec_no}.xlsx`);
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Lucrative's CIP DSS - EDPMS as on {formattedDate} of {username}</h4>
              </div>
              <div>
                <button
                className='btn btn-primary'
                
                  onClick={exportToExcel}
                >
                  Export to Excel
                </button>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
            <Table
                className="report-summary-table"
                width={100}
                columns={dashboardReportSummaryColumns} 
                dataSource={summaryDashboardData}
                pagination={false}
                loading={isLoading2}
            />
            <hr />
              <div className="table-responsive">
                <Table bordered columns={columnNames} dataSource={jsonData} loading={isLoading}/>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default BRCReport;
