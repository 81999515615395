import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import reportWebVitals from './reportWebVitals';

//router
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Index from "./views/index";
import { LandingModulesRouter } from "./views/modules/landing-pages/router/landing-modules-router";

//store

import { Provider } from "react-redux";
//reducer
import { store } from "./store";
import { IndexRouters } from "./router";
import Header from "./views/modules/landing-pages/components/partials/header/header";
import StartUpLandingPage from "./views/modules/landing-pages/pages/start-up-landing-page";

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <StartUpLandingPage />,
    },
    ...IndexRouters,
    ...LandingModulesRouter,
  ],
  { basename: process.env.PUBLIC_URL }
);
ReactDOM.createRoot(document.getElementById("root")).render(
  // <StrictMode>
    <Provider store={store}>
      <App>
        <RouterProvider router={router}></RouterProvider>
      </App>
    </Provider>
  // </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
