import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Table, Button } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { Excel } from 'antd-table-saveas-excel';
import Card from '../../../components/bootstrap/card';
import { determineRemark } from '../utils/RemarkAllocation';
import { getRodtepImportDataService } from '../../../services/RodtepReportService';
import { getReportSummaryData } from '../../../services/FileDataService';
import { getTodaysDate } from '../../../utilities/utils';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const RodetepScripLedger = ({ data }) => {
    const [jsonData, setJsonData] = useState([]);
    const [isLoading2, setIsLoading2] = useState(false);
    const [summaryDashboardData, setSummaryDashboardData] = useState([]);
    const iec_no = localStorage.getItem('iec_no');
    const username = localStorage.getItem('name');

    const today = new Date();
    // Extract day, month, and year components
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = today.getFullYear();
    const reportType = 'BRC';
    // Concatenate components with hyphens
    const formattedDate = `${day}-${month}-${year}`;
  // Define columns for the table
  const columns = [
    { 
      title: 'RD SCRIP NO.', 
      dataIndex: 'scrip_number', 
      key: 'scrip_number', 
      header: 'RD SCRIP NO.' 
    },
    { 
      title: 'RD SCRIP DT.', 
      dataIndex: 'scrip_issue_date', 
      key: 'scrip_issue_date', 
      header: 'RD SCRIP DT.', 
      render: (text, record) => {
        if (record.scrip_issue_date) {
          return formatDateToDDMMYY(record.scrip_issue_date);
        }
      } 
    },
    { 
      title: 'RD SCRIP AMT.', 
      dataIndex: 'scrip_issue_amount', 
      key: 'scrip_issue_amount', 
      header: 'RD SCRIP AMT.' 
    },
    { 
      title: 'IEC/BR CODE', 
      dataIndex: 'iec_br', 
      key: 'iec_br', 
      header: 'IEC/BR CODE' 
    },
    { 
      title: 'PORT', 
      dataIndex: 'port', 
      key: 'port', 
      header: 'PORT' 
    },
    { 
      title: 'REF. CODE', 
      dataIndex: 'reference_code', 
      key: 'reference_code', 
      header: 'REF. CODE' 
    },
    { 
      title: 'BOE NO.', 
      dataIndex: 'be_no', 
      key: 'be_no', 
      header: 'BOE NO.' 
    },
    { 
      title: 'BOE DT.', 
      dataIndex: 'be_date', 
      key: 'be_date', 
      header: 'BOE DT.', 
      render: (text, record) => {
        if (record.be_date) {
          return formatDateToDDMMYY(record.be_date);
        }
      } 
    },
    { 
      title: 'DUTY DEBITED', 
      dataIndex: 'debit_duty', 
      key: 'debit_duty', 
      header: 'DUTY DEBITED' 
    }
  ];  

  const dashboardReportSummaryColumns = [
    { 
      title: 'RD SCRIP NO.', 
      dataIndex: 'scrip_number', 
      key: 'scrip_number', 
      header: 'RD SCRIP NO.' 
    },
    { 
      title: 'RD SCRIP DT.', 
      dataIndex: 'scrip_issue_date', 
      key: 'scrip_issue_date', 
      header: 'RD SCRIP DT.' 
    },
    { 
      title: 'RD SCRIP AMT.', 
      dataIndex: 'scrip_issue_amount', 
      key: 'scrip_issue_amount', 
      header: 'RD SCRIP AMT.',
      align: 'right'
    },
    { 
      title: 'DUTY DEBITED', 
      dataIndex: 'debit_duty', 
      key: 'debit_duty', 
      header: 'DUTY DEBITED',
      align: 'right'
    },
    { 
      title: 'BALANCE', 
      dataIndex: 'balance_amt', 
      key: 'balance_amt', 
      header: 'BALANCE',
      align: 'right'
    },
    { 
      title: 'VALID TILL', 
      dataIndex: 'scrip_expiry_date', 
      key: 'scrip_expiry_date', 
      header: 'VALID TILL' 
    },
    { 
      title: 'VALID FOR (DAYS)', 
      dataIndex: 'valid_for_days', 
      key: 'valid_for_days', 
      header: 'VALID FOR (DAYS)',
      align: 'right'
    }
  ];  

  const getSummaryDashboard = async () => {
    try{
        setIsLoading2(true)
        let reportTypes = 'RODTEP-ScripLedger';
        const response = await getReportSummaryData(reportTypes);

        if(response){
            console.log("response: ", response.data);
            if(response.data){
                setSummaryDashboardData(response.data);
            }
        }
        else{
            console.log("getSummaryDashboard :: no reponse")
        }
        setIsLoading2(false)
    }
    catch(err){
        setIsLoading2(false)
        console.log(err);
    }  
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRodtepImportDataService(iec_no);

        setJsonData(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    getSummaryDashboard();
    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once

  const preprocessData = (data) => {
    return data.map(record => {
      if (record.sb_date) {
        const sbDate = new Date(record.sb_date);
        // Extract fiscal year from sb_date
        const fiscalYearStartYear = sbDate.getMonth() >= 3 ? sbDate.getFullYear() : sbDate.getFullYear() - 1;
        const fiscalYearEndYear = fiscalYearStartYear + 1;
        const fiscalYear = `AM${fiscalYearEndYear.toString().slice(-2)}`;
        record.FY = fiscalYear;
        // Format sb_date

        record.sb_date = formatDateToDDMMYY(sbDate);
      } else {
        record.FY = '';
        record.sb_date = '';
        record.sbDate = '';
      }
      record.Remark = determineRemark(record,reportType);
      return record;
    });
  };
  
  function formatDateToDDMMYY(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return ''; // Return empty string if the dateString is not a valid date
    }
    else {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear()).slice(-2);
      return `${day}/${month}/${year}`;
    }

  }

  const getDateOnly = (dateString) => {
    if(dateString && dateString.length !== 0){
      const date = new Date(dateString);
      return date;
    }
    else{
      return '';
    }
  }

  const exportToExcel = async () => {
    let workbook = new ExcelJS.Workbook();
    let summaryWorksheet = workbook.addWorksheet('Summary');
    let worksheet = workbook.addWorksheet('Data');
    var data = jsonData;

    // Define columns for Excel export
    summaryWorksheet.columns = dashboardReportSummaryColumns;
    // Apply styles to the header row (Row 1)
    summaryWorksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true }; // Bold text
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Yellow background
      };
      cell.alignment = { vertical: 'middle'}; // Center align
    });

    summaryDashboardData.forEach((obj) => {
      summaryWorksheet.addRow({
        ...obj,
        scrip_issue_date: getDateOnly(obj.scrip_issue_date),
        scrip_expiry_date: getDateOnly(obj.scrip_expiry_date),
      })
    })

    worksheet.columns = columns;
    // Apply styles to the header row (Row 1)
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true }; // Bold text
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Yellow background
      };
      cell.alignment = { vertical: 'middle' }; // Center align
    });

    data.forEach((obj) => {
      worksheet.addRow({
        ...obj,
        scrip_issue_date: getDateOnly(obj.scrip_issue_date),
        be_date: getDateOnly(obj.be_date),
        debit_duty: obj.debit_duty ? parseFloat(obj.debit_duty) : ''
      })
      
    });

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    // Use FileSaver to save the file
    saveAs(blob, `Lucrative's CIP DSS - RoDTEP Scrip Ledger as on ${getTodaysDate()} of ${iec_no}.xlsx`);
  };

  return (
    <Fragment>
    <Row>
      <Col sm="12">
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">Lucrative's CIP DSS  -  RoDTEP Scrip Ledger as on {formattedDate} of {username}</h4>
            </div>
            <div>
              <button
               className='btn btn-primary'
                onClick={exportToExcel}
              >
                Export to Excel
              </button>
            </div>
          </Card.Header>
          <Card.Body className="px-0">
            <Table
                className="report-summary-table"
                width={100}
                columns={dashboardReportSummaryColumns} 
                dataSource={summaryDashboardData}
                pagination={false}
                loading={isLoading2}
            />
            <hr />
            <div className="table-responsive">
              <Table bordered columns={columns} dataSource={jsonData} />
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Fragment>
  );
};

export default RodetepScripLedger;
