import React, { memo, Fragment, useState, useEffect } from 'react';

// react-bootstrap
import { Row, Col, Form, Button } from 'react-bootstrap';
import { message, TreeSelect, Select } from 'antd';
// components
import Card from '../../../components/bootstrap/card';

import { getCipPagesService } from '../../../services/UserService';

// router
import { Link } from 'react-router-dom';
import { AddAdminUserService } from '../../../services/UserService';

const AdminAdd = memo(() => {
   const { SHOW_PARENT } = TreeSelect;
   const [addUserData, setUserData] = useState({});
   const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      iec_no: '',
      mobile: '',
      email: '',
      password: '',
   });
   const [isFormValid, setIsFormValid] = useState(false);
   const [messageApi, contextHolder] = message.useMessage();
   const [isUserCreated, setIsUserCreated] = useState(false);
   const [cipPages, setCipPages] = useState([]);
   const [cipUploadPages, setCipUploadPages] = useState([]);
   const [selectedOptions, setSelectedOptions] = useState(['0']);
   const [selectedUploadOptions, setSelectedUploadOptions] = useState(['0']);

   const [userDetails, setUserDetails] = useState({
      role: localStorage.getItem('role'),
      iec: localStorage.getItem('iec_no'),
      name: localStorage.getItem('name'),
   });

   const errorFunction = (content) => {
      messageApi.open({
         type: 'error',
         content: content,
      });
   };

   const handleChange = (value) => {
      // console.log(value);
      setSelectedOptions(value);
   };

   const handleCipUploadChange = (value) => {
      setSelectedUploadOptions(value);
   }

   const successFunction = (content) => {
      messageApi.open({
         type: 'success',
         content: content,
      });
   };

   const getCipPages = async () => {
      try{
         const token = localStorage.getItem('token');
         getCipPagesService(token).then(response => {
            // console.log("response = ", response.data.data)

            if (response.data.data){

               let selectOptions = [];
               selectOptions.push({
                    label: 'All Reports',
                    value: '0',
                    key: '0'
               })

               let selectUploadOptions = [];
               selectUploadOptions.push({
                    label: 'None',
                    value: '0',
                    key: '0'
               })

               for (let i = 0; i < response.data.data.length; i++){
                  const item = response.data.data[i];
                  if(item.page_type == 'REPORT'){
                     const obj = {
                        label: item.page_name,
                        value: item.page_id,
                        key: '0-' + i
                     }
                     selectOptions.push(obj)
                  }
                  
                  if(item.page_type == 'UPLOAD'){
                     const obj = {
                        label: item.page_name,
                        value: item.page_id,
                        key: '0-' + i
                     }
                     selectUploadOptions.push(obj);
                  }
               }
               setCipPages(selectOptions);
               setCipUploadPages(selectUploadOptions);
               
            }
            else{
               setCipPages([])
            }
            
            
         }, error => {
            console.log("error while getting response = ", error)
         })
      }
      catch(error){
         console.log("error")
      }
   }

   const handleInputChange = (e) => {
      const { id, value } = e.target;
      setFormData((prevData) => ({
         ...prevData,
         [id]: value,
      }));
      checkFormValid();
   
   };

   const checkFormValid = () => {
         const { firstName, lastName, iec_no, mobile, email, password } = formData;
         if (
            firstName &&
            lastName &&
            (userDetails.role !== 'superadmin' || iec_no) &&
            mobile &&
            email &&
            password &&
            (userDetails.role !== 'superadmin' || selectedOptions)
         ) {
            setIsFormValid(true);
         } else {
            setIsFormValid(false);
         }
   };

   useEffect(() => {
      getCipPages();
   }, [userDetails.role]);

   const handleAddUser = async () => {
      try {
         console.log('formData-', formData);

         if (userDetails.role == 'superadmin' && (selectedOptions.length == 0 || selectedUploadOptions.length == 0)){
            console.log("Empty access control report list")
            return;
         }
         if (userDetails.role == 'superadmin' && formData.iec_no.length == 0){
            console.log("Empty access control report list")
            return;
         }

         let finalPageIds = selectedOptions;
         if (selectedOptions.includes('0')){
            const values = cipPages.filter(page => page.value != '0') // Filter out objects where value is '0'
                                    .map(page => page.value); // Extract the 'value' key
            
            finalPageIds = values;
            console.log("values", values);
            setSelectedOptions(values);
         }

         let finalUploadPageIds = selectedUploadOptions;
         if (selectedUploadOptions.includes('0')){
            const values = [] // Extract the 'value' key
            
            finalUploadPageIds = values;
            console.log("values", values);
            setSelectedUploadOptions(finalUploadPageIds);
         }

         // console.log("finalPageIds: ", finalPageIds)
         // console.log("finalUploadPageIds: ", finalUploadPageIds)

         let mergedPageIds = finalPageIds.concat(finalUploadPageIds);
         console.log("mergedPageIds: ", mergedPageIds)

         const create_data = {
            fullname: formData.firstName + ' ' + formData.lastName,
            email: formData.email,
            iec_no: formData.iec_no,
            mobile: formData.mobile,
            password: formData.password,
            page_ids: mergedPageIds
         };
         setUserData(create_data);
         const response = await AddAdminUserService(create_data);
         const data = await response;
         if (data) {
            if(data.status != 200){
               setIsUserCreated(false);
               errorFunction(data.msg);
            }
            else{
               setIsUserCreated(true);
               successFunction(data.msg);
            }
            
         }
         console.log(data);
      } catch (error) {
         console.error('Error adding new user:', error);
         errorFunction('User is not created');
      }
   };

   return (
      <Fragment>
         {contextHolder}
         <Row>
            <Col xl="2" lg="4" className=""></Col>
            {isUserCreated ? (
               <Col xl="8" lg="8" className="">
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">User Created Successfully</h4>
                        </div>
                     </Card.Header>
                     <Card.Body>
                        <div className="mt-2">
                           <h6 className="mb-1">Name:</h6>
                           <p>{addUserData && addUserData.fullname}</p>
                        </div>
                        <div className="mt-2">
                           <h6 className="mb-1">Email:</h6>
                           <p>
                              <Link to="#" className="text-body">
                                 {addUserData && addUserData.email}
                              </Link>
                           </p>
                        </div>
                        <div className="mt-2">
                           <h6 className="mb-1">Mobile:</h6>
                           <p>
                              <Link to="#" className="text-body">
                                 {addUserData && addUserData.mobile}
                              </Link>
                           </p>
                        </div>
                        <div className="mt-2">
                           <h6 className="mb-1">IEC:</h6>
                           <p>
                              <Link to="#" className="text-body">
                                 {addUserData && addUserData.iec_no}
                              </Link>
                           </p>
                        </div>
                        <div className="mt-2">
                           <h6 className="mb-1">Password:</h6>
                           <p>
                              <Link to="#" className="text-body">
                                 {addUserData && addUserData.password}
                              </Link>
                           </p>
                        </div>
                        <div className="mt-2">
                           <h6 style={{ float: 'right' }} className="mb-1">
                              <Link onClick={() => setIsUserCreated(false)} to="#" className="text-body">
                                 Go Back
                              </Link>
                           </h6>
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
            ) : (
               <Col xl="8" lg="8">
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">New User Information</h4>
                        </div>
                     </Card.Header>
                     <Card.Body>
                        <div className="new-user-info">
                           <Form>
                              <div className="row">
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="firstName">Client Name:</Form.Label>
                                    <Form.Control type="text" id="firstName" placeholder="Client Name" onChange={handleInputChange} />
                                 </Form.Group>
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="lastName">Alias:</Form.Label>
                                    <Form.Control type="text" id="lastName" placeholder="Alias" onChange={handleInputChange} />
                                 </Form.Group>
                                 {userDetails.role === 'superadmin' ? (
                                    <Form.Group className="col-md-6 form-group">
                                       <Form.Label htmlFor="iec_no">IEC:</Form.Label>
                                       <Form.Control type="text" id="iec_no" placeholder="IEC" onChange={handleInputChange} />
                                    </Form.Group>
                                 ) : (
                                    <Form.Group className="col-md-6 form-group">
                                       <Form.Label htmlFor="iec_no">IEC:</Form.Label>
                                       <Form.Control type="text" id="iec_no" value={userDetails.iec} placeholder="IEC" disabled />
                                    </Form.Group>
                                 )}
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="mobile">Mobile Number:</Form.Label>
                                    <Form.Control type="text" id="mobile" placeholder="Mobile Number" onChange={handleInputChange} />
                                 </Form.Group>
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="email">Email:</Form.Label>
                                    <Form.Control type="email" id="email" placeholder="Email" onChange={handleInputChange} />
                                 </Form.Group>
                                 { userDetails.role === 'superadmin' && (
                                    
                                    <Form.Group className="col-md-6 form-group">
                                       <Form.Label htmlFor="email">Select Report Access:</Form.Label>
                                       {/* <TreeSelect {...tProps} /> */}
                                       <Select
                                          mode="multiple"
                                          allowClear
                                          style={{
                                          width: '100%',
                                          }}
                                          placeholder="Please select"
                                          defaultValue={['0']}
                                          onChange={handleChange}
                                          maxTagCount='responsive'
                                          options={cipPages}
                                       />
                                    </Form.Group>             
                                 )
                                 }
                                 { userDetails.role === 'superadmin' && (
                                    
                                    <Form.Group className="col-md-6 form-group">
                                       <Form.Label htmlFor="email">Select Upload Access:</Form.Label>
                                       {/* <TreeSelect {...tProps} /> */}
                                       <Select
                                          mode="multiple"
                                          allowClear
                                          style={{
                                          width: '100%',
                                          }}
                                          placeholder="Please select uploads"
                                          defaultValue={['0']}
                                          onChange={handleCipUploadChange}
                                          maxTagCount='responsive'
                                          options={cipUploadPages}
                                       />
                                    </Form.Group>             
                                 )
                                 }
                                 
                              </div>
                              <hr />
                              <h5 className="mb-3">Security</h5>
                              <div className="row">
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="password">Password:</Form.Label>
                                    <Form.Control type="password" id="password" placeholder="Password" onChange={handleInputChange} />
                                 </Form.Group>
                              </div>
                              <Button style={{float:"center"}} type="button" variant="btn btn-primary" onClick={handleAddUser} disabled={!isFormValid}>
                                 {userDetails.role === 'superadmin' ? 'Add New Admin' : 'Add New User'}
                              </Button>
                           </Form>
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
            )}
         </Row>
      </Fragment>
   );
});

AdminAdd.displayName = 'AdminAdd';
export default AdminAdd;
