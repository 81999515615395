import React, { Fragment, useEffect, useState } from 'react';
import { getDrawbackDetailsService } from '../../../services/DrawbackReportService';
import { getReportSummaryData } from '../../../services/FileDataService';
import { Table, Button } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { Excel } from 'antd-table-saveas-excel';
import Card from '../../../components/bootstrap/card';
import { determineRemark } from '../utils/RemarkAllocation';
import { getTodaysDate } from '../../../utilities/utils';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const DrawbackReport = () => {
  const [jsonData, setJsonData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [summaryDashboardData, setSummaryDashboardData] = useState([]);
  const iec_no = localStorage.getItem('iec_no');
  const username = localStorage.getItem('name');

  const today = new Date();
  // Extract day, month, and year components
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const year = today.getFullYear();

  // Concatenate components with hyphens
  const formattedDate = `${day}-${month}-${year}`;

  useEffect(() => {
    getSummaryDashboard();
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getDrawbackDetailsService(iec_no);
     
      setJsonData(response);
      setIsLoading(false);

    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching drawback report data:', error);
    }
  };

  const columnNames = [
    {
      title: 'IEC_BR',
      dataIndex: 'iec_br',
      key: 'iec_br',
      header: 'IEC_BR',
    },
    {
      title: 'PORT CODE',
      dataIndex: 'port_code',
      key: 'port_code',
      header: 'PORT CODE',
    },
    {
      title: 'FY',
      dataIndex: 'fy',
      key: 'fy',
      header: 'FY',
    },
    {
      title: 'SHB NO.',
      dataIndex: 'sb_no',
      key: 'sb_no',
      header: 'SHB NO.',
    },
    {
      title: 'SHB DT.',
      dataIndex: 'sb_date',
      key: 'sb_date',
      header: 'SHB DT.',
    },
    {
      title: 'LEO DT.',
      dataIndex: 'leo_date',
      key: 'leo_date',
      header: 'LEO DT.',
      render: (text, record) => {
        return formatDateToDDMMYY(record.leo_date);
      }
    },
    {
      title: 'DBK AIR AMT. (INR)',
      dataIndex: 'dbk_claim',
      key: 'dbk_claim',
      header: 'DBK AIR AMT. (INR)',
    },
    {
      title: 'SCROLL NO. / QUERY',
      dataIndex: 'scroll_number',
      key: 'scroll_number',
      header: 'SCROLL NO. / QUERY',
    },
    {
      title: 'SCROLL DT.',
      dataIndex: 'scroll_date',
      key: 'scroll_date',
      header: 'SCROLL DT.',
      render: (text, record) => {
        if(record.scroll_date){
          return formatDateToDDMMYY(record.scroll_date);
        }
      }
    },
    {
      title: 'SCROLL AMT.',
      dataIndex: 'scroll_amount',
      key: 'scroll_amount',
      header: 'SCROLL AMT.',
    },
    {
      title: 'BRC NO',
      dataIndex: 'brc_no',
      key: 'brc_no',
      header: 'BRC NO',
    },
    {
      title: 'DATE OF REALIZATION',
      dataIndex: 'date_of_realization',
      key: 'date_of_realization',
      header: 'DATE OF REALIZATION',
      render: (text, record) => {
        if(record.date_of_realization){
          return formatDateToDDMMYY(record.date_of_realization);
        }
      }
    },
    {
      title: 'PORT/FCC DIFF.',
      dataIndex: 'port_currency_difference',
      key: 'port_currency_difference',
      header: 'PORT/FCC DIFF.',
    },
    {
      title: 'VALUE DIFF.',
      dataIndex: 'value_difference',
      key: 'value_difference',
      header: 'VALUE DIFF.'
    },
    {
      title: 'FCC RELEASED IN (DAYS)',
      dataIndex: 'payment_released_in_days',
      key: 'payment_released_in_days',
      header: 'FCC RELEASED IN (DAYS)',
    },
    {
      title: `DBK TOBE SURRENDER (${formatDateToDDMMYY(new Date())})`,
      dataIndex: 'liability',
      key: 'liability',
      header: `DBK TOBE SURRENDER (${formatDateToDDMMYY(new Date())})`,
    },
    {
      title: 'BRC REMARK',
      dataIndex: 'brc_remark',
      key: 'brc_remark',
      header: 'BRC REMARK',
    },
    {
      title: 'DBK REMARK',
      dataIndex: 'dbk_remark',
      key: 'dbk_remark',
      header: 'DBK REMARK',
    },
    {
      title: 'SCROLL TAT',
      dataIndex: 'scroll_tat',
      key: 'scroll_tat',
      header: 'SCROLL TAT',
    },
  ];
  
  const dashboardReportSummaryColumns = [
    {
      title: 'FY',
      dataIndex: 'fy',
      key: 'fy',
      header: 'FY',
    },
    {
      title: 'IEC/BR CODE',
      dataIndex: 'iec_br',
      key: 'iec_br',
      header: 'IEC/BR CODE',
    },
    {
      title: 'PORT CODE',
      dataIndex: 'port_code',
      key: 'port_code',
      header: 'PORT CODE',
    },
    {
      title: 'DBK AIR AMT. (INR)',
      dataIndex: 'dbk_air_amt',
      key: 'dbk_air_amt',
      header: 'DBK AIR AMT. (INR)',
      align: 'right'
    },
    {
      title: 'DBK TOBE SURRENDER',
      dataIndex: 'total_liability',
      key: 'total_liability',
      header: 'DBK TOBE SURRENDER',
      align: 'right'
    },
    {
      title: 'DBK REMARK',
      dataIndex: 'dbk_remark',
      key: 'dbk_remark',
      header: 'DBK REMARK',
    },
  ];
  

  const getSummaryDashboard = async () => {
    try{
        setIsLoading2(true)
        let reportType = 'DBK-AIR'
        const response = await getReportSummaryData(reportType);

        if(response){
            console.log("response: ", response.data);
            if(response.data){
                setSummaryDashboardData(response.data);
            }
        }
        else{
            console.log("getSummaryDashboard :: no reponse")
        }
        setIsLoading2(false)
    }
    catch(err){
        setIsLoading2(false)
        console.log(err);
    }  
  }

  const preprocessData = (data) => {
    return data.map(record => {


      if (record.sb_date) {
        const sbDate = new Date(record.sb_date);
        record.sb_date = formatDateToDDMMYY(sbDate);
      } 

      return record;
    });
  };


  function formatDateToDDMMYY(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return ''; // Return empty string if the dateString is not a valid date
    }
    else {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear()).slice(-2);
      return `${day}/${month}/${year}`;
    }
  }

  const getDateOnly = (dateString) => {
    if(dateString && dateString.length !== 0){
      const date = new Date(dateString);
      return date;
    }
    else{
      return '';
    }
  }

  const exportToExcel = async () => {
    let workbook = new ExcelJS.Workbook();
    let summaryWorksheet = workbook.addWorksheet('Summary');
    let worksheet = workbook.addWorksheet('Data');
    var data = jsonData;

    // Define columns for Excel export
    summaryWorksheet.columns = dashboardReportSummaryColumns;
    // Apply styles to the header row (Row 1)
    summaryWorksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true }; // Bold text
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Yellow background
      };
      cell.alignment = { vertical: 'middle'}; // Center align
    });

    summaryDashboardData.forEach((obj) => {
      summaryWorksheet.addRow({
        ...obj,
      })
    })

    worksheet.columns = columnNames;
    // Apply styles to the header row (Row 1)
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true }; // Bold text
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Yellow background
      };
      cell.alignment = { vertical: 'middle' }; // Center align
    });

    data.forEach((obj) => {
      worksheet.addRow({
        ...obj,
        scroll_date: getDateOnly(obj.scroll_date),
        sb_date: getDateOnly(obj.sb_date),
        leo_date: getDateOnly(obj.leo_date),
        date_of_realization : getDateOnly(obj.date_of_realization),
        dbk_claim : obj.dbk_claim ? parseFloat(obj.dbk_claim) : ''
      })
      
    });

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    // Use FileSaver to save the file
    saveAs(blob, `Lucrative's CIP DSS - DBK AIR as on ${getTodaysDate()} of ${iec_no}.xlsx`);
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Lucrative's CIP DSS  - AIR DBK as on  {formattedDate} of {username}</h4>
              </div>
              <div>
                <button
                  className='btn btn-primary'
                  onClick={exportToExcel}
                 
                >
                  Export to Excel
                </button>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
            <div className="table-responsive">
              <Table
                  className="report-summary-table"
                  columns={dashboardReportSummaryColumns} 
                  dataSource={summaryDashboardData}
                  pagination={false}
                  loading={isLoading2}
              />
            </div>
            <hr />
              <div className="table-responsive">
                <Table bordered columns={columnNames} dataSource={jsonData} loading={isLoading} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DrawbackReport;
