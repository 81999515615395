import React,{useState,useEffect,memo,Fragment} from 'react'

//react-bootstrap
import {Row,Col,Image,Form,Nav,Dropdown,Tab} from 'react-bootstrap'

//components
import Card from '../../../components/bootstrap/card'
import {userAccount} from "../../../services/UserService";
//router
import {Link} from 'react-router-dom'


// img
import avatars11 from '../../../assets/images/avatars/01.png'
import avatars22 from '../../../assets/images/avatars/avtar_1.png'
import avatars33 from '../../../assets/images/avatars/avtar_2.png'
import avatars44 from '../../../assets/images/avatars/avtar_3.png'
import avatars55 from '../../../assets/images/avatars/avtar_4.png'
import avatars66 from '../../../assets/images/avatars/avtar_5.png'
import avatars2 from '../../../assets/images/avatars/02.png'
import avatars3 from '../../../assets/images/avatars/03.png'
import avatars4 from '../../../assets/images/avatars/04.png'
import avatars5 from '../../../assets/images/avatars/05.png'


import icon1 from '../../../assets/images/icons/01.png'
import icon2 from '../../../assets/images/icons/02.png'
import icon3 from '../../../assets/images/icons/03.png'
import icon4 from '../../../assets/images/icons/04.png'
import icon5 from '../../../assets/images/icons/05.png'
import icon6 from '../../../assets/images/icons/06.png'
import icon7 from '../../../assets/images/icons/07.png'

import icon8 from '../../../assets/images/icons/08.png'
import shap2 from '../../../assets/images/shapes/02.png'
import shap4 from '../../../assets/images/shapes/04.png'
import shap6 from '../../../assets/images/shapes/06.png'
import pages2 from '../../../assets/images/pages/02-page.png'

import ShareOffcanvas from '../../../components/partials/components/shareoffcanvas'

// FsLightbox
import ReactFsLightbox from 'fslightbox-react';
const FsLightbox = ReactFsLightbox.default ? ReactFsLightbox.default : ReactFsLightbox;

const UserProfile = memo(() =>{
   const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  });
  const [userDetails, setUserDetails] = useState({
   role: localStorage.getItem('role'),
   iec: localStorage.getItem('iec_no'),
   name: localStorage.getItem('name')
   })

   const [userAccountDetails, setUserAccountDetails] = useState({});

  function imageOnSlide(number) {
    setImageController({
      toggler: !imageController.toggler,
      slide: number,
    });
  }

   const getUserAccount = async () => {
      const token = localStorage.getItem('token');

      try{
         const response = await userAccount(token)
         if(response){
            if(response.data){
               setUserAccountDetails(response.data.data);
            }
         }
      }
      catch(err){
         console.log("Error: " + err);
      }

      
   }

   useEffect(() => {
      getUserAccount();
   }, [])

   return(
         <Fragment>
            <FsLightbox
                     toggler={imageController.toggler}
                     sources={ [icon4,shap2,icon8,shap4,icon2,shap6,icon5,shap4,icon1] }
                     slide={imageController.slide}
                  />
            <Tab.Container  defaultActiveKey="first">
               <Row>
                  <Col lg="12">
                     <Card>
                           <Card.Body>
                              <div className="d-flex flex-wrap align-items-center justify-content-between">
                                 <div className="d-flex flex-wrap align-items-center">
                                    <div className="profile-img position-relative me-3 mb-3 mb-lg-0 profile-logo profile-logo1">
                                       <Image className="theme-color-default-img  img-fluid rounded-pill avatar-100" src={avatars11} alt="profile-pic"/>
                                       <Image className="theme-color-purple-img img-fluid rounded-pill avatar-100" src={avatars22} alt="profile-pic"/>
                                       <Image className="theme-color-blue-img img-fluid rounded-pill avatar-100" src={avatars33} alt="profile-pic"/>
                                       <Image className="theme-color-green-img img-fluid rounded-pill avatar-100" src={avatars55} alt="profile-pic"/>
                                       <Image className="theme-color-yellow-img img-fluid rounded-pill avatar-100" src={avatars66} alt="profile-pic"/>
                                       <Image className="theme-color-pink-img img-fluid rounded-pill avatar-100" src={avatars44} alt="profile-pic"/>
                                    </div>
                                    <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                       <h4 className="me-2 h4">{userDetails.name}</h4>
                                       <span> - {userDetails.iec}</span>
                                    </div>
                                 </div>
                                 <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab" data-toggle="slider-tab" id="profile-pills-tab" role="tablist">
                                    {/* <Nav.Item as="li">
                                       <Nav.Link eventKey="first">Feed</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li">
                                       <Nav.Link eventKey="second">Activity</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li">
                                       <Nav.Link eventKey="third">Friends</Nav.Link>
                                    </Nav.Item> */}
                                    <Nav.Item as="li">
                                       <Nav.Link eventKey="first">Profile</Nav.Link>
                                    </Nav.Item>
                                 </Nav>
                              </div>
                           </Card.Body>
                     </Card>
                  </Col>
                  
                  <Col lg="9">
                     <Tab.Content className="profile-content">
                        <Tab.Pane eventKey="first" id="profile-profile">
                           
                           <Card>
                              <Card.Header>
                                 <div className="header-title">
                                    <h4 className="card-title">About User</h4>
                                 </div>
                              </Card.Header>
                              <Card.Body>
                                 
                                 <div className="mt-2">
                                 <h6 className="mb-1">Joined:</h6>
                                    <p>{userAccountDetails.created_at}</p>
                                 </div>
                                 
                                 <div className="mt-2">
                                    <h6 className="mb-1">Email:</h6>
                                    <p><Link to="#" className="text-body"> {userAccountDetails.email}</Link></p>
                                 </div>
                                 
                                 <div className="mt-2">
                                    <h6 className="mb-1">Contact:</h6>
                                    <p><Link to="#" className="text-body">{userAccountDetails.mobile}</Link></p>
                                 </div>
                                 <div className="mt-2">
                                    <h6 className="mb-1">Password:</h6>
                                    <p><Link to="#" className="text-body">**********</Link></p>
                                 </div>
                              </Card.Body>
                           </Card>
                        </Tab.Pane >
                     </Tab.Content>
                  </Col>
                  <Col lg="3">
                     <Card>
                        <Card.Header>
                           <div className="header-title">
                              <h4 className="card-title">About</h4>
                           </div>
                        </Card.Header>
                        <Card.Body>
                           
                           <div className="mb-1">Email: <Link to="#" className="ms-3">{userAccountDetails.email}</Link></div>
                           <div className="mb-1">Phone: <Link to="#" className="ms-3">{userAccountDetails.mobile}</Link></div>
                           <div className="mb-1">IEC No: <Link to="#" className="ms-3">{userAccountDetails.iec_no}</Link></div>
                           <div>Location: <span className="ms-3">India</span></div>
                           {/* <div>Joined: &nbsp;&nbsp;&nbsp;<span className="ms-3">{userAccountDetails.created_at && userAccountDetails.created_at.split('T')[0]}</span></div> */}
                        </Card.Body>
                     </Card>
                     
                  </Col>
               </Row>
            </Tab.Container>
         </Fragment>
   )
}
)

UserProfile.displayName="UserProfile"
export default UserProfile;