// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small-checkbox .ant-checkbox-inner {
    width: 12px;
    height: 12px;
  }
  
  .small-checkbox .ant-checkbox-inner::after {
    width: 10px;
    height: 10px;
  }
  
  .small-checkbox .ant-checkbox-wrapper {
    font-size: 14px; /* Adjust font size if needed */
  }


  .access-control-table .ant-table-cell {
    padding: 3px !important;
  }

  .access-control-table p {
    margin: 0 !important;
  }

  .access-control-table .ant-table-thead > tr > th {
    font-size: 14px; /* Adjust the font size as needed */
    padding: 12px;    /* Adjust the padding if necessary */
  }

  .access-control-modal .card{
    margin-bottom: 0 !important;
  }

  .access-control-modal .modal-dialog {
        max-width: 70% !important;
  }`, "",{"version":3,"sources":["webpack://./src/views/dashboard/app/add-admin.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;EACd;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,eAAe,EAAE,+BAA+B;EAClD;;;EAGA;IACE,uBAAuB;EACzB;;EAEA;IACE,oBAAoB;EACtB;;EAEA;IACE,eAAe,EAAE,mCAAmC;IACpD,aAAa,KAAK,oCAAoC;EACxD;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;QACM,yBAAyB;EAC/B","sourcesContent":[".small-checkbox .ant-checkbox-inner {\n    width: 12px;\n    height: 12px;\n  }\n  \n  .small-checkbox .ant-checkbox-inner::after {\n    width: 10px;\n    height: 10px;\n  }\n  \n  .small-checkbox .ant-checkbox-wrapper {\n    font-size: 14px; /* Adjust font size if needed */\n  }\n\n\n  .access-control-table .ant-table-cell {\n    padding: 3px !important;\n  }\n\n  .access-control-table p {\n    margin: 0 !important;\n  }\n\n  .access-control-table .ant-table-thead > tr > th {\n    font-size: 14px; /* Adjust the font size as needed */\n    padding: 12px;    /* Adjust the padding if necessary */\n  }\n\n  .access-control-modal .card{\n    margin-bottom: 0 !important;\n  }\n\n  .access-control-modal .modal-dialog {\n        max-width: 70% !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
