import React from 'react'

// default layout
import Default from '../layouts/dashboard/default';

// Dashboard
import Index from '../views/dashboard/index'
import AlternateDashboard from '../views/dashboard/alternate-dashboard'

// icon pages
import Solid from '../views/dashboard/icons/solid';
import Outline from '../views/dashboard/icons/outline';
import DualTone from '../views/dashboard/icons/dual-tone';

// special pages
import Calender from '../views/dashboard/special-pages/calender';

// user pages
import Userprofile from '../views/dashboard/app/user-profile';

// extra
import Admin from '../views/dashboard/admin/admin';

// widget
import Widgetbasic from '../views/dashboard/widget/widgetbasic';
import Widgetchart from '../views/dashboard/widget/widgetchart';
import Widgetcard from '../views/dashboard/widget/widgetcard';


// form
import FormElement from '../views/dashboard/from/form-element';
import FormWizard from '../views/dashboard/from/form-wizard';
import FormValidation from '../views/dashboard/from/form-validation';

// table
import BootstrapTable from '../views/dashboard/table/bootstrap-table';
import TableData from '../views/dashboard/table/table-data';
import Borderedtable from '../views/dashboard/table/border-table';
import Fancytable from '../views/dashboard/table/fancy-table';
import FixedTable from '../views/dashboard/table/fixed-table';

//extra 
import PrivacyPolicy from '../views/dashboard/extra/privacy-policy'
import TermsofService from '../views/dashboard/extra/terms-of-service'
import BlankPage from '../views/dashboard/extra/blank-page'
import ShimmerComponent from '../views/dashboard/extra/ShimmerComponent'
import AdminAdd from '../views/dashboard/app/admin-add';
import AdminList from '../views/dashboard/app/admin-list';
import UploadFiles from '../views/dashboard/upload/upload-files';

//reports

import BRCReport from '../views/dashboard/reports/BRCReport';
import IGSTReport from '../views/dashboard/reports/IGSTReport';
import DrawbackReport from '../views/dashboard/reports/DrawbackReport';
import ClearanceTimeImportReport from '../views/dashboard/reports/ClearanceTimeImportReport';
import ClearanceTimeExportReport from '../views/dashboard/reports/ClearanceTimeExportReport';
import RodtepReport from '../views/dashboard/reports/RodtepReport';
import FtaReport from '../views/dashboard/reports/FTAReport';
import BondReport from '../views/dashboard/reports/BondReport';
import AdvAuthDetailsReport from '../views/dashboard/reports/AdvanceAuthReport';
import AdvAuthDetailsBOEReport from '../views/dashboard/reports/AdvanceAuthLedgerReport';
import EPCGReportComponent from '../views/dashboard/reports/EPCGReport';
import RodetepScripLedger from '../views/dashboard/reports/RodtepScripLedgerReport';
import AdvLicImportExportReport from '../views/dashboard/reports/AdvLicImportExportReport';
import CIPOverview from '../views/frontpage-details/CIPOverview';
import KeyFeatures from '../views/frontpage-details/KeyFeatures';
import ContactUs from '../views/frontpage-details/ContactUs';
import Clients from '../views/frontpage-details/OurClients';
import OtherServices from '../views/frontpage-details/OtherServices';
export const DefaultRouter = [
    {
        path: "/",
        element: <Default />,
        children:[
            {
            path: "dashboard",
            element: <Index />
            },
            
            {
                path: "/dashboard/alternate-dashboard",
                element: <AlternateDashboard />
            },
            // Special Pages
          {
            path:"/frontpage-details/CIPOverview",
            element:<CIPOverview/>
          },
          {
            path:"/frontpage-details/KeyFeatures",
            element:<KeyFeatures/>
          },
          {
            path:"/frontpage-details/ContactUs",
            element:<ContactUs/>
          },
          {
            path:"/frontpage-details/OurClients",
            element:<Clients/>
          },
          {
            path:"/frontpage-details/OtherServices",
            element:<OtherServices/>
          },
            {
                path: 'dashboard/special-pages/calender',
                element:<Calender/>
            },
         
            
            //User 
            {
                path: 'dashboard/app/user-profile',
                element:<Userprofile/>
            },
            {
                path:'dashboard/app/user-add',
                element:<AdminAdd/>
            },
       
            {
                path: 'dashboard/reports/brc-report',
                element:<BRCReport/>
            },
            {
                path: 'dashboard/reports/advauth-export-report',
                element:<AdvAuthDetailsReport/>
            },
            {
                path: 'dashboard/reports/advauth-import-report',
                element:<AdvAuthDetailsBOEReport/>
            },
            {
                path: 'dashboard/reports/advauth-excess-import-report',
                element:<AdvLicImportExportReport/>
            },
            {
                path: 'dashboard/reports/bond-report',
                element:<BondReport/>
            },
            {
                path: 'dashboard/reports/drawback-report',
                element:<DrawbackReport/>
            },
            {
                path: 'dashboard/reports/epcg-report',
                element:<EPCGReportComponent/>
            },
            {
                path: 'dashboard/reports/rodtep-export-report',
                element:<RodtepReport/>
            },
            {
                path: 'dashboard/reports/rodtep-import-report',
                element:<RodetepScripLedger/>
            },
            {
                path: 'dashboard/reports/igst-report',
                element:<IGSTReport/>
            },
            {
                path: 'dashboard/reports/clearance-time-import-report',
                element:<ClearanceTimeImportReport/>
            },
            {
                path: 'dashboard/reports/clearance-time-export-report',
                element:<ClearanceTimeExportReport/>
            },
            {
                path: 'dashboard/reports/fta-report',
                element:<FtaReport/>
            },
            //Upload
            {
                path:'dashboard/upload/upload-files',
                element:<UploadFiles/>
            },
            // Admin
            {
                path: 'dashboard/admin/admin',
                element:<Admin/>
            },
            {
                path:'dashboard/app/admin-add',
                element:<AdminAdd/>
            },
            {
                path: 'dashboard/app/user-list',
                element:<AdminList/>
            },
            // Ui-Color
            // Widgets
            {
                path: 'dashboard/widget/widgetbasic',
                element:<Widgetbasic/>
            },
            {
                path: 'dashboard/widget/widgetchart',
                element:<Widgetchart/>
            },
            {
                path: 'dashboard/widget/widgetcard',
                element:<Widgetcard/>
            },
            
            // Form
            {
                path: 'dashboard/form/form-element',
                element:<FormElement/>
            },
            {
                path: 'dashboard/form/form-wizard',
                element:<FormWizard/>
            },
            {
                path: 'dashboard/form/form-validation',
                element:<FormValidation/>
            },
            // Table
            {
                path: 'dashboard/table/bootstrap-table',
                element:<BootstrapTable/>
            },
            {
                path: 'dashboard/table/table-data',
                element:<TableData/>
            },
            {
                path: 'dashboard/table/border-table',
                element:<Borderedtable/>
            },
            {
                path: 'dashboard/table/fancy-table',
                element:<Fancytable/>
            },
            {
                path: 'dashboard/table/fixed-table',
                element:<FixedTable/>
            },
            
            // Icon
            {
                path: 'dashboard/icon/solid',
                element:<Solid/>
            },
            {
                path: 'dashboard/icon/outline',
                element: <Outline />
            },
            {
                path: 'dashboard/icon/dual-tone',
                element: <DualTone/>
            },
            // extra-pages
            {
                path: 'dashboard/extra/privacy-policy',
                element: <PrivacyPolicy/>
            },
            {
                path: 'dashboard/extra/terms-of-service',
                element: <TermsofService/>
            },
            {
                path: 'dashboard/extra/blank-page',
                element: <BlankPage/>
            },
            {
                path: 'dashboard/extra/ShimmerComponent',
                element: <ShimmerComponent/>
            },
        ]
    },
]
