// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 640px) {
    .custom-choicejs .choices__list--dropdown .choices__item--selectable,.custom-choicejs .choices__list[aria-expanded] .choices__item--selectable {
        padding-right: 10px;
    }
    .custom-choicejs .choices__list--dropdown .choices__item--selectable::after,.custom-choicejs .choices__list[aria-expanded] .choices__item--selectable::after {
        content: ''
    }
}
`, "",{"version":3,"sources":["webpack://./src/assets/custom/css/choices.css"],"names":[],"mappings":"AAAA;IACI;QACI,mBAAmB;IACvB;IACA;QACI;IACJ;AACJ","sourcesContent":["@media (min-width: 640px) {\n    .custom-choicejs .choices__list--dropdown .choices__item--selectable,.custom-choicejs .choices__list[aria-expanded] .choices__item--selectable {\n        padding-right: 10px;\n    }\n    .custom-choicejs .choices__list--dropdown .choices__item--selectable::after,.custom-choicejs .choices__list[aria-expanded] .choices__item--selectable::after {\n        content: ''\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
