  import React, { Fragment, useEffect, useState } from 'react';
import { getIGSTDataService } from '../../../services/IGSTReportService';
import { getReportSummaryData } from '../../../services/FileDataService';
import { Table, Button } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { Excel } from 'antd-table-saveas-excel';
import Card from '../../../components/bootstrap/card';
import { getTodaysDate } from '../../../utilities/utils';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const IGSTReport = ({ data }) => {
    const iec_no = localStorage.getItem('iec_no');
    const [jsonData, setJsonData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [summaryDashboardData, setSummaryDashboardData] = useState([]);
    const today = new Date();
    // Extract day, month, and year components
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = today.getFullYear();
    const username = localStorage.getItem('name');

    // Concatenate components with hyphens
    const formattedDate = `${day}-${month}-${year}`;
  
  // Define columns for the table
  const columns = [
    { 
      title: 'IEC/Br CODE', 
      dataIndex: 'iec_br', 
      key: 'iec_br', 
      header: 'IEC/Br CODE' 
    },
    { 
      title: 'PORT', 
      dataIndex: 'port_code', 
      key: 'port_code', 
      header: 'PORT' 
    },
    { 
      title: 'FY', 
      dataIndex: 'fy', 
      key: 'fy', 
      header: 'FY' 
    },
    { 
      title: 'SHB NO.', 
      dataIndex: 'sb_no', 
      key: 'sb_no', 
      header: 'SHB NO.' 
    },
    { 
      title: 'SHB DT.', 
      dataIndex: 'sb_date', 
      key: 'sb_date', 
      header: 'SHB DT.' 
    },
    { 
      title: 'LEO DT.', 
      dataIndex: 'leo_date', 
      key: 'leo_date', 
      header: 'LEO DT.', 
      render: (text, record) => formatDateToDDMMYY(record.leo_date)
    },
    { 
      title: 'IGST AMT. (INR)', 
      dataIndex: 'igst_amt', 
      key: 'igst_amt', 
      header: 'IGST AMT. (INR)' 
    },
    { 
      title: 'SCROLL NO. / QUERY', 
      dataIndex: 'igst_scroll_number', 
      key: 'igst_scroll_number', 
      header: 'SCROLL NO. / QUERY' 
    },
    { 
      title: 'SCROLL DT.', 
      dataIndex: 'igst_scroll_date', 
      key: 'igst_scroll_date', 
      header: 'SCROLL DT.', 
      render: (text, record) => {
        if (record.igst_scroll_date) {
          return formatDateToDDMMYY(record.igst_scroll_date);
        }
      }
    },
    { 
      title: 'SCROLL AMT.', 
      dataIndex: 'scroll_amount', 
      key: 'scroll_amount', 
      header: 'SCROLL AMT.' 
    },
    { 
      title: 'IGST REMARK', 
      dataIndex: 'igst_remark', 
      key: 'igst_remark', 
      header: 'IGST REMARK' 
    },
    { 
      title: 'SCROLL TAT', 
      dataIndex: 'scroll_tat', 
      key: 'scroll_tat', 
      header: 'SCROLL TAT' 
    }
  ];  

  const dashboardReportSummaryColumns = [
    { 
      title: 'FY', 
      dataIndex: 'fy', 
      key: 'fy', 
      header: 'FY' 
    },
    { 
      title: 'IEC/BR CODE', 
      dataIndex: 'iec_br', 
      key: 'iec_br', 
      header: 'IEC/BR CODE' 
    },
    { 
      title: 'PORT CODE', 
      dataIndex: 'port_code', 
      key: 'port_code', 
      header: 'PORT CODE' 
    },
    { 
      title: 'IGST REFUND AMT.', 
      dataIndex: 'igst_refund_amt', 
      key: 'igst_refund_amt', 
      header: 'IGST REFUND AMT.' 
    },
    { 
      title: 'IGST REMARK', 
      dataIndex: 'igst_remark', 
      key: 'igst_remark', 
      header: 'IGST REMARK' 
    },
    { 
      title: 'NO. OF SHB', 
      dataIndex: 'remark_count', 
      key: 'remark_count', 
      header: 'NO. OF SHB' 
    }
  ];
  

  const getSummaryDashboard = async () => {
    try{
        setIsLoading2(true)
        let reportType = 'IGST-Refund'
        const response = await getReportSummaryData(reportType);

        if(response){
            console.log("response: ", response.data);
            if(response.data){
                setSummaryDashboardData(response.data);
            }
        }
        else{
            console.log("getSummaryDashboard :: no reponse")
            setSummaryDashboardData([]);
        }
        setIsLoading2(false)
    }
    catch(err){
        setIsLoading2(false)
        console.log(err);
    }  
  }

  function formatDateToDDMMYY(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return ''; // Return empty string if the dateString is not a valid date
    }
    else {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear()).slice(-2);
      return `${day}/${month}/${year}`;
    }
  }

  const getDateOnly = (dateString) => {
    if(dateString && dateString.length !== 0){
      const date = new Date(dateString);
      return date;
    }
    else{
      return '';
    }
  }

  const exportToExcel = async () => {
    let workbook = new ExcelJS.Workbook();
    let summaryWorksheet = workbook.addWorksheet('Summary');
    let worksheet = workbook.addWorksheet('Data');
    var data = jsonData;

    // Define columns for Excel export
    summaryWorksheet.columns = dashboardReportSummaryColumns;
    // Apply styles to the header row (Row 1)
    summaryWorksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true }; // Bold text
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Yellow background
      };
      cell.alignment = { vertical: 'middle'}; // Center align
    });

    summaryDashboardData.forEach((obj) => {
      summaryWorksheet.addRow({
        ...obj
      })
    })

    worksheet.columns = columns;
    // Apply styles to the header row (Row 1)
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true }; // Bold text
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Yellow background
      };
      cell.alignment = { vertical: 'middle' }; // Center align
    });

    data.forEach((obj) => {
      worksheet.addRow({
        ...obj,
        sb_date: getDateOnly(obj.sb_date),
        leo_date: getDateOnly(obj.leo_date),
        igst_scroll_date: getDateOnly(obj.leo_date),
        igst_amt: obj.igst_amt ? parseFloat(obj.igst_amt) : '',
        scroll_amount: obj.scroll_amount ? parseFloat(obj.scroll_amount) : ''
      })
      
    });

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    // Use FileSaver to save the file
    saveAs(blob, `Lucrative's CIP DSS - IGST Refund as on ${getTodaysDate()} of ${iec_no}.xlsx`);
  };

  const preprocessData = (data) => {
    return data.map(record => {
     
      if (record.sb_date) {
        const sbDate = new Date(record.sb_date);
        record.sb_date = formatDateToDDMMYY(sbDate);
      }
      return record;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await getIGSTDataService(iec_no);
        setJsonData(response);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      }
    };

    
    getSummaryDashboard();
    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Lucrative's CIP DSS  - IGST Refund as on {formattedDate} of {username}</h4>
              </div>
              <div>
                <button
                 className='btn btn-primary'
                  onClick={exportToExcel}
                >
                  Export to Excel
                </button>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
            <Table
                className="report-summary-table"
                width={100}
                columns={dashboardReportSummaryColumns} 
                dataSource={summaryDashboardData}
                pagination={false}
                loading={isLoading2}
            />
            <hr />
              <div className="table-responsive">
                <Table bordered columns={columns} dataSource={jsonData} loading={isLoading} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default IGSTReport;
