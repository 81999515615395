import React, { useState, useEffect, memo, Fragment } from 'react';
import { Form, Modal, Button, Card, Row, Col } from 'react-bootstrap';
import { Table, Popconfirm, message, Select } from 'antd';
import { uploadStdCertificateDataService, deleteStdCertificateDataService } from '../../../services/UploadService';
import { StdCertificateDataService, getAllAdminIECsService }  from '../../../services/FileDataService';
import StdCertificateInputModal from './ManualDataEntryInputModal';

import moment from 'moment';
import 'react-dropzone-uploader/dist/styles.css';
import { CSVLink } from "react-csv";
import { formatDateTime } from '../../../utilities/utils';

const edit = ` <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
<path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
<path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
</svg>`;

const deleteIcon = ` <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
<path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
<path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
<path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
</svg>`;

const StdCretificateFormComponent = (props) => {
  const [show, setShow] = useState(false);
  const [fileStatus, setFileStatus] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [editData, setEditData] = useState(null);
  const [stdCertDataList, setStdCertDataList] = useState([]);
  const [formValues, setFormValues] = useState({
    file_no: '',
    issuing_date: '',
    expiry_date: '',
    issuing_authority: '',
    purpose: ''
  });
  const [messageApi, contextHolder] = message.useMessage();
  const [IECOptions, setIECOptions] = useState([]);
  const [selectedIEC, setSelectedIEC] = useState(localStorage.getItem('iec_no'));
  const [iecName, setIECName] = useState(localStorage.getItem('name'));
  const [userRole, setUserRole] = useState(localStorage.getItem('role'))

  const handleShow = () => setShow(true);
  const handleClose = () => {
    fetchStdCertificateData();
    setShow(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }

  const handleIECSelection = (val) => {
    console.log(val)
    if (val){
      let iec_no = val.split(";")[0];
      let iec_name = val.split(";")[1];
      setSelectedIEC(iec_no)
      setIECName(iec_name)
    }
    else{
      setSelectedIEC(null);
      setIECName('');
    }
  }

  const getAdminIEC = async () => {
    const token = localStorage.getItem('token');
    const response = await getAllAdminIECsService(token);

    if (response && response.data){
      let i = 0;
      const options = response.data.map(item => ({
        value: `${item.iec_no};${item.fullname}`,
        label: `${item.iec_no} (${item.fullname})`,
        key: i++,
      }));

      setIECOptions(options);
    }
    
  }

  const errorFunction = (content) => {
    messageApi.open({
      type: 'error',
      content: content,
    });
  };

  const successFunction = (content) => {
    messageApi.open({
      type: 'success',
      content: content,
    });
  };

  // specify upload params and url for your files
  const getUploadParams = ({ meta }) => {
    return { url: 'https://httpbin.org/post' };
  }

  // called every time a file's `status` changes
  // const handleChangeStatus = ({ meta, file }, status) => {
  //   setFileStatus(prevStatus => ({
  //     ...prevStatus,
  //     [meta.name]: {
  //       name: meta.name,
  //       percent: meta.percent,
  //       status: status,
  //     },
  //   }));
  // }
  const handleSubmit = async () => {
    try {
      // Perform validation checks before making the API call if needed

      const stdCertData = {
        'file_no': String(formValues.file_no),
        'issuing_date': formValues.issuing_date,
        'expiry_date': formValues.expiry_date,
        'issuing_authority': formValues.issuing_authority,
        'purpose': formValues.purpose,
      }

      const isValid = Object.entries(stdCertData).every(([key, value]) => {
        // Exclude 'purpose' key from validation
        if (key !== 'purpose') {
          return value !== null && value.trim() !== '';
        }
        return true; // Skip validation for 'purpose' key
      });
      
      if (!isValid) {
        errorFunction("Please enter all the required fields");
        return;
      }

      if (!selectedIEC || selectedIEC == ''){
        errorFunction("Please select IEC");
        return
      }

      // Append any additional data or files if needed
      const token = localStorage.getItem('token');
      // Make the API call
      const response = await uploadStdCertificateDataService(props.title, stdCertData, selectedIEC, token)
      console.log("response = " + JSON.stringify(response));
      if(response){

        if(response.data){
          // success
          setFormValues({
            file_no: '',
            issuing_date: '',
            expiry_date: '',
            issuing_authority: '',
            purpose: ''
          });
          successFunction("Success");
        }
        else{
          // error
        }
        
      }
      else{

      }
      fetchStdCertificateData();

    } catch (error) {
      // error
      console.log("Error code: " + error.response.status);
      console.log("Error code: " + error.response.data.msg);
      if(error.response.data.msg){
        errorFunction(error.response.data.msg);
      }
      else{
        errorFunction(error.message);
      }
      
      console.error('An error occurred while submitting form data', error);
    }
  }

  const deleteStdCertificate = async (record) => {
    console.log("delete record: ", record);
    const id = record.std_certificate_id;
    // Append any additional data or files if needed
    const token = localStorage.getItem('token');
    try{
      const response = await deleteStdCertificateDataService(id, token);
      fetchStdCertificateData();
      message.success('Deleted successfully');
    }
    catch(error){
      console.log(error);
      message.error("Error")
    }
  }

  const editStdCertificate = (record) => {
    console.log(record);
    setEditData(record);
    handleShow();
  }
  const cancel = (e) => {
    console.log(e);
  };

  const columns = [
      {
        title: "Action",
        dataIndex: "iconAction",
        width: 70,
        fixed: 'left',
        render: (text, record) => {
          return (
            <div class="flex align-items-center list-user-action">
              <a onClick={() => editStdCertificate(record)} class="btn btn-sm btn-icon btn-warning" data-toggle="tooltip" data-placement="top" title="" data-original-title="Add" to="#">
                <span class="btn-inner" dangerouslySetInnerHTML={{ __html: edit}}>
                  
                </span>
              </a>
              &nbsp;
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                onConfirm={() => deleteStdCertificate(record)}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <a class="btn btn-sm btn-icon btn-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" to="#">
                  <span class="btn-inner" dangerouslySetInnerHTML={{ __html: deleteIcon}}>
                    
                  </span>
                </a>
              </Popconfirm>
            </div>
          );
        },
      },
      {
        title: "IEC",
        dataIndex: "iec_no"
      },
      {
        title: "File No",
        dataIndex: "file_no",
        
      },
      {
        title: "Issuing Date",
        dataIndex: "issuing_date",
      },
      {
        title: "Expiry Date",
        dataIndex: "expiry_date",
      },
      {
        title: "Issuing Authority",
        dataIndex: "issuing_authority",
      },
      {
        title: "Purpose (optional)",
        dataIndex: "purpose",
      },
      {
        title: "Timestamp",
        dataIndex: "created_at",
        render: (text) => {
          // Format the date to only show the date part, not the time
          return moment(text).format('DD-MM-YYYY');
        },
      },
      
  ];

  const fetchStdCertificateData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await StdCertificateDataService(props.title, token);
      setStdCertDataList([]);
      if(response){
        if(response.data){
          setStdCertDataList(response.data);
        }
      }
    }
    catch (error) {
      console.log(error);
    }

  }

  const csvHeaders = [
    { label: "Type", key: "bill_type" },
    { label: "File No", key: "file_no" },
    { label: "IEC", key: "iec_no" },
    { label: "Issuing Date", key: "issuing_date" },
    { label: "Expiry Date", key: "expiry_date" },
    { label: "Issuing Authority", key: "issuing_authority" },
    { label: "Purpose", key: "purpose" },
    { label: "Timestamp", key: "created_at" },
  ];

  useEffect(() => {
    setFormValues({
      file_no: '',
      issuing_date: '',
      expiry_date: '',
      issuing_authority: '',
      purpose: ''
    });
    getAdminIEC();
    fetchStdCertificateData();
  }, [props.title]);

  return (
    <Fragment>
      {contextHolder}
      <Card>
        <Card.Body>
          <Card.Title>{props.title}

          {userRole && userRole == 'superadmin' &&
            <Select
                focus
                showSearch
                placeholder="Select IEC"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                onChange={handleIECSelection}
                allowClear
                options={IECOptions}
                style={{ width: '300px', float: 'right' }}
              />

              
              }
          </Card.Title>
          <hr className="hr-horizontal" />
              <h6 style={{display: 'inline-block', textAlign: 'center'}}><b>{iecName}</b></h6>
              &nbsp;&nbsp;&nbsp;
              <strong>{selectedIEC}</strong>
          <hr className="hr-horizontal" />
          <Form>
            <Form.Group as={Row} className="mb-3" controlId="file_no">
              <Form.Label column sm="2">File No.*</Form.Label>
              <Col sm="10">
                <Form.Control
                  required
                  type="text"
                  name="file_no"
                  placeholder='Enter file no.'
                  value={formValues.file_no}
                  onChange={handleInputChange}
                  style={{ border: formValues.file_no ? '1px solid green' : '1px solid red' }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="issuing_date">
              <Form.Label column sm="2">Issuing Date*</Form.Label>
              
              <Col sm="5">
                {/* <DatePicker id="issuing_date" name="issuing_date" defaultValue={formValues.issuing_date} onChange={handleDateInputChange} style={{width: "50%"}} renderExtraFooter={() => 'extra footer'} /> */}
                <Form.Control
                  required
                  type="date"
                  name="issuing_date"
                  placeholder='Enter issuing date'
                  value={formValues.issuing_date}
                  onChange={handleInputChange}
                  style={{ border: formValues.issuing_date ? '1px solid green' : '1px solid red' }}
                />
              </Col>

              <Form.Label column sm="1">Expiry*</Form.Label>
              <Col sm="4">
                <Form.Control
                  required
                  type="date"
                  name="expiry_date"
                  placeholder='Enter expiry date'
                  value={formValues.expiry_date}
                  onChange={handleInputChange}
                  style={{ border: formValues.expiry_date ? '1px solid green' : '1px solid red' }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="issuing_authority">
              <Form.Label column sm="2">Issuing Authority*</Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  name="issuing_authority"
                  placeholder='Enter Issuing Authority'
                  value={formValues.issuing_authority}
                  onChange={handleInputChange}
                  style={{ border: formValues.issuing_authority ? '1px solid green' : '1px solid red' }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="purposeRemarks">
              <Form.Label column sm="2">Purpose (optional)</Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  name="purpose"
                  placeholder='Enter purpose'
                  value={formValues.purpose}
                  onChange={handleInputChange}
                  
                />
              </Col>
            </Form.Group>

            <Button variant="primary" onClick={handleSubmit} style={{float:'right'}}>
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>

      <div className="table-responsive">
      
        <CSVLink
          style={{float: "right"}}
          className="btn btn-primary"
          data={stdCertDataList}
          headers={csvHeaders}
          filename={props.title + "_export_" + formatDateTime(new Date()) + ".csv"}
          onClick={() => {
          console.log("clicked") 
          }}
        >
          Export
        </CSVLink>
        <StdCertificateInputModal title={props.title} handleClose={handleClose} open={show} data={editData}/>
        <Table
          columns={columns}
          dataSource={stdCertDataList}
        />
      </div>
    </Fragment>
  );
};

export default StdCretificateFormComponent;
