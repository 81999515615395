import React,{ memo, Fragment,useState,useEffect } from "react";

//react-bootstrap
import { Row, Col, Form, Image, Button, Modal } from "react-bootstrap";
import { Table, Popconfirm, message, Select, Checkbox  } from 'antd';
import moment from 'moment';//components
import Card from "../../../components/bootstrap/card";
import AdminEdit from '../app/admin-edit';
import { useNavigate } from 'react-router-dom'
import './add-admin.css'
import { UpdateUserPageAccessMapping } from '../../../services/UserService';

import { getUsers, deleteUser, updateUser, resetUserPasswordService, getCipPagesService } from "../../../services/UserService";

const resetPasswordIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 2C8.14 2 5 5.14 5 9C5 10.46 5.48 11.81 6.27 12.91L2.39 16.79C2.15 17.03 2 17.36 2 17.72V21C2 21.55 2.45 22 3 22H6.28C6.64 22 6.97 21.85 7.21 21.61L11.09 17.73C12.2 18.52 13.55 19 15 19C18.86 19 22 15.86 22 12C22 8.14 18.86 5 15 5C13.55 5 12.2 5.48 11.09 6.27L7.21 2.39C7.47 2.15 7.72 2 8 2H12ZM15 7V1L20 6L15 11V7Z" fill="currentColor"/>
</svg>
`;


const edit = ` <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
<path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
<path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
</svg>`;

const remove = ` <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
<path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
<path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
<path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
</svg>`;

const accessControl = `<svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 2C8.686 2 6 4.686 6 8V11C4.895 11 4 11.895 4 13V18C4 19.105 4.895 20 6 20H18C19.105 20 20 19.105 20 18V13C20 11.895 19.105 11 18 11V8C18 4.686 15.314 2 12 2Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
<path d="M9 11V8C9 6.343 10.343 5 12 5C13.657 5 15 6.343 15 8V11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
</svg>`;


const EditUserAccessControl = (props) => {
  const [userRbacMappings, setUserRbacMappings] = useState(props.userData.rbac_mappings);
  const [reportRbacMapping, setReportRbacMapping] = useState([]);
  const [uploadRbacMapping, setUploadRbacMapping] = useState([]);
  const [selectedPageIds, setSelectedPageIds] = useState([]);
  const [cipReportPages, setCipReportPages] = useState([]);
  const [cipUploadPages, setCipUploadPages] = useState([]);

  const submitAcessControl = () => {
    const payload = {
      signal_users_id: props.userData.signal_users_id,
      page_ids: selectedPageIds
    }
    console.log("payload: ", payload);

    UpdateUserPageAccessMapping(payload).then(response => {
      console.log("response: ", response);
      props.handleClose()
    }, error => {
      console.log("error", error);
      props.handleClose();
    })

  }

  const handleSelect = (record) => {
    // console.log("selected record: ", record)

    if (selectedPageIds.includes(record.page_id)){
      setSelectedPageIds((prevIds) => prevIds.filter(id => id !== record.page_id));
    }
    else{
      
      setSelectedPageIds((prevIds) => [...prevIds, record.page_id]);
    }
  }

  const columns = [
    {
      title: 'Select',
      dataIndex: 'select',
      width: 60,
      key: 'select',
      render: (_, record) => (
        <Checkbox style={{fontSize: '13px'}} 
          className="small-checkbox"
          checked={selectedPageIds.includes(record.page_id)}
          onChange={() => handleSelect(record)}
        />
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text, record) => (
        <p style={{fontSize: '14px'}}>{record.page_type + " - " + record.description}</p>
      )
    },
    // {
    //   title: 'Type',
    //   dataIndex: 'page_type',
    //   key: 'page_type',
    // },
  ]

  const getCipPages = async () => {
    try{
      const token = localStorage.getItem('token');
      const response = await getCipPagesService(token);

      if (response && response.data.data){
        let reportPages = [];
        let uplaodPages = [];
        response.data.data.map((item, i) => {
         

          const obj = {
            page_id: item.page_id,
            description: item.page_name,
            page_type: item.page_type,
            key: '0-' + i
          }

          if (item.page_type == "REPORT"){
            
           reportPages.push(obj)
          }
          if (item.page_type == "UPLOAD"){
            uplaodPages.push(obj)
          }
        })

        setCipReportPages(reportPages);
        setCipUploadPages(uplaodPages);
      }
    }
    catch(error){

    }
  }

  useEffect(() => {
    console.log("props.userData", props.userData)
    getCipPages();
    let reportPages = [];
    let uploadPages = [];
    props.userData.rbac_mappings.map(item => {
      
      if (item.page_type == "REPORT"){
        reportPages.push(item.page_id);
      }

      if (item.page_type == "UPLOAD"){
        uploadPages.push(item.page_id);
      }
    });
    const allMappings = reportPages.concat(uploadPages);
    setSelectedPageIds(allMappings);
    setReportRbacMapping(reportPages);
    setUploadRbacMapping(uploadPages);
  }, [props])

   return (
    <Fragment>
      <Modal className="access-control-modal" show={props.show} onHide={props.handleClose} backdrop="static">
          <Modal.Body>
          <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                      <h4 className="card-title">Edit Admin Access Control</h4>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="new-user-info">
                      <Form>
                        <div className="row">
                              <Form.Group className="col-md-12 form-group">
                                  <Form.Label htmlFor="fullname">{props.userData.fullname } - {props.userData.email}</Form.Label>
                                
                              </Form.Group>
                              <Form.Group className="col-md-6 form-group">
                              <Table
                                className="access-control-table"
                                dataSource={cipReportPages}
                                columns={columns}
                                pagination={false}
                                scroll={{ y: 200, x:300 }}
                                rowKey="key"
                                style={{ flex: 1 }}
                              />
                              </Form.Group>
                              <Form.Group className="col-md-6 form-group">
                              <Table
                                className="access-control-table"
                                dataSource={cipUploadPages}
                                columns={columns}
                                pagination={false}
                                scroll={{ y: 200, x:300 }}
                                rowKey="key"
                                style={{ flex: 1 }}
                              />
                              </Form.Group>
                              <hr/>
                              
                        </div>
                        <Button type="button" variant="btn btn-secondary" onClick={props.handleClose}>Cancel</Button>
                        <Button style={{float: "right"}} type="button" variant="btn btn-primary" onClick={submitAcessControl}>Submit</Button>
                      </Form>
                  </div>
                </Card.Body>
              </Card>
            </Modal.Body>
          </Modal>
    </Fragment>
   )
}

const ResetPassword = (props) => {
  
  const [newPassword, setNewPasssword] = useState('');

  const handleInputChange = (e) => {
    const { id, value } = e.target; 
    setNewPasssword(value);
  };

  const resetPassword = async () => {
    console.log("new password: ", newPassword)
    const userId = props.userData.signal_users_id;
    const token = localStorage.getItem('token');

    try{
      const response = await resetUserPasswordService(userId, newPassword, token)
      message.success("Reset passsword success")
      props.handleClose()
    }
    catch(error){
      message.error("Error")
    }
    
    
  }

  useEffect(() => {
    console.log("props.userData", props.userData)
  }, [props])

  return (
    <Fragment>
      <Modal show={props.show} onHide={props.handleClose} backdrop="static">
          <Modal.Body>
          <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                      <h4 className="card-title">Reset User Password</h4>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="new-user-info">
                      <Form>
                        <div className="row">
                              <Form.Group className="col-md-12 form-group">
                                  <Form.Label htmlFor="fullname">Full Name</Form.Label>
                                  <Form.Control type="text" value={props.userData.fullname} id="fullname" placeholder="FullName" disabled/>
                              </Form.Group>
                              
                              <Form.Group className="col-md-12 form-group">
                                  <Form.Label htmlFor="email">Email</Form.Label>
                                  <Form.Control type="email" value={props.userData.email} id="email" placeholder="Email" disabled/>
                              </Form.Group>
                              <hr/>
                              <Form.Group className="col-md-12  form-group">
                                  <Form.Label htmlFor="newPassword">New Password:</Form.Label>
                                  <Form.Control type="text" value={newPassword} id="newPassword" placeholder="Enter new password" onChange={handleInputChange}/>
                              </Form.Group>
                            <hr/>
                        </div>
                        <Button type="button" variant="btn btn-secondary" onClick={props.handleClose}>Cancel</Button>
                        <Button style={{float: "right"}} type="button" variant="btn btn-primary" onClick={resetPassword}>Update</Button>
                      </Form>
                  </div>
                </Card.Body>
              </Card>
            </Modal.Body>
          </Modal>
    </Fragment>
  )
}

const AdminList = memo(() => {
  const [usersList, setUsersList] = useState([]);
  const [userEditData, setUserEditData] = useState({});
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [showAccesControlModal, setShowAccessControlModal] = useState(false);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    role: localStorage.getItem('role'),
    iec: localStorage.getItem('iec_no'),
    name: localStorage.getItem('name')
  })
  const [messageApi, contextHolder] = message.useMessage();

  const fetchData = async () => {
    try {
      // Fetch users data here, adjust the service function accordingly
      const token = localStorage.getItem('token');
      let skip = 0;
  
      const response = await getUsers(token,skip);
      const data = response.data.data.users;
     
      setUsersList(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const errorFunction = (content) => {
    messageApi.open({
      type: 'error',
      content: content,
    });
  };

  const successFunction = (content) => {
    messageApi.open({
      type: 'success',
      content: content,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);


  const columns = [
    {
      title: "Action",
      dataIndex: "iconAction",
      render: (text, record) => {
        return (
          <div class="flex align-items-center list-user-action">
            <a onClick={() => editUser(record)} class="btn btn-sm btn-icon btn-warning" data-toggle="tooltip" data-placement="top" title="" data-original-title="Add" to="#">
              <span class="btn-inner" dangerouslySetInnerHTML={{ __html: edit}}>
                
              </span>
            </a>
            &nbsp;
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this task?"
              onConfirm={() => handleDeleteUser(record)}
              trigger="click"
              okText="Yes"
              cancelText="No"
              
            >
              <a class="btn btn-sm btn-icon btn-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" to="#">
                <span class="btn-inner" dangerouslySetInnerHTML={{ __html: remove}}>
                  
                </span>
              </a>

            </Popconfirm>
            &nbsp;
            <a onClick={() => resetUserPassword(record)} class="btn btn-sm btn-icon btn-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="reset" to="#">
              <span class="btn-inner" dangerouslySetInnerHTML={{ __html: resetPasswordIcon}}>
                
              </span>
            </a>
            &nbsp;
            {
              localStorage.getItem('role') == 'superadmin' && 
              <a onClick={() => editUserAccessControl(record)} class="btn btn-sm btn-icon btn-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="reset" to="#">
                <span class="btn-inner" dangerouslySetInnerHTML={{ __html: accessControl}}>
                  
                </span>
              </a>
            }
            
          </div>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "fullname",
      sorter: (a, b) => a.fullname.length - b.fullname.length,
    },
    {
      title: "IEC",
      dataIndex: "iec_no",
      sorter: (a, b) => a.iec_no.length - b.iec_no.length,
    },
    {
      title: "Contact",
      dataIndex: "mobile",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Creation Date",
      dataIndex: "created_at",
      render: (text) => {
        // Format the date to only show the date part, not the time
        return moment(text).format('DD-MM-YYYY');
      },
    },
    
  ]

  const handleDeleteUser = (record) => {
    if(record){
      var deleteUserSchema = {
        "users": []
      };
      const user = {
        email: record.email,
        iec_no: record.iec_no,
        mobile: record.mobile
      }
      deleteUserSchema.users.push(user);

      const token = localStorage.getItem('token');
      deleteUser(token, deleteUserSchema).then(response => {
        if (response){
          successFunction("User deleted");
          fetchData();
        }
      }, error => {
          console.log("error response: ", error.response)
          errorFunction("Error");
      })
    }
  }

  const closeEditModal = () => {
    setIsEditModalVisible(false);
  }

  const openEditModal = () => {
    setIsEditModalVisible(true);
  }

  const editUser = (user) => {
    if(user){
      console.log(user);
      setUserEditData(user);
      openEditModal();
      
    }
  }

  const resetUserPassword = (user) => {
    if(user){
      console.log(user);
      setUserEditData(user);
      setShowResetModal(true);
      
    }
  }

  const editUserAccessControl = (user) => {
    console.log(user);
    setUserEditData(user);
    setShowAccessControlModal(true);
  }
  const handleCloseResetModal = () => {
    setShowResetModal(false);
  }
  const handleCloseAccessControlModal = () => {
    setShowAccessControlModal(false);
    fetchData();
  }
  const handleUpdateUser = (userData) => {
    console.log('userDetails-',userData);
    const token = localStorage.getItem('token');

    updateUser(token, userData).then(response => {
                    
      if (response){
          // console.log(response)
          if(response.data){
              if(response.data.status != 200){
                errorFunction(response.data.msg);
              }
              else{
                closeEditModal();
                successFunction("User updated");
                fetchData();
              }
              
          }  
      }
  }, error => {
      console.log("error response: ", error.response)
      errorFunction("Error");
      
  }) 
  }

  const handleUploadClick = () => {
    navigate('/dashboard/upload/upload-files?__card__=ADVANCE AUTH'); // Adjust the route as needed
};

  return (
    <Fragment>
      {contextHolder}
      {isEditModalVisible && 
        
        <AdminEdit show={isEditModalVisible} 
          handleClose={closeEditModal} 
          handleUpdate={handleUpdateUser}
          userData={userEditData}
        />}

      {showResetModal && 
        <ResetPassword show={showResetModal} userData={userEditData} handleClose={handleCloseResetModal}/>
      }
      { showAccesControlModal &&
        <EditUserAccessControl show={showAccesControlModal} userData={userEditData} handleClose={handleCloseAccessControlModal} />

      }
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">{userDetails.role == 'superadmin' ? 'Admin List' : 'User List'}</h4>
              </div>
              <Button variant="primary" onClick={handleUploadClick}>
                Upload
            </Button>
            </Card.Header>
            <Card.Body className="px-0">
              <div className="table-responsive">
               
                <Table
                  columns={columns}
                  dataSource={usersList}
                  scroll={{
                    x: 500,
                  }}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

AdminList.displayName = "AdminList";
export default AdminList;
