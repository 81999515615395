import axios from "axios";
import {SERVER_URL} from "../const";


const token = localStorage.getItem('token');

export const uploadFiles = async (files, billType, iec_no) => {
  try {
    var formData = new FormData();
    for (var i = 0; i < files.length; i++){
      formData.append(
          "upload_file",
          files[i]
      );
    }

    const response = await axios.post(SERVER_URL + "/uploadfile?billType=" + billType + "&iec_no=" + iec_no, formData, {
      headers: {
        'Authorization': 'Bearer ' + token, 
        'Content-Type': 'multipart/form-data'
      }
    });

    // Handle success response
    console.log('Upload successful:', response);
    alert('Files uploaded successfully!');
  } catch (error) {
    // Handle error
    console.error('Error uploading files:', error);
    //alert('Error uploading files. Please try again later.');
  }
};


// Import any necessary dependencies or configure your API endpoint

export const uploadStdCertificateDataService = async (billType, formData, iec_no, token) => {
  try {
    console.log('tolen',token)
    const response = await axios.post(SERVER_URL + "/std-cert-data/insert?bill_type=" + billType + "&iec_no=" + iec_no, formData, {
      headers: {
        'Authorization': 'Bearer ' + token,
      },
    });

    if (response.status === 200) {
      const data = response.data;
      console.log('API Response:', data);
      return data;
    } else {
      console.error('API Error:', response.statusText);
      throw new Error(response.statusText);
    }
  } catch (error) {
    console.error('Error:', error.message);
    throw error;
  }
};

export const updateStdCertificateDataService = async (id, updatedData, token) => {
  try {
    console.log('tolen',token)
    const response = await axios.put(SERVER_URL + `/std-cert-data/${id}`, updatedData, {
      headers: {
        'Authorization': 'Bearer ' + token,
      },
    });

    if (response.status === 200) {
      const data = response.data;
      console.log('API Response:', data);
      return data;
    } else {
      console.error('API Error:', response.statusText);
      throw new Error(response.statusText);
    }
  } catch (error) {
    console.error('Error:', error.message);
    throw error;
  }
};

export const deleteStdCertificateDataService = async (id, token) => {
  try {
    console.log('tolen',token)
    const response = await axios.delete(SERVER_URL + `/std-cert-data/${id}`, {
      headers: {
        'Authorization': 'Bearer ' + token,
      },
    });

    if (response.status === 200) {
      const data = response.data;
      console.log('API Response:', data);
      return data;
    } else {
      console.error('API Error:', response.statusText);
      throw new Error(response.statusText);
    }
  } catch (error) {
    console.error('Error:', error.message);
    throw error;
  }
};


