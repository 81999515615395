import React, { useState, useEffect, memo, Fragment } from 'react';
import { Form, Modal, Button, Card,Alert } from 'react-bootstrap';
import { Table, message, Select, Popconfirm } from 'antd';
import Dropzone from "react-dropzone-uploader";
import 'react-dropzone-uploader/dist/styles.css';
import { uploadFiles } from '../../../services/UploadService';
import { fileUploadStatusDataService, getAllAdminIECsService, deleteFileDataService }  from '../../../services/FileDataService';
import moment from 'moment';

const deleteIcon = ` <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
<path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
<path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
<path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
</svg>`;

const CardComponent = (props) => {
  const [show, setShow] = useState(false);
  const [fileStatus, setFileStatus] = useState({});
  const [fileStatusDataList, setFileStatusDataList] = useState([]);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [file, setFile] = React.useState(null);
  const [fileUploadError, setFileUploadError] = useState(null); // State to hold file upload error
  const [IECOptions, setIECOptions] = useState([]);
  const [selectedIEC, setSelectedIEC] = useState(localStorage.getItem('iec_no'));
  const [userRole, setUserRole] = useState(localStorage.getItem('role'))

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    setFileStatus(prevStatus => ({
      ...prevStatus,
      [meta.name]: {
        name: meta.name,
        percent: meta.percent,
        status: status,
      },
    }));
  }

  const deleteFileRecord = async (record) => {
    console.log("delete record", record);
    const token = localStorage.getItem('token');
    const response = await deleteFileDataService(token, record.file_status_id);
    if(response){
      console.log("File data is deleted");
      fetchFileUploadStatus();
    }
    else{
      console.log("deleteFileRecord : response: Error");
    }
  }

  const cancel = (e) => {
    console.log(e);
  };

  const fileUploadStatusColumns = [
    {
      title: "Action",
      dataIndex: "iconAction",
      width: 70,
      fixed: 'left',
      render: (text, record) => {
        return (
          <div class="flex align-items-center list-user-action">
            <Popconfirm
              title="Delete the file record"
              description="Are you sure to delete this file?"
              onConfirm={() => deleteFileRecord(record)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <a class="btn btn-sm btn-icon btn-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" to="#">
                <span class="btn-inner" dangerouslySetInnerHTML={{ __html: deleteIcon}}>
                  
                </span>
              </a>
            </Popconfirm>
          </div>
        );
      },
    },
    {
      title: "File Name",
      dataIndex: "filename",
    },
    {
      title: "User IEC",
      dataIndex: "user_iec_no",
    },
    {
      title: "File Status",
      dataIndex: "status",
    },
    {
      title: "Remark",
      dataIndex: "remarks",
    },
    {
      title: "Uploaded on",
      dataIndex: "created_at",
      render: (text) => {
        // Format the date to only show the date part, not the time
        return moment(text).format('DD-MM-YYYY');
      },
    },
  ]

  const getAdminIEC = async () => {
    const token = localStorage.getItem('token');
    const response = await getAllAdminIECsService(token);

    if (response && response.data){
      let i = 0;
      const options = response.data.map(item => ({
        value: item.iec_no,
        label: `${item.iec_no} (${item.fullname})`,
        key: i++,
      }));

      setIECOptions(options);
    }
    
  }

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = async (files) => {
    try {
      if (file !== null) {
        // Call your upload API here

        if (selectedIEC && selectedIEC != ''){
          await uploadFiles(file, props.title, selectedIEC);
          // Reset file upload error if successful
          setFileUploadError(null);
          setSelectedIEC(null);
          fetchFileUploadStatus()
        }
        else{
          setFileUploadError('Please select an IEC');
        }
        
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setFileUploadError('File upload failed. Please try again.'); // Set file upload error message
    }
  }
    

  const Preview = ({ meta }) => {
    const { name, percent, status } = fileStatus[meta.name] || meta;
    return (
      <span style={{ alignSelf: 'flex-start', margin: '10px 3%', fontFamily: 'Helvetica' }}>
        {name}, {Math.round(percent)}%, {status}
      </span>
    )
  }

   // Get uploaded file
   const handleFileChange = (e) => {
      console.log(e.target.files);
    const totalFiles = e.target.files;
    if (totalFiles.length > 25){
        alert("You cannot select more than 25 files");
        window.location.reload();
    } else{
        setFile(totalFiles);
    }

  }

  const fetchFileUploadStatus = async () => {
    try {
      setFileStatusDataList([]);
      const token = localStorage.getItem('token');
      const response = await fileUploadStatusDataService(props.title, token);
      
      if(response){
        if(response.data){
          setFileStatusDataList(response.data);
        }
      }
    }
    catch (error) {
      console.log(error);
    }

  }

  const handleIECSelection = (val) => {
    console.log(val)
    if (val){
      setSelectedIEC(val)
    }
    else{
      setSelectedIEC(null)
    }
  }

  useEffect(() => {
    getAdminIEC();
    fetchFileUploadStatus();
  }, [props.title]);


  return (
    <Fragment>
      <Card>
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
<input 
    onChange={handleFileChange} 
    className="file-input" 
    type="file" 
    multiple>
</input>

    {userRole && userRole == 'superadmin' &&
    <Select
        showSearch
        placeholder="Select IEC"
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        onChange={handleIECSelection}
        allowClear
        options={IECOptions}
        style={{ width: '300px' }}
      />
      }

      &nbsp;&nbsp;&nbsp;&nbsp;
      <Button title="Select a file and then upload" onClick={handleSubmit} style={{padding: "5px 20px"}} className="upload-btn">
                <i  className="fa-solid fa-cloud-arrow-up"></i>
                Upload
            </Button>
            {fileUploadError && <Alert variant="danger">{fileUploadError}</Alert>} {/* Display file upload error message */}

        </Card.Body>
        <div className="table-responsive">
          <Table
            columns={fileUploadStatusColumns}
            dataSource={fileStatusDataList}
            
          />
        </div>
        
      </Card>
    </Fragment>
  );
};

export default CardComponent;
