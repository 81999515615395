import React, { useState, useEffect, memo, Fragment } from 'react';
import { Form, Button, Card, Row, Col } from 'react-bootstrap';
import { Modal } from 'antd';

import { updateStdCertificateDataService } from '../../../services/UploadService';


const StdCertificateInputModal = (props) => {
    const [formValues, setFormValues] = useState({
        file_no: '',
        issuing_date: '',
        expiry_date: '',
        issuing_authority: '',
        purpose: ''
    });


    const showModal = () => {
        // setOpen(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
          ...formValues,
          [name]: value,
        });
    }

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setFormValues({});
        props.handleClose();
        // setOpen(false);
    };

    const handleOk = async () => {
        console.log("update: ", formValues);
        // Append any additional data or files if needed
        const token = localStorage.getItem('token');
        // Make the API call
        const response = await updateStdCertificateDataService(formValues.std_certificate_id, formValues, token)
        
        props.handleClose();
        
      };

    useEffect(() => {
        setFormValues(props.data);
    }, [props.data, props.title, props.open]);

    return (
        <>
            <Modal
                title={"Edit " + props.title}
                open={props.open}
                // onOk={handleOk}
                // confirmLoading={confirmLoading}
                onCancel={handleCancel}
                footer={[
                    // <Button key="back" onClick={handleCancel}>
                    //   Cancel
                    // </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                      Update
                    </Button>,
                  ]}
            >
                <Card>
                    <Card.Body>
                    {/* <Card.Title>{props.title}</Card.Title> */}
                    <hr className="hr-horizontal" />
                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="file_no">
                        <Form.Label column sm="3">File No.*</Form.Label>
                        <Col sm="9">
                            <Form.Control
                            required
                            type="text"
                            name="file_no"
                            placeholder='Enter file no.'
                            value={formValues && formValues.file_no}
                            onChange={handleInputChange}
                            style={{ border: formValues && formValues.file_no ? '1px solid green' : '1px solid red' }}
                            />
                        </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="issuing_date">
                        <Form.Label column sm="3">Issuing Date*</Form.Label>
                        
                        <Col sm="9">
                            {/* <DatePicker id="issuing_date" name="issuing_date" defaultValue={formValues.issuing_date} onChange={handleDateInputChange} style={{width: "50%"}} renderExtraFooter={() => 'extra footer'} /> */}
                            <Form.Control
                            required
                            type="date"
                            name="issuing_date"
                            placeholder='Enter issuing date'
                            value={formValues && formValues.issuing_date}
                            onChange={handleInputChange}
                            style={{ border: formValues && formValues.issuing_date ? '1px solid green' : '1px solid red' }}
                            />
                        </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="expiry_date">
                        <Form.Label column sm="3">Expiry*</Form.Label>
                        <Col sm="9">
                            <Form.Control
                            required
                            type="date"
                            name="expiry_date"
                            placeholder='Enter expiry date'
                            value={formValues && formValues.expiry_date}
                            onChange={handleInputChange}
                            style={{ border: formValues && formValues.expiry_date ? '1px solid green' : '1px solid red' }}
                            />
                        </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="issuing_authority">
                        <Form.Label column sm="3">Issuing Authority*</Form.Label>
                        <Col sm="9">
                            <Form.Control
                            type="text"
                            name="issuing_authority"
                            placeholder='Enter Issuing Authority'
                            value={formValues && formValues.issuing_authority}
                            onChange={handleInputChange}
                            style={{ border: formValues && formValues.issuing_authority ? '1px solid green' : '1px solid red' }}
                            />
                        </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-1" controlId="purposeRemarks">
                            <Form.Label column sm="3">Purpose (optional)</Form.Label>
                            <Col sm="9">
                                <Form.Control
                                type="text"
                                name="purpose"
                                placeholder='Enter purpose'
                                value={formValues && formValues.purpose}
                                onChange={handleInputChange}
                                
                                />
                            </Col>
                        </Form.Group>

                        
                    </Form>
                    </Card.Body>
                </Card>
            </Modal>
        </>
    )
}

export default StdCertificateInputModal;