export const remarks = {
    portAndCurrencyMismatch: {
      key: 'PORT_CURR_MISMATCH',
      remark: 'Port & Currency Mismatch - BRC needs to be corrected',
      drawback_remark_scroll_pending: 'DBK pending scroll Under process or query raised by customs BRC to be corrected',
      drawback_remark_scroll_generated: 'Scroll Generated - BRC to be Corrected',
      rodtep_scroll_not_generated:'Scroll Not Generated - BRC to be Corrected',
      rodtep_scroll_generated_scrip_pending:'Scroll Generated - BRC to be Corrected',
      rodtep_scroll_scrip_generated:'Scrip Generated - BRC to be Corrected'
    },
    portMismatch: {
      key: 'PORT_MISMATCH',
      remark: 'Port Mismatch - BRC needs to be corrected',
      drawback_remark_scroll_pending: 'DBK pending scroll Under process or query raised by customs BRC to be corrected',
      drawback_remark_scroll_generated: 'Scroll Generated - BRC to be Corrected',
      rodtep_scroll_not_generated:'Scroll Not Generated - BRC to be Corrected',
      rodtep_scroll_generated_scrip_pending:'Scroll Generated - BRC to be Corrected',
      rodtep_scroll_scrip_generated:'Scrip Generated - BRC to be Corrected'
    },
    currencyMismatch: {
      key: 'CURR_MISMATCH',
      remark: 'Currency Mismatch - BRC needs to be corrected',
      drawback_remark_scroll_pending: 'DBK pending scroll Under process or query raised by customs BRC to be corrected',
      drawback_remark_scroll_generated: 'Scroll Generated - BRC to be Corrected',
      rodtep_scroll_not_generated:'Scroll Not Generated - BRC to be Corrected',
      rodtep_scroll_generated_scrip_pending:'Scroll Generated - BRC to be Corrected',
      rodtep_scroll_scrip_generated:'Scrip Generated - BRC to be Corrected'
    },
    paymentReceivedAfter270Days: {
      key: 'PAYMENT_DELAYED',
      remark: 'Payment received after 270 days',
      drawback_remark_scroll_pending: 'DBK pending scroll Under process or query raised by customs- Payment received after 270 Days',
      drawback_remark_scroll_generated: 'Scroll Generated - Payment recd after 270 Days',
      rodtep_scroll_not_generated:'Scroll Not Generated - Payment recd after 270 Days ',
      rodtep_scroll_generated_scrip_pending:'Ready for scrip Generation - Payment recd after 270 Days',
      rodtep_scroll_scrip_generated:'Scrip Generated - Payment recd after 270 Days'
    },
    excessPaymentReceived: {
      key: 'EXCESS_PAYMENT',
      remark: 'Excess payment Received',
      drawback_remark_scroll_pending: 'DBK pending scroll Under process or query raised by customs - excess payment Recd.',
      drawback_remark_scroll_generated: 'Scroll Generated - Excess payment recd',
      rodtep_scroll_not_generated:'Scroll Not Generated - excess payment Recd.',
      rodtep_scroll_generated_scrip_pending:'Ready for scrip Generation - excess payment Recd.',
      rodtep_scroll_scrip_generated:'Scrip Generated - Excess Payment Recd'
    },
    shortPaymentReceived: {
      key: 'SHORT_PAYMENT',
      remark: 'Short Payment Received',
      drawback_remark_scroll_pending: 'DBK pending scroll Under process or query raised by customs - Short Payment Recd.',
      drawback_remark_scroll_generated: 'Scroll Generated - Short payment recd',
      rodtep_scroll_not_generated:'Scroll Not Generated - Short Payment Recd.',
      rodtep_scroll_generated_scrip_pending:'Ready for scrip Generation - Short Payment Recd.',
      rodtep_scroll_scrip_generated:'Scrip Generated - Short Payment Recd.'
    },
    obtainBRCOnPriority: {
      key: 'OBTAIN_BRC_PRIORITY',
      remark: 'Obtain the BRC on priority',
      drawback_remark_scroll_pending:'DBK pending scroll Under process or query raised by customs- BRC Pending',
      drawback_remark_scroll_generated:'Scroll Generated - BRC Pending',
      rodtep_scroll_not_generated:'Scroll Not Generated - BRC Pending',
      rodtep_scroll_generated_scrip_pending:'Scroll Generated - BRC Pending',
      rodtep_scroll_scrip_generated:'Scrip Generated - BRC Pending'
    },
    checkPaymentStatusAndObtainBRC: {
      key: 'CHECK_STATUS_OBTAIN_BRC',
      remark: 'Check the payment status and obtain the BRC on priority',
      drawback_remark_scroll_pending:'DBK pending scroll Under process or query raised by customs- BRC Pending',
      drawback_remark_scroll_generated:'Scroll Generated - BRC Pending',
      rodtep_scroll_not_generated:'Scroll Not Generated - BRC Pending',
      rodtep_scroll_generated_scrip_pending:'Scroll Generated - BRC Pending',
      rodtep_scroll_scrip_generated:'Scrip Generated - BRC Pending'
    },
    BRCPending: {
      key: 'BRC_PENDING',
      remark: 'BRC Pending',
      drawback_remark_scroll_pending:'DBK pending scroll Under process or query raised by customs- BRC Pending',
      drawback_remark_scroll_generated:'Scroll Generated - BRC Pending',
      rodtep_scroll_not_generated:'Scroll Not Generated - BRC Pending',
      rodtep_scroll_generated_scrip_pending:'Scroll Generated - BRC Pending',
      rodtep_scroll_scrip_generated:'Scrip Generated - BRC Pending'
    },
    allClear: {
      key: 'ALL_CLEAR',
      remark: 'All Clear',
      drawback_remark_scroll_pending:'DBK pending scroll Under process or query raised by customs- BRC Cleared',
      drawback_remark_scroll_generated:'Closed',
      rodtep_scroll_not_generated:'Scroll Not Generated - BRC Cleared',
      rodtep_scroll_generated_scrip_pending:'Ready for scrip Generation',
      rodtep_scroll_scrip_generated:'Scrip Generated'
    }
  };
  
  