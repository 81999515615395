import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './frontpage.scss';

const OtherServices = () => {
  return (
    <Container className="my-5 other-services">
      <h2 className="mb-4 title">Other Services</h2>
      <Row>
        <Col md={3} className="service-category">
          <h3>Management</h3>
          <ul className="custom-list">
            <li>EXIM Business Process Outsourcing (E2E)</li>
            <li>System Implementation for Exports & Imports Documentation</li>
            <li>Indirect Tax & EXIM Strategic Management Services</li>
            <li>Legal Opinion on Indirect tax Law and Procedure</li>
            <li>Corporate Advisory Services</li>
            <li>Optimization of Indirect cost</li>
          </ul>
        </Col>
        <Col md={3} className="service-category">
          <h3>DGFT</h3>
          <ul className="custom-list">
            <li>Export Incentive Scheme</li>
            <li>Export Promotional Scheme</li>
            <li>Deemed Exports Incentives</li>
            <li>Service Exports Incentives</li>
            <li>Clearance of Policy Relaxation Committee / Policy Grievance Committee</li>
          </ul>
        </Col>
        <Col md={3} className="service-category">
          <h3>Custom</h3>
          <ul className="custom-list">
            <li>MOOWR 2.0</li>
            <li>IGCRD</li>
            <li>IGST Refund</li>
            <li>Drawback All Industry Rate & Brand Rate</li>
            <li>AEO Registration & monitoring of AEO System</li>
            <li>Customs Refund, SVB Refund, SVB Order assessment, SVB Order renewal and Transfer Pricing</li>
          </ul>
        </Col>
        <Col md={3} className="service-category">
          <h3>IT/ITES for EXIM</h3>
          <ul className="custom-list">
            <li>RFID, Digital Signature & EDI solution related work</li>
            <li>MOOWR-SyS (System for MOOWR compliances, Material Accounting; Monthly Returns for Audit purpose)</li>
            <li>Doc 2 Data (Build Data Bank of Exim Records for customs compliances)</li>
            <li>Online MIS and paper less documentation System</li>
            <li>ITM (India Trade Management - Know your Correct HS code and Duty Exemptions under IGCRD & FTA)</li>
            <li>Customised Digital Tools for - 
              <ul>
                <li>FTA Duty saving Opportunities</li>
                <li>EDPMS</li>
                <li>DBK AIR</li>
                <li>RoDTEP Entitlement and utilisation</li>
                <li>IGST Refund </li>
                <li>Advance Authorisation EO controls and utilisation</li>
                <li>EPCG Authorisation EO controls and utilisation</li>
              </ul>
            </li>
          </ul>
        </Col>
      </Row>
      
    </Container>
  );
};

export default OtherServices;
